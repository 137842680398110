import { Link, useNavigate } from "react-router-dom"
import React from "react"
import { useDeclarationUpdate } from "../DeclarationUpdate"
import { useConsultantDeclarationActions } from "../../core/_hooks"
import { ConsultantDeclarationMensuelleSuiviModel } from "../../../../../../../models"
import {
     CONST_DECLARATION_SUIVI_TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VEUT_ET_PEUT_APPORTER_MODIFICATION_SANS_VALIDATION_PAR_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_VALIDATION_REGUL_PAR_RESP,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_MODIF_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
     CONST_DECLARATION_SUIVI_TYPE_REJET_REGUL_PAR_RESP,
     CONST_DECLARATION_SUIVI_TYPE_RESP_VIENT_DE_DEMANDER_UNE_RECTIFICATION_APRES_SIGNATURE_APRES_DEMANDE_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_VALIDATION_PAR_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_VALIDATION_REGUL_PAR_RESP,
} from "../../../../../../../constants"

const urlParams = new URLSearchParams(window.location.search)

const Footer = () => {
     const codeSignature = urlParams.get("code_signature") || null

     const context = useDeclarationUpdate()
     const navigate = useNavigate()

     const { mutations, actions } = useConsultantDeclarationActions()

     function finaliserCallBack() {
          navigate("/activites/declarations")
     }

     function entamerRectificationSansValidationRespOuSiege(val: ConsultantDeclarationMensuelleSuiviModel) {
          context.setData(prev => ({
               ...prev,
               declaration: prev.declaration ? { ...prev.declaration, etat: val, modifiable: true } : null,
          }))
     }

     function signerCallBack() {
          navigate("/activites/declarations")
     }

     function demandeRegulCallBack() {
          navigate("/activites/declarations")
     }

     return (
          <>
               <div className="d-flex justify-content-between">
                    {/* BEGIN: LEFT SIDE */}
                    <div className={"d-flex"}>
                         <Link to="/activites/declarations " className={`btn btn-sm btn-light-primary d-flex me-2`}>
                              <i className={"fas fa-list-dots"} /> <span className={"d-none d-md-flex ms-3"}>Retour aux déclarations</span>
                         </Link>
                         <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setDeclarationStepHeader("ticket_resto")}>
                              <i className={"fas fa-arrow-left"} /> TR
                         </button>
                    </div>

                    {/* END: LEFT SIDE */}

                    {/* BEGIN: RIGHT SIDE */}
                    {[
                         undefined,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VEUT_ET_PEUT_APPORTER_MODIFICATION_SANS_VALIDATION_PAR_BM_OU_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT,
                         CONST_DECLARATION_SUIVI_TYPE_VALIDATION_REGUL_PAR_RESP,
                         CONST_DECLARATION_SUIVI_TYPE_RESP_VIENT_DE_DEMANDER_UNE_RECTIFICATION_APRES_SIGNATURE_APRES_DEMANDE_REGUL,
                    ].includes(context.data.declaration?.etat?.type as string) && (
                         <button
                              type="button"
                              className="btn btn-sm btn-success d-flex"
                              onClick={() => actions.finaliser(context.data.declaration?.id as number, finaliserCallBack)}
                              disabled={mutations.finaliser.isLoading}
                         >
                              Finaliser <span className="fas fa-check-circle fs-3 ms-1 align-middle" />
                         </button>
                    )}
                    {[
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                    ].includes(context.data.declaration?.etat?.type as string) && (
                         <button
                              type="button"
                              className="btn btn-sm btn-warning"
                              onClick={() =>
                                   actions.entamerRectificationSansValidationBmOuSiege(context.data.declaration?.id as number, entamerRectificationSansValidationRespOuSiege)
                              }
                              disabled={mutations.entamerRectificationSansDemandeValidation.isLoading}
                         >
                              Apporter une modification <span className="fas fa-edit fs-4 align-middle" />
                         </button>
                    )}

                    {!codeSignature && (
                         <>
                              {[
                                   CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME,
                                   CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION,
                                   CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_MODIF_SANS_VALIDATION_BM_OU_SIEGE,
                                   CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                              ].includes(context.data.declaration?.etat?.type as string) && (
                                   <div className={"d-flex"}>
                                        <button
                                             type="button"
                                             className="btn btn-sm btn-info d-flex me-2"
                                             onClick={() => actions.reenvoyerMailFinalisation(context.data.declaration?.id as number)}
                                             disabled={mutations.reenvoyerMailFinalisation.isLoading}
                                        >
                                             <span className={"d-none d-md-flex"}>Re-envoyer le mail de finalisation</span>
                                             <i className="fas fa-paper-plane fs-4 ms-1" />
                                        </button>
                                        <button
                                             type="button"
                                             className="btn btn-sm btn-warning d-flex"
                                             onClick={() =>
                                                  actions.entamerRectificationSansValidationBmOuSiege(
                                                       context.data.declaration?.id as number,
                                                       entamerRectificationSansValidationRespOuSiege
                                                  )
                                             }
                                             disabled={mutations.entamerRectificationSansDemandeValidation.isLoading}
                                        >
                                             <span className={"d-none d-md-flex"}>Apporter une modification</span>
                                             <i className="fas fa-edit fs-4 align-middle ms-1" />
                                        </button>
                                   </div>
                              )}

                              {[
                                   CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM,
                                   CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM,
                                   CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_VALIDATION_REGUL_PAR_RESP,
                                   CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_REGUL,
                              ].includes(context.data.declaration?.etat?.type as string) && (
                                   <div className={"d-flex"}>
                                        <button
                                             type="button"
                                             className="btn btn-sm btn-info d-flex me-2"
                                             onClick={() => actions.reenvoyerMailFinalisation(context.data.declaration?.id as number)}
                                             disabled={mutations.reenvoyerMailFinalisation.isLoading}
                                        >
                                             <span className={"d-none d-md-flex"}>Re-envoyer le mail de finalisation</span>
                                             <i className="fas fa-paper-plane fs-4" />
                                        </button>
                                   </div>
                              )}

                              {[CONST_DECLARATION_SUIVI_TYPE_VALIDATION_PAR_SIEGE, CONST_DECLARATION_SUIVI_TYPE_REJET_REGUL_PAR_RESP].includes(
                                   context.data.declaration?.etat?.type as string
                              ) && (
                                   <div className={"d-flex"}>
                                        <button
                                             type="button"
                                             className="btn btn-sm btn-danger d-flex me-2"
                                             onClick={() => actions.demandeRegul(context.data.declaration?.id as number, demandeRegulCallBack)}
                                             disabled={mutations.demandeRegul.isLoading}
                                        >
                                             <span className={"d-none d-md-flex"}>Demander une régularisation</span>
                                             <i className="fas fa-hand fs-4 ms-0 ms-md-1" />
                                        </button>
                                   </div>
                              )}
                         </>
                    )}

                    {codeSignature &&
                         [
                              CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME,
                              CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION,
                              CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM,
                              CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_MODIF_SANS_VALIDATION_BM_OU_SIEGE,
                              CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM,
                              CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                              CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_VALIDATION_REGUL_PAR_RESP,
                              CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_REGUL,
                         ].includes(context.data.declaration?.etat?.type as string) && (
                              <button
                                   type="button"
                                   className="btn btn-sm btn-success"
                                   onClick={() => actions.signer(context.data.declaration?.id as number, codeSignature, signerCallBack)}
                                   disabled={mutations.signer.isLoading}
                              >
                                   {!mutations.signer.isLoading && (
                                        <>
                                             Signer <span className="fas fa-signature fs-4 align-middle" />
                                        </>
                                   )}
                                   {mutations.signer.isLoading && (
                                        <>
                                             Veuillez patienter <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </>
                                   )}
                              </button>
                         )}

                    {/* END: RIGHT SIDE */}
               </div>
          </>
     )
}

export default Footer
