import axios from "axios"
import { API_URL_ESPACE_CONSULTANT, CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE } from "../../../../constants"
import {
     ConsultantArretMaladieModel,
     ConsultantCraModel,
     ConsultantCraSuiviModel,
     ConsultantDdcModel,
     ConsultantDeclarationMensuelleModel,
     ConsultantNdfDetailModel,
     ConsultantNdfDocumentModel,
     ConsultantNdfModel,
} from "../../../../models"
import moment from "moment"
import {
     CraRowForListinDesCraModel,
     DdcListingFilters,
     INdfAddNdfDetailGroupedRequestModel,
     INdfAddNdfDetailRequestModel,
     INdfDetailEditDepenseGroupedRequestModel,
     INdfDetailEditDepenseSimpleRequestModel,
     MaladieListingFilters,
} from "./_models"
import { IEditArretMaladieEditRequestModel } from "../components/maladie/core/_models"

// BEGIN:: CRA-details requests
export function craDetailsToggleTicketResuataurant(id: number) {
     return axios.put<{ ticketNewValue: boolean }>(`${API_URL_ESPACE_CONSULTANT}/cra-details/${id}/toggle-ticket-restaurant`, {})
}

export function craDetailsToggleAllTicketsResuataurantMonth(mois: string, annee: string) {
     return axios.put<{ consultantCraDetailsAffectedIds: number[] }>(`${API_URL_ESPACE_CONSULTANT}/cra-details/toggle-all-tickets-restaurant-month`, { mois, annee })
}

// END:: CRA-details requests

// BEGIN:: déclarations mensuelles

export function declarationListing() {
     return axios.get<ConsultantDeclarationMensuelleModel[]>(`${API_URL_ESPACE_CONSULTANT}/declaration/listing`)
}

export function declarationDetails(id: number) {
     return axios.get<ConsultantDeclarationMensuelleModel>(`${API_URL_ESPACE_CONSULTANT}/declaration/${id}/details`)
}

export function declarationActiveCra(id: number) {
     return axios.get<ConsultantCraModel>(`${API_URL_ESPACE_CONSULTANT}/declaration/${id}/active-cra`)
}

export function declarationActiveNdf(id: number) {
     return axios.get<ConsultantNdfModel>(`${API_URL_ESPACE_CONSULTANT}/declaration/${id}/active-ndf`)
}

export function declarationGetDeclarationByDate(mois: string, annee: string) {
     return axios.post<ConsultantNdfModel>(`${API_URL_ESPACE_CONSULTANT}/declaration/get-declaration-by-date`, { mois, annee })
}

export function declarationCreate(mois: string, annee: string) {
     return axios.post<{ id: number }>(`${API_URL_ESPACE_CONSULTANT}/declaration/create`, { mois, annee })
}

export function declarationAddNdf(id: number) {
     return axios.post<ConsultantNdfModel>(`${API_URL_ESPACE_CONSULTANT}/declaration/${id}/add-ndf`, {})
}

// END:: déclarations mensuelles

// BEGIN:: CRA requests

export function craListing() {
     return axios.get<CraRowForListinDesCraModel[]>(`${API_URL_ESPACE_CONSULTANT}/cra/listing`)
}

export function craDetails(id: number) {
     return axios.get<ConsultantCraModel>(`${API_URL_ESPACE_CONSULTANT}/cra/${id}/details`)
}

export function craGetCraByDate(mois: string, annee: string) {
     return axios.post<ConsultantNdfModel>(`${API_URL_ESPACE_CONSULTANT}/cra/get-cra-by-date`, { mois, annee })
}

export function craCheckExistence(mois: string, annee: string) {
     return axios.post<{ exists: boolean; id: number | null }>(`${API_URL_ESPACE_CONSULTANT}/cra/check-existence`, { mois, annee })
}

export function craCreate(mois: string, annee: string) {
     return axios.post<{ id: number }>(`${API_URL_ESPACE_CONSULTANT}/cra/create`, { mois, annee })
}

// -- Ajoute une ligne vide avec la référence de la mission
export function craAddLigneMission(id: number, mission_id: number) {
     return axios.post<ConsultantCraModel>(`${API_URL_ESPACE_CONSULTANT}/cra/${id}/add-ligne-mission`, { mission_id })
}

// -- Delete la ligne de la mission
export function craRemoveMission(id: number, mission_id: number) {
     return axios.post<ConsultantCraModel>(`${API_URL_ESPACE_CONSULTANT}/cra/${id}/remove-ligne-mission`, { mission_id })
}

// -- Get quelques informations de l'entité CRA
export function craGetInfoByMonth(id: number) {
     return axios.get<ConsultantCraModel>(`${API_URL_ESPACE_CONSULTANT}/cra/${id}/info`)
}

// -- Replace les heures d'un jour d'une mission d'un CRA
export function craSetHeuresDayMission(id: number, mission_id: number, date: moment.Moment, heures: number) {
     const dateFormated = date.format("YYYY-MM-DD")
     return axios.post<ConsultantCraModel>(`${API_URL_ESPACE_CONSULTANT}/cra/${id}/set-heures-day-mission`, {
          mission_id,
          date: dateFormated,
          heures,
     })
}

// -- Replace les heures d'un jour d'une absence d'un CRA
export function craSetHeuresDayAbsence(id: number, absence_type: string, date: moment.Moment, heures: number) {
     const dateFormated = date.format("YYYY-MM-DD")
     return axios.post<ConsultantCraModel>(`${API_URL_ESPACE_CONSULTANT}/cra/${id}/set-heures-day-absence`, {
          absence_type,
          date: dateFormated,
          heures,
     })
}

// -- Set all heures du mois d'une mission d'un CRA
export function craSetHeuresMissionForTheEntireMonth(id: number, mission_id: number, heures: number) {
     return axios.post<ConsultantCraModel>(`${API_URL_ESPACE_CONSULTANT}/cra/${id}/set-heures-mission-for-entire-month`, {
          mission_id,
          heures,
     })
}

// -- Suivi consultant vient de finaliser sa déclaration (first time)
export function declarationSuiviConsultantVientDeFinaliserSaDeclaration(id: number) {
     return axios.post(`${API_URL_ESPACE_CONSULTANT}/declaration/${id}/suivi-consultant-vient-de-finaliser-declaration`, {})
}

export function declarationSuiviConsultantReenvoyerMailFinalisationPourSignature(id: number) {
     return axios.post(`${API_URL_ESPACE_CONSULTANT}/declaration/${id}/suivi-consultant-reevoyer-mail-finalisation-pour-signature-declaration`, {})
}

export function declarationSuiviConsultantSigneDeclaration(id: number, code: string, commentaire?: string) {
     return axios.post(`${API_URL_ESPACE_CONSULTANT}/declaration/${id}/suivi-consultant-signe-declaration`, {
          code,
          commentaire,
     })
}

// -- Demande de régul
export function declarationSuiviConsultantDemandeRegulRequest(id: number, commentaire: string) {
     return axios.post(`${API_URL_ESPACE_CONSULTANT}/declaration/${id}/suivi-demande-regul-par-consultant`, {
          commentaire,
     })
}

export function declarationSuiviConsultantVeutEtPeutApporterModifSansValidationBmOuSiege(id: number) {
     return axios.post<ConsultantCraSuiviModel>(`${API_URL_ESPACE_CONSULTANT}/declaration/${id}/suivi-consultant-veut-et-peut-apporter-modif-sans-validation-bm-siege`, {})
}

// END:: CRA requests

// BEGIN:: Maladie requests
export function maladieGetListe(p_filters?: MaladieListingFilters) {
     let filters = {}
     if (p_filters?.du) filters["du"] = p_filters.du.format("YYYY-MM-DD")
     if (p_filters?.au) filters["au"] = p_filters.au.format("YYYY-MM-DD")
     if (p_filters?.duOrAuWithThisMonthAndYear) filters["duOrAuWithThisMonthAndYear"] = p_filters.duOrAuWithThisMonthAndYear.format("YYYY-MM-DD")

     return axios.post<ConsultantArretMaladieModel[]>(`${API_URL_ESPACE_CONSULTANT}/maladie/listing`, filters)
}

export function maladieCreate({ du, au, justificatif }: { du: moment.Moment; au: moment.Moment; justificatif: File }) {
     const duYMD = du.format("YYYY-MM-DD")
     const auYMD = au.format("YYYY-MM-DD")
     const formData = new FormData()
     formData.append("form[du]", duYMD)
     formData.append("form[au]", auYMD)
     formData.append("justificatif", justificatif)
     return axios.post<ConsultantDdcModel>(`${API_URL_ESPACE_CONSULTANT}/maladie/create`, formData)
}

export function maladieAnnulerRequest(id: number, commentaire: string) {
     return axios.put<ConsultantDdcModel>(`${API_URL_ESPACE_CONSULTANT}/maladie/${id}/annuler`, {commentaire})
}

export function maladieEditRequest(id: number, payload: IEditArretMaladieEditRequestModel) {
     const formData = new FormData()
     if (payload.du) formData.append("data[du]", moment(payload.du, "DD/MM/YYYY").format("YYYY-MM-DD"))
     if (payload.au) formData.append("data[au]", moment(payload.au, "DD/MM/YYYY").format("YYYY-MM-DD"))
     formData.append("data[isJustificatifReplaced]", payload.isJustificatifReplaced ? "1" : "0")
     if (payload.justificatif) formData.append("justificatif", payload.justificatif)

     return axios.post<ConsultantArretMaladieModel>(`${API_URL_ESPACE_CONSULTANT}/maladie/${id}/edit`, formData)
}

// END:: Maladie requests

// BEGIN:: DDC requests
export function ddcGetListe(p_filters?: DdcListingFilters) {
     let filters = {}
     if (p_filters?.du) filters["du"] = p_filters.du.format("YYYY-MM-DD")
     if (p_filters?.au) filters["au"] = p_filters.au.format("YYYY-MM-DD")
     if (p_filters?.duOrAuWithThisMonthAndYear) filters["duOrAuWithThisMonthAndYear"] = p_filters.duOrAuWithThisMonthAndYear.format("YYYY-MM-DD")

     return axios.post<ConsultantDdcModel[]>(`${API_URL_ESPACE_CONSULTANT}/ddc/listing`, filters)
}

export function ddcCreate({
     du,
     au,
     type,
     sousType,
     motif,
     justificatif,
}: {
     du: moment.Moment
     au: moment.Moment
     type: string
     sousType: string
     motif: string | null
     justificatif: File | null
}) {
     const duYMD = du.format("YYYY-MM-DD")
     const auYMD = au.format("YYYY-MM-DD")
     const formData = new FormData()
     formData.append("form[du]", duYMD)
     formData.append("form[au]", auYMD)
     formData.append("form[type]", type)
     if (type == CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE) {
          if (motif) formData.append("form[motif]", motif)
          if (justificatif) formData.append("justificatif", justificatif)
          formData.append("form[sousType]", sousType)
     }
     return axios.post<ConsultantDdcModel>(`${API_URL_ESPACE_CONSULTANT}/ddc/create`, formData)
}

export function ddcRectifierAvantDecisionBm(
     id: number,
     {
          du,
          au,
          type,
          sousType,
          motif,
          voulezVousChangerLaPieceJointe,
          justificatif,
     }: {
          du: moment.Moment
          au: moment.Moment
          type: string
          sousType: string | null
          motif: string | null
          voulezVousChangerLaPieceJointe: boolean
          justificatif: File | null
     }
) {
     const duYMD = du.format("YYYY-MM-DD")
     const auYMD = au.format("YYYY-MM-DD")
     const formData = new FormData()
     formData.append("form[du]", duYMD)
     formData.append("form[au]", auYMD)
     formData.append("form[type]", type)
     if (type == CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE) {
          formData.append("form[sousType]", sousType || "")
          formData.append("form[voulezVousChangerLaPieceJointe]", voulezVousChangerLaPieceJointe ? "1" : "0")
          if (motif) formData.append("form[motif]", motif)
          if (voulezVousChangerLaPieceJointe && justificatif) formData.append("justificatif", justificatif)
     }

     return axios.post<ConsultantDdcModel>(`${API_URL_ESPACE_CONSULTANT}/ddc/${id}/rectifier-avant-decision-bm`, formData)
}

export function ddcAnnuler(id: number, raison: string) {
     return axios.put<ConsultantDdcModel>(`${API_URL_ESPACE_CONSULTANT}/ddc/${id}/annuler`, {
          raison,
     })
}

// END:: DDC requests

// BEGIN:: NDF requests
export function ndfGetListe() {
     return axios.get<ConsultantNdfModel[]>(`${API_URL_ESPACE_CONSULTANT}/ndf/listing`)
}

export function ndfGetNdfByDate(mois: string, annee: string) {
     return axios.post<ConsultantNdfModel>(`${API_URL_ESPACE_CONSULTANT}/ndf/get-ndf-by-date`, { mois, annee })
}

export function ndfCreate(mois: string, annee: string) {
     return axios.post<ConsultantNdfModel>(`${API_URL_ESPACE_CONSULTANT}/ndf/create`, { mois, annee })
}

export function ndfDelete(id: number) {
     return axios.post(`${API_URL_ESPACE_CONSULTANT}/ndf/${id}/delete`, {})
}

export function ndfGetInfo(id: number) {
     return axios.get<ConsultantNdfModel>(`${API_URL_ESPACE_CONSULTANT}/ndf/${id}/info`)
}

export function ndfGetDetails(id: number) {
     return axios.get<ConsultantNdfDetailModel[]>(`${API_URL_ESPACE_CONSULTANT}/ndf/${id}/details`)
}

export function ndfAddDetailGrouped(id: number, childs: INdfAddNdfDetailGroupedRequestModel[], fichiers: File[]) {
     const formData = new FormData()
     childs.map((item, index) => {
          formData.append(`childs[${index}][numeroTicket]`, item.numeroTicket.toString())
          formData.append(`childs[${index}][montantTTC]`, item.montantTTC.toString())
          formData.append(`childs[${index}][categorie]`, item.categorie)
          if(item.description) formData.append(`childs[${index}][description]`, item.description)
          formData.append(`childs[${index}][date]`, item.date.format("YYYY-MM-DD"))
          if (item.repasSeulOuEnGroupe) formData.append(`childs[${index}][repasSeulOuEnGroupe]`, item.repasSeulOuEnGroupe)
          if (item.invites) item.invites.map((item_, index_) => formData.append(`childs[${index}][invites][${index_}]`, item_))
     })
     fichiers.map((item, index) => formData.append(`fichiers[${index}]`, item))

     return axios.post<ConsultantNdfDetailModel>(`${API_URL_ESPACE_CONSULTANT}/ndf/${id}/add-details-grouped`, formData)
}

// NDF Detail
// -- Add detail
export function ndfAddDetailSimple(id: number, payload: INdfAddNdfDetailRequestModel) {
     const formData = new FormData()
     if (payload.date) formData.append(`data[date]`, moment(payload.date, "DD/MM/YYYY").format("YYYY-MM-DD"))
     if (payload.categorie) formData.append(`data[categorie]`, payload.categorie)
     if (payload.description) formData.append(`data[description]`, payload.description)
     if (payload.montantTTC) formData.append(`data[montantTTC]`, payload.montantTTC.toString())
     if (payload.documents) payload.documents.map((item, index) => formData.append(`documents[${index}]`, item))
     if (payload.repasSeulOuEnGroupe) formData.append(`data[repasSeulOuEnGroupe]`, payload.repasSeulOuEnGroupe)
     if (payload.invites) payload.invites.map((item, index) => formData.append(`data[invites][${index}]`, item))

     return axios.post<ConsultantNdfDetailModel>(`${API_URL_ESPACE_CONSULTANT}/ndf/${id}/add-details-simple`, formData)
}

// -- Edit detail (depense simple)
export function ndfDetailEditDepenseSimple(id: number, payload: INdfDetailEditDepenseSimpleRequestModel) {
     payload = {
          ...payload,
          date: moment(payload.date, "DD/MM/YYYY").format("YYYY-MM-DD")
     }

     return axios.post<ConsultantNdfDetailModel>(`${API_URL_ESPACE_CONSULTANT}/ndf/detail/${id}/edit-depense-simple`, payload)
}

// -- Edit detail (depense grouped)
export function ndfDetailEditDepenseGrouped(id: number, payload: INdfDetailEditDepenseGroupedRequestModel) {
     payload = {
          ...payload,
          depenses: payload.depenses.map(item => ({
               ...item,
               date: moment(item.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
          })),
     }

     return axios.post<ConsultantNdfDetailModel>(`${API_URL_ESPACE_CONSULTANT}/ndf/detail/${id}/edit-depense-grouped`, payload)
}

// -- Remove detail
export function ndfDetailRemove(id: number) {
     return axios.post(`${API_URL_ESPACE_CONSULTANT}/ndf/detail/${id}/delete`, {})
}

// -- Replace doc
export function ndfDetailAddDocument(ndf_detail_id: number, justificatif: File) {
     const formData = new FormData()
     formData.append("justificatif", justificatif)
     return axios.post<ConsultantNdfDocumentModel>(`${API_URL_ESPACE_CONSULTANT}/ndf/detail/${ndf_detail_id}/add-document`, formData)
}

// -- Replace doc
export function ndfDetailDocumentReplace(id: number, justificatif: File) {
     const formData = new FormData()
     formData.append("justificatif", justificatif)
     return axios.post<ConsultantNdfDocumentModel>(`${API_URL_ESPACE_CONSULTANT}/ndf/detail/doc/${id}/replace`, formData)
}

// -- Delete doc
export function ndfDetailDocumentDelete(id: number) {
     return axios.delete(`${API_URL_ESPACE_CONSULTANT}/ndf/detail/doc/${id}/delete`)
}

// END:: NDF requests
