/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {ChangeEmail} from "./ChangeEmail";
import {ChangePassword} from "./ChangePassword";
import {TwoFactorAuth} from "./TwoFactorAuth";
import {useAuth} from "../../../../../auth";
import {AUTH_ESPACE_AVENTURIER_TYPE_CONSULTANT} from "../../../../../../constants";


const Authentication: React.FC = () => {
    const {currentUser} = useAuth()

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-header border-0 px-0'>
                <div className='card-title m-0'>
                    <h4 className='fw-bolder m-0'>Authentification</h4>
                </div>
            </div>

            <div className='card-body border-top px-0'>
                <ChangeEmail />

                <div className='separator separator-dashed my-6'></div>

                <ChangePassword />

                {currentUser?.typeEspaceAppAventurier === AUTH_ESPACE_AVENTURIER_TYPE_CONSULTANT && <>
                    <div className='separator separator-dashed my-6'></div>

                    <TwoFactorAuth />
                </>}

            </div>
        </div>
    )
}




export {Authentication}
