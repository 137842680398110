import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/email-password-auth`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot-password`

// Server should return AuthModel
export function login(username: string, password: string, code ?: string) {
    return axios.post<AuthModel>(LOGIN_URL, code ? {username, password, code} : {username, password})
}

// Server should return AuthModel
export function register(
    nom: string,
    prenom: string,
    email: string,
    password: string,
    recaptchaToken: string
) {
    return axios.post(REGISTER_URL, {
        nom,
        prenom,
        email,
        password,
        recaptchaToken
    })
}

export function forgotPassword(email: string) {
    return axios.post(REQUEST_PASSWORD_URL, {
        email,
    })
}

export function resetPassword(password: string, token: string) {
    return axios.put<UserModel>(`${API_URL}/reset-password`, {
        password,
    }, {
        headers: {
            'Authorization-api': `Bearer ${token}`
        },
    })
}

export function getUserByToken(token: string) {
    return axios.get<UserModel>(`${API_URL}/user-info`, {
        headers: {
            'Authorization-api': `Bearer ${token}`
        },
    })
}


export function activate(token: string) {
    return axios.put<UserModel>(`${API_URL}/activate-account`, {}, {
        headers: {
            'Authorization-api': `Bearer ${token}`
        },
    })
}

export function getCodePostalOfVille(ville_id: number) {
    return axios.get<{ codePostal: string }>(`${API_URL}/utils/get-code-postal-of-ville`, {
        params: {ville_id}
    })
}

