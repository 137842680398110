import React, { useState } from "react"
import * as Yup from "yup"
import clsx from "clsx"
import { Link, useLocation } from "react-router-dom"
import { useFormik } from "formik"
import { forgotPassword } from "../core/_requests"
import { KTSVG } from "../../../../_metronic/helpers"

type LocationProps = {
     state: {
          email: string
          message: string | null
     }
}

export function ForgotPassword() {
    const location = useLocation() as unknown as LocationProps;
    const urlParams = new URLSearchParams(window.location.search);

    const initialValues = {
        email: location?.state?.email || urlParams.get('email') || ''
    }

    const forgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .email('Wrong email format')
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Ce champ est requis'),
    })


    const [loading, setLoading] = useState(false)
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
    const [error, setError] = useState<null | string>(null)
    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            setError(null)
            setTimeout(() => {
                forgotPassword(values.email || '')
                    .then(() => {
                        setLoading(false)
                        setIsFormSubmitted(true)
                    })
                    .catch(e => {
                        if (!e.response) {
                            setStatus("Echec lors de la création du compte. Veuillez réessayer.")
                        } else {
                            setStatus(e.response.data.message)
                        }
                        setSubmitting(false)
                    })
            }, 1000)
        },
    })

    if (!isFormSubmitted) {
        return (
             <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" noValidate id="kt_login_password_reset_form" onSubmit={formik.handleSubmit}>
                  <div className="text-center mb-10">
                       {/* begin::Title */}
                       <h1 className="text-black fw-bolder mb-3">Mot de passe oublié ?</h1>
                       {/* end::Title */}

                       {/* begin::Link */}
                       <div className="text-gray-500 fw-semibold fs-6">Entrez votre email pour réinitialiser votre mot de passe</div>
                       {/* end::Link */}
                  </div>

                  {/* begin::Title */}

                  {location?.state?.message && (
                       <div className="mb-lg-15 alert alert-warning">
                            <div className="alert-text font-weight-bold">{location?.state?.message}</div>
                       </div>
                  )}

                  {error && (
                       <div className="mb-lg-15 alert alert-danger">
                            <div className="alert-text font-weight-bold">{error}</div>
                       </div>
                  )}

                  {/* end::Title */}

                  {/* begin::Form group */}
                  <div className="fv-row mb-8">
                       <label className="form-label fw-bolder text-black fs-6">Email</label>
                       <input
                            type="email"
                            placeholder="Email"
                            autoComplete="off"
                            {...formik.getFieldProps("email")}
                            className={clsx(
                                 "form-control bg-transparent",
                                 { "is-invalid": formik.touched.email && formik.errors.email },
                                 {
                                      "is-valid": formik.touched.email && !formik.errors.email,
                                 }
                            )}
                       />
                       {formik.touched.email && formik.errors.email && (
                            <div className="fv-plugins-message-container">
                                 <div className="fv-help-block">
                                      <span role="alert">{formik.errors.email}</span>
                                 </div>
                            </div>
                       )}
                  </div>
                  {/* end::Form group */}

                  {/* begin::Form group */}
                  <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                       <Link to="/auth/login">
                            <button
                                 type="button"
                                 id="kt_login_password_reset_form_cancel_button"
                                 className="btn btn-light-primary me-4"
                                 disabled={formik.isSubmitting || !formik.isValid}
                            >
                                 Annuler
                            </button>
                       </Link>

                       <button type="submit" id="kt_password_reset_submit" className="btn btn-primary">
                            <span className="indicator-label">
                                 {!loading && "Valider"}
                                 {loading && (
                                      <span className="indicator-progress" style={{ display: "block" }}>
                                           Veuillez patienter...
                                           <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                      </span>
                                 )}
                            </span>
                       </button>
                  </div>
                  {/* end::Form group */}
             </form>
        )
    } else {
        return <div className="alert alert-success d-flex align-items-center p-5 mb-10">
            <span className={"svg-icon svg-icon-2hx svg-icon-success me-3"}>
                <KTSVG
                    path='/media/icons/duotune/general/gen037.svg'
                    className='svg-icon svg-icon-2hx svg-icon-success me-4'
                />
            </span>
            <div className="d-flex flex-column">
                <h5 className="mb-1">Parfait ! Votre demande a bien été prise en compte.</h5>
                <span>Si un compte est associé à cette adresse mail, nous y enverrons un lien de réinitialisation de
                    mot de passe.</span>
            </div>
        </div>
    }

}
