import React, { useState } from "react"
import { useAuth } from "../../../../auth"
import { useIntl } from "react-intl"
import * as Yup from "yup"
import {
     API_GET_PAYS_INDICATIFS_TEL_URL,
     API_GET_PAYS_URL,
     API_GET_VILLES_FRANCE_URL,
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CIVILITES_OPTIONS_FOR_SELECT2,
     CONST_DEFAULT_TOAST_OPTIONS,
     CONST_OUI_NON_FOR_SELECT2,
     CONST_PAYS_CODE_FRANCE,
     CONST_PAYS_ID_FRANCE,
} from "../../../../../constants"
import { useFormik } from "formik"
import { ProfilDetailsAfterDemandeEmbaucheSalarieModel, ProfilDetailsAfterDemandeEmbaucheSalarieSchemaModel } from "../../../core/_models"
import MySelectField from "../../../../../utils/fields/MySelectField"
import MySelectGetAllDataFromServer from "../../../../../utils/fields/MySelectGetAllDataFromServer"
import MyInptuMaskField from "../../../../../utils/fields/MyInputMaskField"
import MyDateDayMonthYearField from "../../../../../utils/fields/MyDateDayMonthYearField"
import MySelectSearchDataFromServer from "../../../../../utils/fields/MySelectSearchDataFromServer"
import { removePhotoProfil, setInfoPerso, setPhotoProfil } from "../../../core/_requests"
import { toast } from "react-toastify"
import { AxiosError, AxiosResponse } from "axios"
import { getCodePostalOfVille } from "../../../../auth/core/_requests"
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers"
import { getThumbnailDriveUrl } from "../../../../../helpers/helper-global"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

const InfoPersoEditFormEspacePreembaucheSalarie = () => {
     const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
     const [loadingCodePostal, setLoadingCodePostal] = useState<boolean>(false)
     const { currentUser, setCurrentUser } = useAuth()
     const intl = useIntl()
     const MySwal = withReactContent(Swal)

     const profileDetailsSchema = Yup.object().shape({
          civilite: Yup.string().required("Ce champ est requis."),
          prenom: Yup.string().required("Ce champ est requis."),
          nom: Yup.string().required("Ce champ est requis."),
          telIndicatifId: Yup.number().label("Indicatif téléphonique").required("L'indicatif est requis"),
          tel: Yup.string().required("Le tél. est requis"),
          dateNaissance: Yup.string().required("La date de naissance est requise"),
          nationaliteId: Yup.number().when("etesVousDeNationaliteFrancaise", {
               is: false,
               then: schema => schema.required("Ce champ est requis"),
          }),
          paysNaissanceId: Yup.number().required("Ce champ est requis"),
          villeNaissanceHorsFrance: Yup.string().when("paysNaissanceId", {
               is: paysNaissanceId => {
                    return paysNaissanceId !== CONST_PAYS_ID_FRANCE
               },
               then: schema => schema.required("Ce champ est requis"),
          }),
          villeNaissanceFranceId: Yup.number().when("paysNaissanceId", {
               is: CONST_PAYS_ID_FRANCE,
               then: schema => schema.required("Ce champ est requis").nullable(),
          }),
          paysResidenceId: Yup.number().required("Ce champ est requis"),
          villeResidenceHorsFrance: Yup.string().when("paysResidenceId", {
               is: paysResidenceId => {
                    return paysResidenceId !== CONST_PAYS_ID_FRANCE
               },
               then: schema => schema.required("Ce champ est requis"),
          }),
          villeResidenceFranceId: Yup.number().when("paysResidenceId", {
               is: CONST_PAYS_ID_FRANCE,
               then: schema => schema.required("Ce champ est requis").nullable(),
          }),
          etesVousDeNationaliteFrancaise: Yup.boolean().required("Ce champ est requis").nullable(),
          adresseResidence: Yup.string().required("Ce champ est requis."),
          codePostalResidence: Yup.string().required("Ce champ est requis."),
          numSecuriteSociale: Yup.string().required("Ce champ est requis.").min(21, "Ce champ doit contenir 13 chiffres"),
          personneToContacterEnCasUrgenceNom: Yup.string(),
          personneToContacterEnCasUrgencePrenom: Yup.string(),
          personneToContacterEnCasUrgenceTelIndicatifId: Yup.number().nullable(),
          personneToContacterEnCasUrgenceTel: Yup.string(),
     })

     const formik = useFormik<ProfilDetailsAfterDemandeEmbaucheSalarieSchemaModel>({
          initialValues: {
               civilite: currentUser?.consultant?.civilite,
               prenom: currentUser?.consultant?.prenom || "",
               nom: currentUser?.consultant?.nom || "",
               telIndicatifId: currentUser?.consultant?.telProPays?.id,
               tel: currentUser?.consultant?.telPro,
               dateNaissance: currentUser?.consultant?.naissanceDate?.format("DD/MM/YYYY"),
               paysNaissanceId: currentUser?.consultant?.naissancePays?.id,
               villeNaissanceHorsFrance: currentUser?.consultant?.naissanceVille || "",
               villeNaissanceFranceId: currentUser?.consultant?.naissanceVilleFrance?.id,
               etesVousDeNationaliteFrancaise: currentUser?.consultant?.nationalite ? currentUser?.consultant?.nationalite?.code === CONST_PAYS_CODE_FRANCE : null,
               nationaliteId: currentUser?.consultant?.nationalite?.id,
               paysResidenceId: currentUser?.consultant?.domicilePays?.id,
               villeResidenceHorsFrance: currentUser?.consultant?.domicileVille || "",
               villeResidenceFranceId: currentUser?.consultant?.domocileVilleFrance?.id,
               adresseResidence: currentUser?.consultant?.domicileAdresse || "",
               codePostalResidence: currentUser?.consultant?.domicileCodePostal || "",
               numSecuriteSociale: currentUser?.consultant?.numeroSecuriteSociale || "",
               personneToContacterEnCasUrgenceNom: currentUser?.consultant?.contactUrgenceNom || "",
               personneToContacterEnCasUrgencePrenom: currentUser?.consultant?.contactUrgencePrenom || "",
               personneToContacterEnCasUrgenceTelIndicatifId: currentUser?.consultant?.contactUrgenceTelPays?.id,
               personneToContacterEnCasUrgenceTel: currentUser?.consultant?.contactUrgenceTel || "",
          },
          validationSchema: profileDetailsSchema,
          onSubmit: async (values, { setStatus }) => {
               setIsSubmitting(true)
               setStatus(false)

               const details: ProfilDetailsAfterDemandeEmbaucheSalarieModel = {}
               if (values.civilite) details.civilite = values.civilite
               if (values.nom) details.nom = values.nom
               if (values.prenom) details.prenom = values.prenom
               if (values.telIndicatifId) details.telIndicatifId = values.telIndicatifId
               if (values.tel) details.tel = values.tel
               if (values.numSecuriteSociale) details.numSecuriteSociale = values.numSecuriteSociale
               if (values.dateNaissance) details.dateNaissance = values.dateNaissance
               if (values.paysNaissanceId) details.paysNaissanceId = values.paysNaissanceId
               if (values.paysNaissanceId === CONST_PAYS_ID_FRANCE && values.villeNaissanceFranceId) details.villeNaissanceFranceId = values.villeNaissanceFranceId
               if (values.paysNaissanceId !== CONST_PAYS_ID_FRANCE && values.villeNaissanceHorsFrance) details.villeNaissanceHorsFrance = values.villeNaissanceHorsFrance
               if (values.etesVousDeNationaliteFrancaise) details.nationaliteId = CONST_PAYS_ID_FRANCE
               if (!values.etesVousDeNationaliteFrancaise && values.nationaliteId) details.nationaliteId = values.nationaliteId
               if (values.adresseResidence) details.adresseResidence = values.adresseResidence
               if (values.paysResidenceId) details.paysResidenceId = values.paysResidenceId
               if (values.paysResidenceId === CONST_PAYS_ID_FRANCE && values.villeResidenceFranceId) details.villeResidenceFranceId = values.villeResidenceFranceId
               if (values.paysResidenceId !== CONST_PAYS_ID_FRANCE && values.villeResidenceHorsFrance) details.villeResidenceHorsFrance = values.villeResidenceHorsFrance
               if (values.codePostalResidence) details.codePostalResidence = values.codePostalResidence
               if (values.personneToContacterEnCasUrgenceNom) details.personneToContacterEnCasUrgenceNom = values.personneToContacterEnCasUrgenceNom
               if (values.personneToContacterEnCasUrgencePrenom) details.personneToContacterEnCasUrgencePrenom = values.personneToContacterEnCasUrgencePrenom
               if (values.personneToContacterEnCasUrgenceTelIndicatifId)
                    details.personneToContacterEnCasUrgenceTelIndicatifId = values.personneToContacterEnCasUrgenceTelIndicatifId
               if (values.personneToContacterEnCasUrgenceTel) details.personneToContacterEnCasUrgenceTel = values.personneToContacterEnCasUrgenceTel

               setInfoPerso(details)
                    .then(r => {
                         setCurrentUser(r.data)
                         toast.success("Vos modifications ont bien été prises en compte.", CONST_DEFAULT_TOAST_OPTIONS)
                    })
                    .catch((e: AxiosError) => {
                         setStatus(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                    })
                    .finally(() => {
                         setIsSubmitting(false)
                    })
          },
     })

     function getCodePostalAccordingToSelectedVilleResidence(ville_id: number) {
          setLoadingCodePostal(true)
          getCodePostalOfVille(ville_id).then((r: AxiosResponse) => {
               formik.setFieldValue("codePostalResidence", r.data.codePostal)
               setLoadingCodePostal(false)
          })
     }

     function handleSetPhotoProfil(e) {
          const fileBinary = e.target.files[0]

          if (fileBinary) {
               toast.info(currentUser?.photoProfilDriveId ? "Changement de votre avatar en cours ..." : "Ajout de votre avatar en cours ...", { autoClose: false })
               setPhotoProfil(fileBinary)
                    .then(r => {
                         setCurrentUser(prev => (prev ? { ...prev, photoProfilDriveId: r.data.id } : prev))
                         toast.dismiss()
                         toast.success("Votre nouveau avatar a bien été pris en compte.")
                    })
                    .catch(e => {
                         toast.dismiss()
                         toast.error(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                    })
          }
     }

     function handleRemovePhotoProfil() {
          MySwal.fire({
               icon: "warning",
               text: `Êtes-vous sûr de vouloir supprimer votre avatar?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: () => {
                    return removePhotoProfil()
                         .then(() => {
                              setCurrentUser(prev => (prev ? { ...prev, photoProfilDriveId: null } : prev))
                              toast.dismiss()
                              toast.success("Votre avatar a bien été supprimé ...")
                         })
                         .catch((e: AxiosError) => {
                              toast.dismiss()
                              toast.error(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     return (
          <form onSubmit={formik.handleSubmit} noValidate className="form">
               {formik.status && (
                    <div className="alert alert-danger">
                         <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
               )}

               <div className="card-body px-0">
                    {/* Avatar */}
                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
                         <div className="col-lg-8">
                              <div
                                   className="image-input image-input-outline"
                                   data-kt-image-input="true"
                                   style={{ backgroundImage: `url(${toAbsoluteUrl("/media/avatars/blank.png")})` }}
                              >
                                   <div
                                        className="image-input-wrapper w-125px h-125px"
                                        style={{
                                             backgroundImage: `url(${
                                                  currentUser?.photoProfilDriveId
                                                       ? getThumbnailDriveUrl(currentUser?.photoProfilDriveId)
                                                       : toAbsoluteUrl("/media/avatars/blank.png")
                                             })`,
                                        }}
                                   ></div>

                                   <label
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                        data-kt-image-input-action="change"
                                        data-bs-original-title="Change avatar"
                                   >
                                        <i className="bi bi-pencil-fill fs-7"></i>
                                        <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={e => handleSetPhotoProfil(e)} />
                                   </label>

                                   {currentUser?.photoProfilDriveId && (
                                        <span
                                             className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                             data-kt-image-input-action="remove"
                                             data-bs-original-title="Remove avatar"
                                             onClick={() => handleRemovePhotoProfil()}
                                        >
                                             <i className="bi bi-x fs-2"></i>
                                        </span>
                                   )}
                              </div>
                         </div>
                    </div>

                    <div className="pb-6">
                         {/*Civilité*/}
                         <div className="row mb-6">
                              <label className="col-lg-4 col-form-label fw-bold fs-6 required">Civilité</label>

                              <div className="col-lg-8 fv-row">
                                   <MySelectField
                                        options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(item => item.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                        name={"civilite"}
                                        value={formik.values?.civilite}
                                        onChange={value => formik.setFieldValue("civilite", value)}
                                        isSearchable={false}
                                        placeholder={"Choisissez une civilité"}
                                        isClearable={true}
                                   />
                                   {formik.touched.civilite && formik.errors.civilite && (
                                        <div className="fv-plugins-message-container">
                                             <div className="fv-help-block">{formik.errors.civilite}</div>
                                        </div>
                                   )}
                              </div>
                         </div>

                         {/*Nom & prénom*/}
                         <div className="row mb-6">
                              <label className="col-lg-4 col-form-label required fw-bold fs-6">Prénom NOM</label>

                              <div className="col-lg-8">
                                   <div className="row">
                                        <div className="col-lg-6 fv-row">
                                             <input
                                                  type="text"
                                                  className="form-control form-control-lg mb-3 mb-lg-0"
                                                  placeholder="Prénom"
                                                  {...formik.getFieldProps("prenom")}
                                                  autoComplete={"off"}
                                             />
                                             {formik.touched.prenom && formik.errors.prenom && (
                                                  <div className="fv-plugins-message-container">
                                                       <div className="fv-help-block">{formik.errors.prenom}</div>
                                                  </div>
                                             )}
                                        </div>

                                        <div className="col-lg-6 fv-row">
                                             <input type="text" className="form-control form-control-lg " placeholder="NOM" {...formik.getFieldProps("nom")} />
                                             {formik.touched.nom && formik.errors.nom && (
                                                  <div className="fv-plugins-message-container">
                                                       <div className="fv-help-block">{formik.errors.nom}</div>
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                              </div>
                         </div>

                         {/*Tél*/}
                         <div className="row pb-4">
                              <label className="col-lg-4 col-form-label fw-bold fs-6 required">Téléphone</label>

                              <div className="col-lg-8">
                                   <div className="row">
                                        <div className="col-lg-6 fv-row mb-lg-0 mb-3">
                                             <MySelectGetAllDataFromServer
                                                  url={API_GET_PAYS_INDICATIFS_TEL_URL}
                                                  name={"telIndicatifId"}
                                                  value={formik.values.telIndicatifId}
                                                  defaultOptions={
                                                       currentUser?.consultant?.telProPays
                                                            ? [
                                                                   {
                                                                        label: currentUser?.consultant?.telProPays?.phoneCodeWithPlusPrefixAndCountry,
                                                                        value: currentUser?.consultant?.telProPays?.id,
                                                                   },
                                                              ]
                                                            : []
                                                  }
                                                  placeholder={"Choisissez un indicatif"}
                                                  onChange={val => formik.setFieldValue("telIndicatifId", val)}
                                             />
                                             {formik.errors.telIndicatifId && (
                                                  <div className="fv-plugins-message-container">
                                                       <div className="fv-help-block">{formik.errors.telIndicatifId}</div>
                                                  </div>
                                             )}
                                        </div>

                                        <div className="col-lg-6 fv-row">
                                             <MyInptuMaskField
                                                  name={"tel"}
                                                  mask={"99 999999999999999999"}
                                                  value={formik.values.tel}
                                                  placeholder={"N° de téléphone"}
                                                  inputMode={"numeric"}
                                                  onChange={e => formik.setFieldValue("tel", e.target.value)}
                                                  isInvalid={!!formik.errors.tel}
                                             />
                                             {formik.touched.tel && formik.errors.tel && (
                                                  <div className="fv-plugins-message-container">
                                                       <div className="fv-help-block">{formik.errors.tel}</div>
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                              </div>
                         </div>

                         {/*N° SS*/}
                         <div className="row">
                              <label className="col-lg-4 col-form-label fw-bold fs-6 required">Numéro de sécurité sociale</label>

                              <div className="col-lg-8 fv-row">
                                   <MyInptuMaskField
                                        name={"numSecuriteSociale"}
                                        mask={"9 99 99 99 999 999 99"}
                                        value={formik.values.numSecuriteSociale}
                                        placeholder={"N° de sécurité sociale"}
                                        inputMode={"numeric"}
                                        onChange={e => formik.setFieldValue("numSecuriteSociale", e.target.value)}
                                        isInvalid={!!formik.errors.numSecuriteSociale}
                                   />
                                   {formik.touched.numSecuriteSociale && formik.errors.numSecuriteSociale && (
                                        <div className="fv-plugins-message-container">
                                             <div className="fv-help-block">{formik.errors.numSecuriteSociale}</div>
                                        </div>
                                   )}
                              </div>
                         </div>
                    </div>

                    {/*NAISSANCE*/}
                    <div className={"pb-6"}>
                         <h3 className={"mt-8 mb-4"}>Naissance</h3>
                         <div className="separator separator-dashed mb-6" />

                         {/*Date de naissance*/}
                         <div className="row mb-6">
                              <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                   <span>Date de naissance</span>
                              </label>

                              <div className="col-lg-8 fv-row">
                                   <MyDateDayMonthYearField
                                        onChange={date => formik.setFieldValue("dateNaissance", date)}
                                        value={formik.values.dateNaissance}
                                        name={"dateNaissance"}
                                        placeholder={"Date de naissance"}
                                   />
                                   {formik.touched.dateNaissance && formik.errors.dateNaissance && (
                                        <div className="fv-plugins-message-container">
                                             <div className="fv-help-block">{formik.errors.dateNaissance}</div>
                                        </div>
                                   )}
                              </div>
                         </div>

                         {/*Pays et ville de naissance*/}
                         <div className="row mb-6">
                              <label className="col-lg-4 col-form-label required fw-bold fs-6">Pays et ville de naissance</label>

                              <div className="col-lg-8">
                                   <MySelectGetAllDataFromServer
                                        url={API_GET_PAYS_URL}
                                        name={"paysNaissanceId"}
                                        value={formik.values.paysNaissanceId}
                                        defaultOptions={
                                             currentUser?.consultant?.naissancePays
                                                  ? [
                                                         {
                                                              label: currentUser?.consultant?.naissancePays?.nomFr,
                                                              value: currentUser?.consultant?.naissancePays?.id,
                                                         },
                                                    ]
                                                  : []
                                        }
                                        placeholder={"Choisissez un pays"}
                                        onChange={val => formik.setFieldValue("paysNaissanceId", val)}
                                   />
                                   {formik.touched.paysNaissanceId && formik.errors.paysNaissanceId && (
                                        <div className="fv-plugins-message-container">
                                             <div className="fv-help-block">{formik.errors.paysNaissanceId}</div>
                                        </div>
                                   )}

                                   {formik.values.paysNaissanceId && (
                                        <>
                                             <br />
                                             {formik.values.paysNaissanceId === CONST_PAYS_ID_FRANCE ? (
                                                  <>
                                                       <MySelectSearchDataFromServer
                                                            name={"villeNaissanceFranceId"}
                                                            url={API_GET_VILLES_FRANCE_URL}
                                                            defaultOptions={
                                                                 currentUser?.consultant?.naissanceVilleFrance
                                                                      ? [
                                                                             {
                                                                                  value: currentUser?.consultant?.naissanceVilleFrance?.id,
                                                                                  label: currentUser?.consultant?.naissanceVilleFrance?.nom,
                                                                             },
                                                                        ]
                                                                      : []
                                                            }
                                                            method={"GET"}
                                                            value={formik.values.villeNaissanceFranceId}
                                                            placeholder={"Choisissez une ville"}
                                                            onChange={val => {
                                                                 formik.setFieldValue("villeNaissanceFranceId", val)
                                                            }}
                                                            minimumLengthSearch={1}
                                                       />
                                                       {formik.touched.villeNaissanceFranceId && formik.errors.villeNaissanceFranceId && (
                                                            <div className="fv-plugins-message-container">
                                                                 <div className="fv-help-block">{formik.errors.villeNaissanceFranceId}</div>
                                                            </div>
                                                       )}
                                                  </>
                                             ) : (
                                                  <>
                                                       <input
                                                            type="text"
                                                            className="form-control form-control-lg mb-3 mb-lg-0"
                                                            placeholder="Ville de naissance"
                                                            {...formik.getFieldProps("villeNaissanceHorsFrance")}
                                                            autoComplete={"off"}
                                                       />
                                                       {formik.errors.villeNaissanceHorsFrance && (
                                                            <div className="fv-plugins-message-container">
                                                                 <div className="fv-help-block">{formik.errors.villeNaissanceHorsFrance}</div>
                                                            </div>
                                                       )}
                                                  </>
                                             )}
                                        </>
                                   )}
                              </div>
                         </div>

                         {/*French?*/}
                         <div className="row mb-6">
                              <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                   <span>Êtes-vous de nationalité française?</span>
                              </label>

                              <div className="col-lg-8 fv-row">
                                   <MySelectField
                                        options={CONST_OUI_NON_FOR_SELECT2}
                                        name={"etesVousDeNationaliteFrancaise"}
                                        value={formik.values.etesVousDeNationaliteFrancaise}
                                        placeholder={"Êtes-vous de nationalité française?"}
                                        isSearchable={false}
                                        onChange={val => formik.setFieldValue("etesVousDeNationaliteFrancaise", val)}
                                   />
                                   {formik.errors.etesVousDeNationaliteFrancaise && (
                                        <div className="fv-plugins-message-container">
                                             <div className="fv-help-block">{formik.errors.etesVousDeNationaliteFrancaise}</div>
                                        </div>
                                   )}
                              </div>
                         </div>

                         {/*Précisez nationalité*/}
                         {formik.values.etesVousDeNationaliteFrancaise === false && (
                              <div className="row mb-6">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                        <span>Précisez votre nationalité</span>
                                   </label>

                                   <div className="col-lg-8 fv-row">
                                        <MySelectGetAllDataFromServer
                                             url={API_GET_PAYS_URL}
                                             name={"nationaliteId"}
                                             value={formik.values.nationaliteId}
                                             defaultOptions={
                                                  currentUser?.consultant?.nationalite
                                                       ? [
                                                              {
                                                                   label: currentUser?.consultant?.nationalite?.nomFr,
                                                                   value: currentUser?.consultant?.nationalite?.id,
                                                              },
                                                         ]
                                                       : []
                                             }
                                             placeholder={"Choisissez une nationalité"}
                                             onChange={val => formik.setFieldValue("nationaliteId", val)}
                                        />
                                        {formik.touched.nationaliteId && formik.errors.nationaliteId && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.nationaliteId}</div>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         )}
                    </div>

                    {/*RESIDENCE*/}
                    <div className={"pb-6"}>
                         <h3 className={"mt-8 mb-4"}>Résidence</h3>
                         <div className="separator separator-dashed mb-6" />

                         {/*Adresse de résidence*/}
                         <div className="row mb-6">
                              <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                   <span>Adresse de résidence</span>
                              </label>

                              <div className="col-lg-8 fv-row">
                                   <input
                                        type="text"
                                        className="form-control form-control-lg mb-3 mb-lg-0"
                                        placeholder="Adresse de résidence"
                                        {...formik.getFieldProps("adresseResidence")}
                                        autoComplete={"off"}
                                   />
                                   {formik.errors.adresseResidence && (
                                        <div className="fv-plugins-message-container">
                                             <div className="fv-help-block">{formik.errors.adresseResidence}</div>
                                        </div>
                                   )}
                              </div>
                         </div>

                         {/*Pays de résidence*/}
                         <div className="row mb-6">
                              <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                   <span>Pays de résidence</span>
                              </label>

                              <div className="col-lg-8 fv-row">
                                   <MySelectGetAllDataFromServer
                                        url={API_GET_PAYS_URL}
                                        name={"paysResidenceId"}
                                        value={formik.values.paysResidenceId}
                                        defaultOptions={
                                             currentUser?.consultant?.domicilePays
                                                  ? [
                                                         {
                                                              label: currentUser?.consultant?.domicilePays?.nomFr,
                                                              value: currentUser?.consultant?.domicilePays?.id,
                                                         },
                                                    ]
                                                  : []
                                        }
                                        placeholder={"Choisissez un pays"}
                                        onChange={val => formik.setFieldValue("paysResidenceId", val)}
                                   />
                                   {formik.touched.paysResidenceId && formik.errors.paysResidenceId && (
                                        <div className="fv-plugins-message-container">
                                             <div className="fv-help-block">{formik.errors.paysResidenceId}</div>
                                        </div>
                                   )}
                              </div>
                         </div>

                         {/*Ville & code postal*/}
                         {formik.values.paysResidenceId && (
                              <div className="row mb-6">
                                   <label className="col-lg-4 col-form-label required fw-bold fs-6">Ville et code postal</label>

                                   <div className="col-lg-8">
                                        <div className="row">
                                             <div className="col-lg-6 fv-row mb-lg-0 mb-3">
                                                  {formik.values.paysResidenceId === CONST_PAYS_ID_FRANCE ? (
                                                       <>
                                                            <MySelectSearchDataFromServer
                                                                 url={API_GET_VILLES_FRANCE_URL}
                                                                 name={"villeResidenceFranceId"}
                                                                 defaultOptions={
                                                                      currentUser?.consultant?.domocileVilleFrance
                                                                           ? [
                                                                                  {
                                                                                       value: currentUser?.consultant?.domocileVilleFrance?.id,
                                                                                       label: currentUser?.consultant?.domocileVilleFrance?.nom,
                                                                                  },
                                                                             ]
                                                                           : []
                                                                 }
                                                                 method={"GET"}
                                                                 value={currentUser?.consultant?.domocileVilleFrance?.id}
                                                                 placeholder={"Choisissez une ville"}
                                                                 onChange={val => {
                                                                      formik.setFieldValue("villeResidenceFranceId", val)
                                                                      getCodePostalAccordingToSelectedVilleResidence(Number(val))
                                                                 }}
                                                                 minimumLengthSearch={1}
                                                            />
                                                            {formik.touched.villeResidenceFranceId && formik.errors.villeResidenceFranceId && (
                                                                 <div className="fv-plugins-message-container">
                                                                      <div className="fv-help-block">{formik.errors.villeResidenceFranceId}</div>
                                                                 </div>
                                                            )}
                                                       </>
                                                  ) : (
                                                       <>
                                                            <input
                                                                 type="text"
                                                                 className="form-control form-control-lg mb-3 mb-lg-0"
                                                                 placeholder="Ville de résidence"
                                                                 {...formik.getFieldProps("villeResidenceHorsFrance")}
                                                                 autoComplete={"off"}
                                                            />
                                                            {formik.errors.villeResidenceHorsFrance && (
                                                                 <div className="fv-plugins-message-container">
                                                                      <div className="fv-help-block">{formik.errors.villeResidenceHorsFrance}</div>
                                                                 </div>
                                                            )}
                                                       </>
                                                  )}
                                             </div>

                                             <div className="col-lg-6 fv-row">
                                                  <input
                                                       type="text"
                                                       className="form-control form-control-lg mb-3 mb-lg-0"
                                                       placeholder="Code postal"
                                                       disabled={loadingCodePostal}
                                                       {...formik.getFieldProps("codePostalResidence")}
                                                       autoComplete={"off"}
                                                  />
                                                  {formik.errors.codePostalResidence && (
                                                       <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.codePostalResidence}</div>
                                                       </div>
                                                  )}
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         )}
                    </div>

                    {/*Personne à contacter en cas d'urgence*/}
                    <div className={"pb-8"}>
                         <h3 className={"mt-8 mb-4"}>Personne à contacter en cas d'urgence</h3>
                         <div className="separator separator-dashed mb-6" />

                         {/*Nom & prénom*/}
                         <div className="row mb-6">
                              <label className="col-lg-4 col-form-label fw-bold fs-6">Prénom NOM</label>

                              <div className="col-lg-8">
                                   <div className="row">
                                        <div className="col-lg-6 fv-row">
                                             <input
                                                  type="text"
                                                  className="form-control form-control-lg mb-3 mb-lg-0"
                                                  placeholder="Prénom"
                                                  {...formik.getFieldProps("personneToContacterEnCasUrgencePrenom")}
                                             />
                                             {formik.touched.personneToContacterEnCasUrgencePrenom && formik.errors.personneToContacterEnCasUrgencePrenom && (
                                                  <div className="fv-plugins-message-container">
                                                       <div className="fv-help-block">{formik.errors.personneToContacterEnCasUrgencePrenom}</div>
                                                  </div>
                                             )}
                                        </div>

                                        <div className="col-lg-6 fv-row">
                                             <input
                                                  type="text"
                                                  className="form-control form-control-lg "
                                                  placeholder="NOM"
                                                  {...formik.getFieldProps("personneToContacterEnCasUrgenceNom")}
                                             />
                                             {formik.touched.personneToContacterEnCasUrgenceNom && formik.errors.personneToContacterEnCasUrgenceNom && (
                                                  <div className="fv-plugins-message-container">
                                                       <div className="fv-help-block">{formik.errors.personneToContacterEnCasUrgenceNom}</div>
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                              </div>
                         </div>

                         {/*Tél*/}
                         <div className="row pb-4">
                              <label className="col-lg-4 col-form-label fw-bold fs-6">Téléphone</label>

                              <div className="col-lg-8">
                                   <div className="row">
                                        <div className="col-lg-6 fv-row mb-lg-0 mb-3">
                                             <MySelectGetAllDataFromServer
                                                  url={API_GET_PAYS_INDICATIFS_TEL_URL}
                                                  name={"personneToContacterEnCasUrgenceTelIndicatifId"}
                                                  value={formik.values.personneToContacterEnCasUrgenceTelIndicatifId}
                                                  defaultOptions={
                                                       currentUser?.consultant?.contactUrgenceTelPays
                                                            ? [
                                                                   {
                                                                        label: currentUser?.consultant?.contactUrgenceTelPays?.phoneCodeWithPlusPrefixAndCountry,
                                                                        value: currentUser?.consultant?.contactUrgenceTelPays?.id,
                                                                   },
                                                              ]
                                                            : []
                                                  }
                                                  placeholder={"Choisissez un indicatif"}
                                                  onChange={val => formik.setFieldValue("personneToContacterEnCasUrgenceTelIndicatifId", val)}
                                             />
                                             {formik.errors.personneToContacterEnCasUrgenceTelIndicatifId && (
                                                  <div className="fv-plugins-message-container">
                                                       <div className="fv-help-block">{formik.errors.personneToContacterEnCasUrgenceTelIndicatifId}</div>
                                                  </div>
                                             )}
                                        </div>

                                        <div className="col-lg-6 fv-row">
                                             <MyInptuMaskField
                                                  name={"personneToContacterEnCasUrgenceTel"}
                                                  mask={"99 999999999999999999"}
                                                  value={formik.values.personneToContacterEnCasUrgenceTel}
                                                  placeholder={"N° de téléphone"}
                                                  inputMode={"numeric"}
                                                  onChange={e => formik.setFieldValue("personneToContacterEnCasUrgenceTel", e.target.value)}
                                                  isInvalid={!!formik.errors.personneToContacterEnCasUrgenceTel}
                                             />
                                             {formik.touched.personneToContacterEnCasUrgenceTel && formik.errors.personneToContacterEnCasUrgenceTel && (
                                                  <div className="fv-plugins-message-container">
                                                       <div className="fv-help-block">{formik.errors.personneToContacterEnCasUrgenceTel}</div>
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div className="card-footer d-flex justify-content-lg-end justify-content-center py-6 px-9">
                         <button type="submit" className="btn btn-sm btn-primary" disabled={isSubmitting}>
                              {!isSubmitting && "Enregister les modifications"}
                              {isSubmitting && (
                                   <span className="indicator-progress" style={{ display: "block" }}>
                                        Veuillez patienter... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                   </span>
                              )}
                         </button>
                    </div>
               </div>
          </form>
     )
}

export default InfoPersoEditFormEspacePreembaucheSalarie
