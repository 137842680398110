import {useAuth} from "../../../../../auth";
import {CONST_PAYS_CODE_FRANCE} from "../../../../../../constants";
import React from "react";

export function InfoPersoViewOnlyEspaceConsultantSalarie() {
    const {currentUser} = useAuth()

    return <>
        <div className="pb-6">
            {/*Civilité*/}
            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Civilité</label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{currentUser?.consultant.civiliteLabel}</span>
                </div>
            </div>

            {/*Nom & prénom*/}
            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Prénom NOM</label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{currentUser?.consultant.prenom} {currentUser?.consultant.nom}</span>
                </div>
            </div>

            {/*Email*/}
            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Email</label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark me-2'>{currentUser?.consultant?.mailPro}</span>
                    <i className={"fas fa-check-circle text-success"}/>
                </div>
            </div>

            {/*Tél*/}
            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Téléphone</label>

                <div className='col-lg-8'>
                    <span
                        className='fw-bolder fs-6 text-dark'>{currentUser?.consultant.telProPays?.phoneCodeWithPlusPrefix} {currentUser?.consultant?.telPro}</span>
                </div>
            </div>

            {/*N° SS*/}
            <div className='row'>
                <label className='col-lg-4 fw-bold text-muted'>N° de Sécurité Sociale {!currentUser?.consultant.numeroSecuriteSociale &&
                    <i className={"fas fa-exclamation-circle text-warning"}/>}</label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{currentUser?.consultant.numeroSecuriteSociale}</span>
                </div>
            </div>
        </div>

        <div className="pb-6">
            <h3 className={"mt-8 mb-4"}>Naissance</h3>
            <div className="separator separator-dashed mb-6"/>

            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Date de naissance {!currentUser?.consultant.naissanceDate && <i className={"fas fa-exclamation-circle text-warning"}/>}</label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{currentUser?.consultant.naissanceDate?.format('DD/MM/YYYY')}</span>
                </div>
            </div>

            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Pays et ville de naissance {' '}
                    {(!currentUser?.consultant.naissancePays ||
                        (currentUser?.consultant.naissancePays.code === CONST_PAYS_CODE_FRANCE && !currentUser?.consultant.naissanceVilleFrance) ||
                        (currentUser?.consultant.naissancePays.code !== CONST_PAYS_CODE_FRANCE && !currentUser?.consultant.naissanceVille)
                    ) && <i className={"fas fa-exclamation-circle text-warning"}/>}
                </label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark me-2'>{currentUser?.consultant.naissancePays?.nomFr}</span>
                    <span className='fw-bolder fs-6 text-dark'>
                        {currentUser?.consultant.naissancePays?.code === CONST_PAYS_CODE_FRANCE ? currentUser?.consultant.naissanceVilleFrance?.nom : currentUser?.consultant.naissanceVille}
                    </span>
                </div>
            </div>

            <div className='row'>
                <label className='col-lg-4 fw-bold text-muted'>Nationalité {!currentUser?.consultant.nationalite && <i className={"fas fa-exclamation-circle text-warning"}/>}</label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{currentUser?.consultant.nationalite?.nomFr}</span>
                </div>
            </div>
        </div>

        <div className="pb-6">
            <h3 className={"mt-8 mb-4"}>Résidence</h3>
            <div className="separator separator-dashed mb-6"/>

            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Adresse {!currentUser?.consultant.domicileAdresse && <i className={"fas fa-exclamation-circle text-warning"}/>}</label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{currentUser?.consultant?.domicileAdresse}</span>
                </div>
            </div>

            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                    Ville et code postal {' '}
                    {(!currentUser?.consultant.domicileCodePostal || !currentUser?.consultant.domicilePays ||
                        (currentUser?.consultant.domicilePays.code === CONST_PAYS_CODE_FRANCE && !currentUser?.consultant.domocileVilleFrance) ||
                        (currentUser?.consultant.domicilePays.code !== CONST_PAYS_CODE_FRANCE && !currentUser?.consultant.domicileVille)
                    ) && <i className={"fas fa-exclamation-circle text-warning"}/>}
                </label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>
                        {currentUser?.consultant.domicilePays && <>
                            <span className={"me-2"}>{currentUser?.consultant.domicilePays.code === CONST_PAYS_CODE_FRANCE ? currentUser?.consultant.domocileVilleFrance?.nom : currentUser?.consultant?.domicileVille}</span>
                        </>}
                        {currentUser?.consultant?.domicileCodePostal}
                    </span>
                </div>
            </div>

            <div className='row'>
                <label className='col-lg-4 fw-bold text-muted'>Pays {!currentUser?.consultant.domicilePays && <i className={"fas fa-exclamation-circle text-warning"}/>}</label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{currentUser?.consultant?.domicilePays?.nomFr}</span>
                </div>
            </div>
        </div>

        <div className="pb-6">
            <h3 className={"mt-8 mb-4"}>Personne à contacter en cas d'urgence</h3>
            <div className="separator separator-dashed mb-6"/>

            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Prénom NOM</label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{currentUser?.consultant.contactUrgencePrenom} {currentUser?.consultant.contactUrgenceNom}</span>
                </div>
            </div>

            <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Téléphone</label>

                <div className='col-lg-8'>
                    <span className='fw-bolder fs-6 text-dark'>{currentUser?.consultant.contactUrgenceTelPays?.phoneCodeWithPlusPrefix} {currentUser?.consultant.contactUrgenceTel}</span>
                </div>
            </div>
        </div>
    </>

}

