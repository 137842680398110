import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { Link, useNavigate } from "react-router-dom"
import moment from "moment"
import React from "react"
import { ConsultantDeclarationMensuelleModel, ConsultantDeclarationMensuelleSuiviModel, IHttpErrorResponseModel } from "../../../../../models"
import { declarationListing } from "../../core/_request"
import { useConsultantDeclarationActions } from "./core/_hooks"
import { FirstLetterUppercase } from "../../../../../helpers/helper-global"
import {
     CONST_DECLARATION_SUIVI_TYPE_BM_A_VALIDE_ET_ENVOYE_DOCUMENTS_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VEUT_ET_PEUT_APPORTER_MODIFICATION_SANS_VALIDATION_PAR_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_VALIDATION_REGUL_PAR_RESP,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_MODIF_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_VALIDATION_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
     CONST_DECLARATION_SUIVI_TYPE_DEMANDE_REGUL_PAR_CONSULTANT,
     CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_REJET_REGUL_PAR_RESP,
     CONST_DECLARATION_SUIVI_TYPE_RESP_VIENT_DE_DEMANDER_UNE_RECTIFICATION_APRES_SIGNATURE_APRES_DEMANDE_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_VALIDATION_PAR_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_VALIDATION_REGUL_PAR_RESP,
} from "../../../../../constants"
import MyCard from "../../../../../utils/MyCard"
import MySimpleSpinner from "../../../../../utils/MySimpleSpinner"
import MyAlert from "../../../../../utils/MyAlert"
import MyTooltip from "../../../../../utils/MyTooltip"

const REACT_QUERY_KEY_GET_LISTING = "REACT_QUERY_KEY_GET_LISTING"
export const DeclarationList = () => {
     const navigate = useNavigate()
     const queryClient = useQueryClient()

     const listingQuery = useQuery<ConsultantDeclarationMensuelleModel[], AxiosError>(REACT_QUERY_KEY_GET_LISTING, async () => {
          return declarationListing()
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     const { actions } = useConsultantDeclarationActions()

     function entamerRectificationSansValidationRespOuSiege(declaration: ConsultantDeclarationMensuelleModel) {
          actions.entamerRectificationSansValidationBmOuSiege(declaration.id as number, () =>
              navigate("update", {
                   state: {
                        declaration_id: declaration.id,
                        default_step: "recap",
                        breadCrumbPageTitle: FirstLetterUppercase(moment(declaration.mois).format("MMMM YYYY")),
                   },
              })
          )
     }

     function handleDemandeRegulCallback(declaration_ID: number, suivi: ConsultantDeclarationMensuelleSuiviModel) {
          queryClient.setQueryData<ConsultantDeclarationMensuelleModel[] | undefined>(REACT_QUERY_KEY_GET_LISTING, (prev: ConsultantDeclarationMensuelleModel[] | undefined) => {
               if (prev && prev?.length > 0) {
                    const index = prev.findIndex(item => item?.id === declaration_ID)
                    if (index >= 0) {
                         prev[index] = {
                              ...prev[index],
                              etat: suivi,
                         }
                         return prev
                    }
               }

               return prev
          })
     }

     const BadgeAccordingToEtat = ({ declaration }: { declaration: ConsultantDeclarationMensuelleModel }) => {
          return (
               <div>
                    {[null, undefined, CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VEUT_ET_PEUT_APPORTER_MODIFICATION_SANS_VALIDATION_PAR_BM_OU_SIEGE].includes(
                         declaration.etat?.type
                    ) && (
                         <span className="badge badge-warning text-black">
                              À COMPLÉTER <i className={"fas fa-exclamation-triangle text-black ms-1"} />
                         </span>
                    )}
                    {[
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_MODIF_SANS_VALIDATION_BM_OU_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_REGUL,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_VALIDATION_REGUL_PAR_RESP,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM,
                    ].includes(declaration.etat?.type as string) && (
                         <span className="badge badge-warning text-black">
                              À SIGNER <i className={"fas fa-exclamation-triangle text-black ms-1"} />
                         </span>
                    )}
                    {[
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_VALIDATION_REGUL,
                         CONST_DECLARATION_SUIVI_TYPE_BM_A_VALIDE_ET_ENVOYE_DOCUMENTS_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE,
                    ].includes(declaration.etat?.type as string) && <span className="badge badge-light-success">SIGNÉE ET ENVOYÉE</span>}
                    {[
                         CONST_DECLARATION_SUIVI_TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT,
                         CONST_DECLARATION_SUIVI_TYPE_VALIDATION_REGUL_PAR_RESP,
                         CONST_DECLARATION_SUIVI_TYPE_RESP_VIENT_DE_DEMANDER_UNE_RECTIFICATION_APRES_SIGNATURE_APRES_DEMANDE_REGUL,
                    ].includes(declaration.etat?.type as string) && <span className="badge badge-light-danger">À RECTIFIER</span>}
                    {[
                         CONST_DECLARATION_SUIVI_TYPE_VALIDATION_PAR_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_DEMANDE_REGUL_PAR_CONSULTANT,
                         CONST_DECLARATION_SUIVI_TYPE_REJET_REGUL_PAR_RESP,
                    ].includes(declaration.etat?.type as string) && <span className="badge badge-success">Validée</span>}
               </div>
          )
     }

     return (
          <div className={"d-flex justify-content-center"}>
               <div className={"w-100 w-lg-75 w-xxl-65"}>
                    <MyCard>
                         <MyCard.Header
                              rightSideContent={
                                   <>
                                        <button type={"button"} className={`btn btn-light-primary btn-sm me-2 d-flex align-items-center`} onClick={() => listingQuery.refetch()}>
                                             <span className={`d-md-inline d-none`}>Recharger la liste</span>
                                             <i className={"fas fa-sync ms-md-2 pe-0 fs-3"} />
                                        </button>
                                        <Link className={`btn btn-sm btn-primary d-flex align-items-center`} to={"new"}>
                                             Nouvelle déclaration <span className={"fas fa-plus-circle fs-3 ms-1"} />
                                        </Link>
                                   </>
                              }
                         >
                              Mes déclarations
                         </MyCard.Header>
                         <MyCard.Body>
                              {listingQuery.isFetching && (
                                   <div className={"text-center"}>
                                        <MySimpleSpinner size={"xl"} />
                                   </div>
                              )}

                              {!listingQuery.isFetching && !listingQuery.isError && listingQuery.data && listingQuery.data.length === 0 && (
                                   <MyAlert type={"primary"}>
                                        <div>Aucune déclaration trouvée</div>
                                   </MyAlert>
                              )}

                              {listingQuery.isError && (
                                   <MyAlert type={"danger"}>
                                        <div className={"d-flex align-items-center"}>
                                             <div className={"me-2"}>{listingQuery.error.response?.data.detail}</div>
                                             <button className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"} onClick={() => listingQuery.refetch()}>
                                                  Recharger
                                             </button>
                                        </div>
                                   </MyAlert>
                              )}

                              {!listingQuery.isFetching && !listingQuery.isError && listingQuery.data && listingQuery.data.length > 0 && (
                                   <>
                                        <div className={"row"} style={{ maxHeight: "55vh", overflow: "auto" }}>
                                             {listingQuery.data.map((item, key) => (
                                                  <div className="col-md-6 col-xl-4 mb-9 card" key={key}>
                                                       <div className="card-header border border-primary bg-primary" style={{ minHeight: 0 }}>
                                                            <div className="card-title">
                                                                 <Link
                                                                      to={`update`}
                                                                      style={{ color: "inherit" }}
                                                                      state={{
                                                                           declaration_id: item.id,
                                                                           default_step: "recap",
                                                                           breadCrumbPageTitle: FirstLetterUppercase(moment(item.mois).format("MMMM YYYY")),
                                                                      }}
                                                                 >
                                                                      {FirstLetterUppercase(item.mois?.format("MMMM YYYY"))}
                                                                 </Link>
                                                            </div>
                                                            <div className="card-toolbar">
                                                                 <BadgeAccordingToEtat declaration={item} />
                                                            </div>
                                                       </div>
                                                       <div className="card-body text-center border-start border-end border-bottom border-primary p-4">
                                                            <div className={"d-flex justify-content-center"}>
                                                                 <MyTooltip title={"Arrêts maladie"}>
                                                                      <Link
                                                                           to={`update`}
                                                                           style={{ verticalAlign: "middle" }}
                                                                           state={{
                                                                                declaration_id: item.id,
                                                                                default_step: "arrets",
                                                                                breadCrumbPageTitle: FirstLetterUppercase(moment(item.mois).format("MMMM YYYY")),
                                                                           }}
                                                                           className={`p-3`}
                                                                      >
                                                                           <span className={"fas fa-file-medical text-dark"} style={{ fontSize: "2rem" }} />
                                                                      </Link>
                                                                 </MyTooltip>

                                                                 <MyTooltip title={"Demandes de congés"}>
                                                                      <Link
                                                                           to={`update`}
                                                                           style={{ verticalAlign: "middle" }}
                                                                           state={{
                                                                                declaration_id: item.id,
                                                                                default_step: "ddc",
                                                                                breadCrumbPageTitle: FirstLetterUppercase(moment(item.mois).format("MMMM YYYY")),
                                                                           }}
                                                                           className={`p-3`}
                                                                      >
                                                                           <span className={"fas fa-umbrella-beach text-dark"} style={{ fontSize: "2rem" }} />
                                                                      </Link>
                                                                 </MyTooltip>

                                                                 <MyTooltip title={"CRA"}>
                                                                      <Link
                                                                           to={`update`}
                                                                           style={{ verticalAlign: "middle" }}
                                                                           state={{
                                                                                declaration_id: item.id,
                                                                                default_step: "cra",
                                                                                breadCrumbPageTitle: FirstLetterUppercase(moment(item.mois).format("MMMM YYYY")),
                                                                           }}
                                                                           className={`p-3`}
                                                                      >
                                                                           <span className={"fas fa-calendar-days text-dark"} style={{ fontSize: "2rem" }} />
                                                                      </Link>
                                                                 </MyTooltip>

                                                                 <MyTooltip title={"NDF"}>
                                                                      <Link
                                                                           to={`update`}
                                                                           style={{ verticalAlign: "middle" }}
                                                                           state={{
                                                                                declaration_id: item.id,
                                                                                default_step: "ndf",
                                                                                breadCrumbPageTitle: FirstLetterUppercase(moment(item.mois).format("MMMM YYYY")),
                                                                           }}
                                                                           className={`p-3`}
                                                                      >
                                                                           <span className={"fas fa-file-invoice-dollar text-dark"} style={{ fontSize: "2rem" }} />
                                                                      </Link>
                                                                 </MyTooltip>

                                                                 <MyTooltip title={"TR"}>
                                                                      <Link
                                                                           to={`update`}
                                                                           style={{ verticalAlign: "middle" }}
                                                                           state={{
                                                                                declaration_id: item.id,
                                                                                default_step: "ticket_resto",
                                                                                breadCrumbPageTitle: FirstLetterUppercase(moment(item.mois).format("MMMM YYYY")),
                                                                           }}
                                                                           className={`p-3`}
                                                                      >
                                                                           <span className={"fas fa-utensils text-dark"} style={{ fontSize: "2rem" }} />
                                                                      </Link>
                                                                 </MyTooltip>

                                                                 <MyTooltip title={"Récapitulatif"}>
                                                                      <Link
                                                                           to={`update`}
                                                                           style={{ verticalAlign: "middle" }}
                                                                           state={{
                                                                                declaration_id: item.id,
                                                                                default_step: "recap",
                                                                                breadCrumbPageTitle: FirstLetterUppercase(moment(item.mois).format("MMMM YYYY")),
                                                                           }}
                                                                           className={`p-3`}
                                                                      >
                                                                           <span className={"fas fa-list text-dark"} style={{ fontSize: "2rem" }} />
                                                                      </Link>
                                                                 </MyTooltip>
                                                            </div>

                                                            <div className="separator separator-dashed mb-2"></div>

                                                            <div className="d-flex justify-content-center">
                                                                 {[
                                                                      undefined,
                                                                      CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VEUT_ET_PEUT_APPORTER_MODIFICATION_SANS_VALIDATION_PAR_BM_OU_SIEGE,
                                                                 ].includes(item.etat?.type as string) && (
                                                                      <>
                                                                           <MyTooltip title={"Consulter ma déclaration"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-primary me-2"}
                                                                                     onClick={() =>
                                                                                         navigate("update", {
                                                                                              state: {
                                                                                                   declaration_id: item.id,
                                                                                                   default_step: "recap",
                                                                                                   breadCrumbPageTitle: FirstLetterUppercase(moment(item.mois).format("MMMM YYYY")),
                                                                                              },
                                                                                         })
                                                                                     }
                                                                                >
                                                                                     <span className={"fas fa-eye align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>
                                                                           <button
                                                                                type={"button"}
                                                                                className={"btn btn-sm btn-success"}
                                                                                onClick={() =>
                                                                                    navigate("update", {
                                                                                         state: {
                                                                                              declaration_id: item.id,
                                                                                              default_step: "recap",
                                                                                              breadCrumbPageTitle: FirstLetterUppercase(moment(item.mois).format("MMMM YYYY")),
                                                                                         },
                                                                                    })
                                                                                }
                                                                           >
                                                                                Finaliser <span className={"fas fa-check-circle align-middle fs-4"} />
                                                                           </button>
                                                                      </>
                                                                 )}

                                                                 {[
                                                                      CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM,
                                                                      CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_VALIDATION_REGUL_PAR_RESP,
                                                                      CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_REGUL,
                                                                 ].includes(item.etat?.type as string) && (
                                                                      <>
                                                                           <MyTooltip title={"Consulter ma déclaration"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-primary me-2"}
                                                                                     onClick={() =>
                                                                                          navigate(`update`, {
                                                                                               state: {
                                                                                                    declaration_id: item.id,
                                                                                                    default_step: "recap",
                                                                                                    breadCrumbPageTitle: FirstLetterUppercase(
                                                                                                         moment(item.mois).format("MMMM YYYY")
                                                                                                    ),
                                                                                               },
                                                                                          })
                                                                                     }
                                                                                >
                                                                                     <span className={"fas fa-eye align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>

                                                                           <MyTooltip title={"Re-envoyer le mail de finalisation pour signature"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-info"}
                                                                                     onClick={() => actions.reenvoyerMailFinalisation(item?.id as number)}
                                                                                >
                                                                                     <span className={"fas fa-paper-plane align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>
                                                                      </>
                                                                 )}

                                                                 {[
                                                                      CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
                                                                      CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                                                                 ].includes(item.etat?.type as string) && (
                                                                      <>
                                                                           <MyTooltip title={"Apporter une modification"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-warning me-2"}
                                                                                     onClick={() => entamerRectificationSansValidationRespOuSiege(item)}
                                                                                >
                                                                                     <span className={"fas fa-edit align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>

                                                                           <MyTooltip title={"Consulter ma déclaration"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-primary"}
                                                                                     onClick={() =>
                                                                                          navigate(`update`, {
                                                                                               state: {
                                                                                                    declaration_id: item.id,
                                                                                                    default_step: "recap",
                                                                                                    breadCrumbPageTitle: FirstLetterUppercase(
                                                                                                         moment(item.mois).format("MMMM YYYY")
                                                                                                    ),
                                                                                               },
                                                                                          })
                                                                                     }
                                                                                >
                                                                                     <span className={"fas fa-eye align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>
                                                                      </>
                                                                 )}

                                                                 {[
                                                                      CONST_DECLARATION_SUIVI_TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT,
                                                                      CONST_DECLARATION_SUIVI_TYPE_VALIDATION_REGUL_PAR_RESP,
                                                                      CONST_DECLARATION_SUIVI_TYPE_RESP_VIENT_DE_DEMANDER_UNE_RECTIFICATION_APRES_SIGNATURE_APRES_DEMANDE_REGUL,
                                                                 ].includes(item.etat?.type as string) && (
                                                                      <button
                                                                           type={"button"}
                                                                           className={"btn btn-sm btn-danger"}
                                                                           onClick={() =>
                                                                                navigate('update', {
                                                                                     state: {
                                                                                          declaration_id: item.id,
                                                                                          default_step: "recap",
                                                                                          breadCrumbPageTitle: FirstLetterUppercase(moment(item.mois).format("MMMM YYYY")),
                                                                                     },
                                                                                })
                                                                           }
                                                                      >
                                                                           Apporter une correction <i className={"fas fa-edit align-middle fs-4"} />
                                                                      </button>
                                                                 )}

                                                                 {[
                                                                      CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME,
                                                                      CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION,
                                                                      CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_MODIF_SANS_VALIDATION_BM_OU_SIEGE,
                                                                      CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM,
                                                                      CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                                                                 ].includes(item.etat?.type as string) && (
                                                                      <>
                                                                           <MyTooltip title={"Consulter ma déclaration"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-primary me-2"}
                                                                                     onClick={() =>
                                                                                          navigate('update', {
                                                                                               state: {
                                                                                                    declaration_id: item.id,
                                                                                                    default_step: "recap",
                                                                                                    breadCrumbPageTitle: FirstLetterUppercase(
                                                                                                         moment(item.mois).format("MMMM YYYY")
                                                                                                    ),
                                                                                               },
                                                                                          })
                                                                                     }
                                                                                >
                                                                                     <span className={"fas fa-eye align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>
                                                                           <MyTooltip title={"Apporter une modification"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-warning me-2"}
                                                                                     onClick={() => entamerRectificationSansValidationRespOuSiege(item)}
                                                                                >
                                                                                     <span className={"fas fa-edit align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>
                                                                           <MyTooltip title={"Re-envoyer le mail de finalisation pour signature"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-info"}
                                                                                     onClick={() => actions.reenvoyerMailFinalisation(item?.id as number)}
                                                                                >
                                                                                     <span className={"fas fa-paper-plane align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>
                                                                      </>
                                                                 )}

                                                                 {[
                                                                      CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM,
                                                                      CONST_DECLARATION_SUIVI_TYPE_DEMANDE_REGUL_PAR_CONSULTANT,
                                                                      CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_VALIDATION_REGUL,
                                                                      CONST_DECLARATION_SUIVI_TYPE_BM_A_VALIDE_ET_ENVOYE_DOCUMENTS_SIEGE,
                                                                      CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE,
                                                                 ].includes(item.etat?.type as string) && (
                                                                      <button
                                                                           type={"button"}
                                                                           className={"btn btn-sm btn-primary"}
                                                                           onClick={() =>
                                                                                navigate(`update`, {
                                                                                     state: {
                                                                                          declaration_id: item.id,
                                                                                          default_step: "recap",
                                                                                          breadCrumbPageTitle: FirstLetterUppercase(moment(item.mois).format("MMMM YYYY")),
                                                                                     },
                                                                                })
                                                                           }
                                                                      >
                                                                           Consulter <i className={"fas fa-eye align-middle fs-4"} />
                                                                      </button>
                                                                 )}

                                                                 {[CONST_DECLARATION_SUIVI_TYPE_VALIDATION_PAR_SIEGE, CONST_DECLARATION_SUIVI_TYPE_REJET_REGUL_PAR_RESP].includes(
                                                                      item.etat?.type as string
                                                                 ) && (
                                                                      <>
                                                                           <MyTooltip title={"Consulter ma déclaration"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-primary me-2"}
                                                                                     onClick={() =>
                                                                                          navigate(`update`, {
                                                                                               state: {
                                                                                                    declaration_id: item.id,
                                                                                                    default_step: "recap",
                                                                                                    breadCrumbPageTitle: FirstLetterUppercase(
                                                                                                         moment(item.mois).format("MMMM YYYY")
                                                                                                    ),
                                                                                               },
                                                                                          })
                                                                                     }
                                                                                >
                                                                                     <span className={"fas fa-eye align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>
                                                                           <MyTooltip title={"demander une régularisation"}>
                                                                                <button
                                                                                     type={"button"}
                                                                                     className={"btn btn-sm btn-danger me-2"}
                                                                                     onClick={() => actions.demandeRegul(item?.id as number, handleDemandeRegulCallback)}
                                                                                >
                                                                                     <span className={"fas fa-hand align-middle fs-4"} />
                                                                                </button>
                                                                           </MyTooltip>
                                                                      </>
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </div>
                                             ))}
                                        </div>
                                   </>
                              )}
                         </MyCard.Body>
                    </MyCard>
               </div>
          </div>
     )
}

