import {useAuth} from "../../../../../auth";
import React, {useMemo} from "react";
import {CONST_CONTRAT_ST} from "../../../../../../constants";
import {InfoPersoViewOnlyEspacePreemabaucheSousTraitant} from "./InfoPersoViewOnlyEspacePreemabaucheSousTraitant";
import {InfoPersoViewOnlyEspacePreemabaucheSalarie} from "./InfoPersoViewOnlyEspacePreemabaucheSalarie";

export function InfoPersoViewOnlyEspacePreemabauche() {
    const {currentUser} = useAuth()

    return <>
        {currentUser?.consultant.contrat === CONST_CONTRAT_ST ? <InfoPersoViewOnlyEspacePreemabaucheSousTraitant/> : <InfoPersoViewOnlyEspacePreemabaucheSalarie/>}
    </>
}

