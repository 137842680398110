import axios from "axios";
import {CandidatDocumentModel} from "../../models";
import {API_BASE_URL} from "../../constants";


export function setDocoAsCompleted(doco_id: number) {
    return axios.post<{ message: string }>(`${API_BASE_URL}/set-doco-as-completed`, {doco_id})
}

export function setDocoAsEnCours(doco_id: number) {
    return axios.post<{ message: string }>(`${API_BASE_URL}/set-doco-as-en-cours`, {doco_id})
}

export function getDocos() {
    return axios.get<CandidatDocumentModel[]>(`${API_BASE_URL}/docos`)
}