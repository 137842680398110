import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {CompteHeader} from "./CompteHeader";
import {Compte} from "./components/compte/Compte";
import {useAuth} from "../auth";
import {DocumentsWrapper} from "./components/Documents";
import {InfoPersoEditWrapper} from "./components/infoPerso/infoPersoEdit/InfoPersoEdit";
import {InfoPersoViewWrapper} from "./components/infoPerso/infoPersoViewOnly/InfoPersoViewOnly";

const profilBreadCrumbs: Array<PageLink> = [
    {
        title: "Mon compte",
        path: '/mon-compte/profil',
    }
]

const ComptePage = () => {
    return <Routes>
        <Route element={
            <>
                <CompteHeader/>
                <Outlet/>
            </>
        }>
            <Route
                path='/profil'
                element={
                    <>
                        <PageTitle breadcrumbs={profilBreadCrumbs}>Aperçu du profil</PageTitle>
                        <InfoPersoViewWrapper/>
                    </>
                }
            />

            <Route
                path='/profil/edit'
                element={
                    <>
                        <PageTitle breadcrumbs={profilBreadCrumbs}>Edition du profil</PageTitle>
                        <InfoPersoEditWrapper/>
                    </>
                }
            />

            <Route
                path='/settings'
                element={
                    <>
                        <PageTitle breadcrumbs={profilBreadCrumbs}>Paramètres du compte</PageTitle>
                        <Compte/>
                    </>
                }
            />

            <Route
                path='/mes-documents'
                element={
                    <>
                        <PageTitle breadcrumbs={profilBreadCrumbs}>Mes documents</PageTitle>
                        <DocumentsWrapper/>
                    </>
                }
            />


            <Route index element={<Navigate to='profil'/>}/>

        </Route>
    </Routes>
}

export default ComptePage