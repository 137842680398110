import { toAbsoluteUrl } from "../../../_metronic/helpers"
import React, { FC, useEffect, useState } from "react"
import MyModal from "../../utils/MyModal"
import { toast } from "react-toastify"
import { CONST_DEFAULT_TOAST_OPTIONS } from "../../constants"
import { AxiosError, AxiosResponse } from "axios"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { useIntl } from "react-intl"
import { getDocos, setDocoAsCompleted, setDocoAsEnCours } from "./_requests"
import { CandidatDocumentModel } from "../../models"
import MyTooltip from "../../utils/MyTooltip"
import MyAlert from "../../utils/MyAlert"
import { BrowserView, MobileView } from "react-device-detect"

function Docos() {
     const [docos, setDocos] = useState<CandidatDocumentModel[]>([])
     const [loadingDocos, setLoadingDocos] = useState<boolean>(true)
     const [error, setError] = useState<string | null>(null)

     const intl = useIntl()
     const MySwal = withReactContent(Swal)

     useEffect(() => {
          fetchDocos()
     }, [])

     const [showingDocoInModal, setShowingDocoInModal] = useState<CandidatDocumentModel | null>(null)

     function handleSetDocoAsCompleted(doco: CandidatDocumentModel) {
          MySwal.fire({
               icon: "warning",
               title: `Vous êtes sur le point d'envoyer votre dossier. Voulez-vous procéder?`,
               html: `Une notification par mail sera envoyée à votre contact ${doco!.autoGeneratedPar.prenomNom}`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return setDocoAsCompleted(doco!.id)
                         .then(() => {
                              setDocos(prev => {
                                   const indexDoco = prev.findIndex(item => item.id === doco!.id)
                                   prev[indexDoco] = { ...prev[indexDoco], remplisTerminadoParCandidat: true }
                                   if (showingDocoInModal) {
                                        setShowingDocoInModal(null)
                                   }

                                   return [...prev]
                              })
                              toast.success("Merci, Nous sommes prévenus par mail. ", CONST_DEFAULT_TOAST_OPTIONS)
                         })
                         .catch((e: AxiosError) => {
                              toast.error(e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function handleSetDocoAsEnCours(doco: CandidatDocumentModel) {
          MySwal.fire({
               icon: "warning",
               title: `Souhaitez-vous reprendre l'édition de votre document?`,
               html: `Une notification par mail sera envoyée à votre contact ${doco!.autoGeneratedPar.prenomNom}`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: () => {
                    return setDocoAsEnCours(doco!.id)
                         .then(() => {
                              setDocos(prev => {
                                   const indexDoco = prev.findIndex(item => item.id === doco!.id)
                                   prev[indexDoco] = { ...prev[indexDoco], remplisTerminadoParCandidat: false }
                                   setShowingDocoInModal(prev[indexDoco])

                                   return [...prev]
                              })
                              toast.success("Merci, Nous sommes prévenus par mail. ", CONST_DEFAULT_TOAST_OPTIONS)
                         })
                         .catch((e: AxiosError) => {
                              toast.error(e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function fetchDocos() {
          setError(null)
          setLoadingDocos(true)

          getDocos()
               .then((r: AxiosResponse) => {
                    setDocos(r.data)
               })
               .catch((e: AxiosError) => {
                    setError(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
               })
               .finally(() => {
                    setLoadingDocos(false)
               })
     }

     return (
          <div className="card mb-5 mb-xl-10">
               <div className="card-header cursor-pointer">
                    <div className="card-title m-0">
                         <h3 className="fw-bolder m-0">Mes dossiers de compétences</h3>
                    </div>
                    <div className={"card-toolbar"}>
                         <button className={"btn btn-sm btn-secondary float-end"} onClick={() => fetchDocos()} disabled={loadingDocos}>
                              <i className={"fas fa-refresh p-0 pe-sm-2"} />
                              <span className={"d-none d-sm-inline"}>Recharger</span>
                         </button>
                    </div>
               </div>

               <div className="card-body p-9" style={{ maxHeight: "73vh", overflow: "scroll" }}>
                    {loadingDocos && (
                         <div className={"text-center p-4"}>
                              <span className="spinner-border mb-2"></span>
                              <p style={{ fontWeight: 500 }}>Chargement des DoCos en cours ...</p>
                         </div>
                    )}

                    {!loadingDocos && error && (
                         <div className="mb-lg-15 alert alert-danger">
                              <div className="alert-text font-weight-bold">{error}</div>
                         </div>
                    )}

                    {!loadingDocos && (
                         <>
                              {docos.length == 0 && (
                                   <MyAlert type={"primary"} title={"Aucun dossier de disponible"} classNames={"w-xl-50"}>
                                        <>
                                             <div className={"mb-4"}>Nous vous donnerons accès à l'édition d'un dossier de compétences si nécessaire.</div>
                                        </>
                                   </MyAlert>
                              )}
                              {docos.length > 0 && (
                                   <div className="row g-6 g-xl-9 mb-6 mb-xl-9">
                                        {docos.map((item, key) => (
                                             <div className="col-md-6 col-lg-4 col-xl-3" key={key}>
                                                  {/*begin::Card*/}
                                                  <div className="card h-100">
                                                       {/*begin::Card body*/}
                                                       <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                                            {/*begin::Name*/}
                                                            <a
                                                                 href="#"
                                                                 className="text-gray-800 text-hover-primary d-flex flex-column"
                                                                 onClick={e => {
                                                                      e.preventDefault()
                                                                      setShowingDocoInModal(item)
                                                                 }}
                                                            >
                                                                 {/*begin::Image*/}
                                                                 <div className="symbol symbol-60px mb-5">
                                                                      {item.isLocked && (
                                                                           <span
                                                                                className={"fas fa-lock fs-3"}
                                                                                style={{ left: "43%", top: "-12%", position: "absolute", color: "lightslategrey" }}
                                                                           />
                                                                      )}
                                                                      <img src={toAbsoluteUrl("/media/svg/files/doc.svg")} className="theme-light-show" alt="" />
                                                                      <img src={toAbsoluteUrl("/media/svg/files/doc.svg")} className="theme-dark-show" alt="" />
                                                                 </div>
                                                                 {/*end::Image*/}
                                                                 {/*begin::Title*/}
                                                                 <div className="fs-5 fw-bold mb-3">{item.intitule ? item.intitule : `DoCo n°${key + 1}`}</div>
                                                                 {/*end::Title*/}
                                                            </a>
                                                            <div className={"text-center mb-4"}>
                                                                 {item.isLocked ? (
                                                                      <div style={{ color: "lightslategrey" }}>Document verouillé</div>
                                                                 ) : (
                                                                      <>
                                                                           {!item.remplisTerminadoParCandidat ? (
                                                                                <>
                                                                                     <MyTooltip title={"Reprendre l'édition"}>
                                                                                          <button
                                                                                               className={"btn btn-warning btn-sm me-2 text-black"}
                                                                                               onClick={e => {
                                                                                                    e.preventDefault()
                                                                                                    setShowingDocoInModal(item)
                                                                                               }}
                                                                                          >
                                                                                               <span className={"fas fa-edit fs-3"} style={{ verticalAlign: "middle" }} />
                                                                                          </button>
                                                                                     </MyTooltip>
                                                                                     <MyTooltip title={"Finaliser et envoyer"}>
                                                                                          <button
                                                                                               className={"btn btn-success btn-sm text-black"}
                                                                                               onClick={() => handleSetDocoAsCompleted(item)}
                                                                                          >
                                                                                               <span className={"fas fa-paper-plane fs-3"} style={{ verticalAlign: "middle" }} />
                                                                                          </button>
                                                                                     </MyTooltip>
                                                                                </>
                                                                           ) : (
                                                                                <button
                                                                                     className={"btn btn-warning btn-sm text-dark"}
                                                                                     onClick={() => handleSetDocoAsEnCours(item)}
                                                                                     disabled={item.isLocked}
                                                                                >
                                                                                     Reprendre l'édition
                                                                                </button>
                                                                           )}
                                                                      </>
                                                                 )}
                                                            </div>
                                                            {/*end::Name*/}
                                                            <div className="fs-7 fw-semibold text-dark mb-2">
                                                                 <div className={"mb-1"}>
                                                                      Ajouté le <b>{item.createdAt.format("DD/MM/YYYY")}</b>{" "}
                                                                      {item.autoGeneratedPar && <b>par {item.autoGeneratedPar.prenomNom}</b>}
                                                                 </div>
                                                                 <div>
                                                                      {item.remplisTerminadoParCandidat ? (
                                                                           <span className={"text-success"}>
                                                                                Complété et envoyé{" "}
                                                                                <i className={"fas fa-check-circle text-success fs-3"} style={{ verticalAlign: "middle" }} />{" "}
                                                                           </span>
                                                                      ) : (
                                                                           <span className={"text-warning"}>
                                                                                Non envoyé{" "}
                                                                                <i className={"fas fa-exclamation-circle text-warning fs-3"} style={{ verticalAlign: "middle" }} />{" "}
                                                                           </span>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       {/*end::Card body*/}
                                                  </div>
                                                  {/*end::Card*/}
                                             </div>
                                        ))}
                                   </div>
                              )}
                         </>
                    )}
               </div>

               {showingDocoInModal && (
                    <MyModal
                         title={<></>}
                         titleMiddle={
                              <div className={"text-center"}>
                                   <h3 className={"mb-2"}>{showingDocoInModal.intitule}</h3>
                                   <div className="separator separator-dashed mb-2 text-warning" />
                                   {showingDocoInModal.isLocked ? (
                                        <h6 style={{ color: "lightslategrey" }}>Document verouillé</h6>
                                   ) : (
                                        <>
                                             {!showingDocoInModal.remplisTerminadoParCandidat ? (
                                                  <>
                                                       <button
                                                            className={"btn btn-primary btn-sm me-2 d-lg-inline d-none"}
                                                            onClick={() =>
                                                                 toast.info("Vos modifications ont bien été enregistrées.", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: 1000 })
                                                            }
                                                       >
                                                            <i className={"fas fa-save pe-2"} />
                                                            <span className={""}>Enregistrer vos modifications</span>
                                                       </button>
                                                       <button className={"btn btn-success btn-sm"} onClick={() => handleSetDocoAsCompleted(showingDocoInModal)}>
                                                            <i className={"fas fa-check-circle pe-2"} />
                                                            <span className={""}>Finaliser et envoyer</span>
                                                       </button>
                                                  </>
                                             ) : (
                                                  <button
                                                       className={"btn btn-warning btn-sm text-black"}
                                                       onClick={() => handleSetDocoAsEnCours(showingDocoInModal)}
                                                       disabled={showingDocoInModal.isLocked}
                                                  >
                                                       Reprendre l'édition
                                                  </button>
                                             )}
                                        </>
                                   )}
                              </div>
                         }
                         show={!!showingDocoInModal}
                         fullscreen={true}
                         handleClose={() => setShowingDocoInModal(null)}
                    >
                         <ViewDoco doco={showingDocoInModal} />
                    </MyModal>
               )}
          </div>
     )
}

const DocosWrapper: FC = () => {
     return <Docos />
}

function ViewDoco({ doco }: { doco: CandidatDocumentModel }) {
     const [loading, setLoading] = useState<boolean>(true)
     return (
          <>
               {doco.isLocked ? (
                    <>
                         <MobileView className={"d-flex justify-content-center"}>
                              <a className={"btn btn-primary btn-sm"} href={`https://docs.google.com/document/d/${doco.googleDriveID}/edit`}>
                                   <i className={"fas fa-file-word pe-2"} />
                                   <span className={""}>Ouvrir dans Google Docs</span>
                              </a>
                         </MobileView>
                         <iframe
                              onLoad={() => setLoading(false)}
                              src={`https://docs.google.com/document/d/${doco.googleDriveID}/preview`}
                              style={{ display: "block", width: "100%", height: "88vh" }}
                         />
                    </>
               ) : (
                    <>
                         {doco.remplisTerminadoParCandidat ? (
                              <>
                                   <MobileView className={"d-flex justify-content-center"}>
                                        <a className={"btn btn-primary btn-sm"} href={`https://docs.google.com/document/d/${doco.googleDriveID}/edit`}>
                                             <i className={"fas fa-file-word pe-2"} />
                                             <span className={""}>Ouvrir dans Google Docs</span>
                                        </a>
                                   </MobileView>
                                   <iframe
                                        onLoad={() => setLoading(false)}
                                        src={`https://docs.google.com/document/d/${doco.googleDriveID}/preview`}
                                        style={{ display: "block", width: "100%", height: "88vh" }}
                                   />
                              </>
                         ) : (
                              <>
                                   <BrowserView>
                                        {loading && (
                                             <div className={"text-center p-4"}>
                                                  <span className="spinner-border mb-2"></span>
                                                  <p style={{ fontWeight: 500 }}>Chargement du dossier en cours ...</p>
                                             </div>
                                        )}
                                        <div className={"d-flex justify-content-center"}>
                                             <iframe
                                                  onLoad={() => setLoading(false)}
                                                  className={"d-none d-lg-block"}
                                                  src={`https://docs.google.com/document/d/${doco.googleDriveID}/edit`}
                                                  style={{ display: "block", width: "100%", height: "88vh" }}
                                             />
                                             <h3 className={"d-lg-none"}>Veuillez élargir votre navigateur afin d'afficher le document</h3>
                                        </div>
                                   </BrowserView>

                                   <MobileView>
                                        <h3 className={"text-center"}>
                                             Veuillez télécharger l'application{" "}
                                             <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.docs.editors.docs&hl=fr&gl=US&pli=1">Google Docs</a> sur
                                             votre appareil afin d'éditer votre dossier de compétences.
                                        </h3>
                                        <div className="separator mb-6"></div>

                                        <MyAlert type={"primary"} title={"Installer Google Docs ( étape à ignorer si c'est déjà fait)"} customIcon={"fas fa-1"}>
                                             <a
                                                  className={"btn btn-primary btn-sm mt-2"}
                                                  href={"https://play.google.com/store/apps/details?id=com.google.android.apps.docs.editors.docs&hl=fr&gl=US&pli=1"}
                                             >
                                                  <i className={"fas fa-download pe-2"} />
                                                  <span className={""}>Télécharger Google Docs</span>
                                             </a>
                                        </MyAlert>
                                        <br />
                                        <MyAlert type={"primary"} title={"Ouvrir le document"} customIcon={"fas fa-2"}>
                                             <a className={"btn btn-primary btn-sm mt-2"} href={`https://docs.google.com/document/d/${doco.googleDriveID}/edit`}>
                                                  <i className={"fas fa-file-word pe-2"} />
                                                  <span className={""}>Ouvrir "{doco.intitule}" dans Google Docs</span>
                                             </a>
                                        </MyAlert>
                                        <br />
                                        <MyAlert type={"primary"} title={"Modifier le document"} customIcon={"fas fa-3"}>
                                             <span>
                                                  Sur l'application Google Docs, appuyez sur le bouton d'édition afin d'entamer votre modification. La sauvegarde se fait
                                                  automatiquement.
                                             </span>
                                        </MyAlert>
                                   </MobileView>
                              </>
                         )}
                    </>
               )}
          </>
     )
}

export { DocosWrapper }
