import {useAuth} from "../../../../../auth";
import React, {useMemo} from "react";
import {CONST_CONTRAT_ST} from "../../../../../../constants";
import {InfoPersoViewOnlyEspaceConsultantSousTraitant} from "./InfoPersoViewOnlyEspaceConsultantSousTraitant";
import {InfoPersoViewOnlyEspaceConsultantSalarie} from "./InfoPersoViewOnlyEspaceConsultantSalarie";

export function InfoPersoViewOnlyEspaceConsultant() {
    const {currentUser} = useAuth()

    return <>
        {currentUser?.consultant.contrat === CONST_CONTRAT_ST ? <InfoPersoViewOnlyEspaceConsultantSousTraitant/> : <InfoPersoViewOnlyEspaceConsultantSalarie/>}
    </>
}

