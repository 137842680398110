import { toast } from "react-toastify"
import { useMutation } from "react-query"
import useSwal from "../../../../../../hooks/useSwal"
import {
     declarationSuiviConsultantDemandeRegulRequest,
     declarationSuiviConsultantReenvoyerMailFinalisationPourSignature,
     declarationSuiviConsultantSigneDeclaration,
     declarationSuiviConsultantVeutEtPeutApporterModifSansValidationBmOuSiege,
     declarationSuiviConsultantVientDeFinaliserSaDeclaration,
} from "../../../core/_request"
import { ConsultantDeclarationMensuelleSuiviModel } from "../../../../../../models"

export const useConsultantDeclarationActions = () => {
     const { swal } = useSwal()

     const finaliserMutation = useMutation(({ declaration_ID }: { declaration_ID: number }) => declarationSuiviConsultantVientDeFinaliserSaDeclaration(declaration_ID))
     const reenvoyerMailFinalisationMutation = useMutation(({ declaration_ID }: { declaration_ID: number }) =>
          declarationSuiviConsultantReenvoyerMailFinalisationPourSignature(declaration_ID)
     )
     const entamerRectificationSansDemandeValidationRespOuSiegeMutation = useMutation(({ declaration_ID }: { declaration_ID: number }) =>
          declarationSuiviConsultantVeutEtPeutApporterModifSansValidationBmOuSiege(declaration_ID)
     )
     const signerMutation = useMutation(({ declaration_ID, code, commentaire }: { declaration_ID: number; code: string; commentaire?: string }) =>
          declarationSuiviConsultantSigneDeclaration(declaration_ID, code, commentaire)
     )

     const demandeRegulMutation = useMutation(({ declaration_ID, commentaire }: { declaration_ID: number; commentaire: string }) =>
          declarationSuiviConsultantDemandeRegulRequest(declaration_ID, commentaire)
     )

     function finaliser(declaration_ID: number, callback: () => void = () => {}) {
          swal.fire({
               icon: "warning",
               title: `Vous êtes sur le point de finaliser votre déclaration`,
               text: "Voulez-vous finaliser?",
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return finaliserMutation
                         .mutateAsync({ declaration_ID })
                         .then(() => {
                              toast.info("Afin de finaliser, veuillez signer votre déclaration à l'aide du mail que vous allez recevoir dans quelques instants.", {
                                   autoClose: false,
                              })
                              callback()
                         })
                         .catch(e => {
                              swal.fire({ icon: "error", text: e.response.data?.detail })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function reenvoyerMailFinalisation(declaration_ID: number, callback: () => void = () => {}) {
          swal.fire({
               icon: "info",
               title: `Voulez-vous procéder au renvoi du mail de finalisation pour signature?`,
               text: "Ce mail est essentiel pour signer votre déclaration mensuelle.",
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return reenvoyerMailFinalisationMutation
                         .mutateAsync({ declaration_ID })
                         .then(() => {
                              toast.info("Le mail de finalisation pour signature a bien été envoyé. Veuillez vérifier vos SPAMs si vous ne l'avez pas reçu.", { autoClose: false })
                         })
                         .catch(e => {
                              toast.error(e.response.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function entamerRectificationSansValidationBmOuSiege(declaration_ID: number, callback: (val: ConsultantDeclarationMensuelleSuiviModel) => void = () => {}) {
          swal.fire({
               icon: "info",
               title: `Voulez-vous entamer la rectification de votre déclaration mensuelle?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return entamerRectificationSansDemandeValidationRespOuSiegeMutation
                         .mutateAsync({ declaration_ID })
                         .then(r => {
                              toast.info("Vous pouvez entamer la rectification de votre déclaration.")
                              callback(r.data)
                         })
                         .catch(e => {
                              toast.error(e.response.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function signer(declaration_ID: number, code: string, callback: () => void = () => {}) {
          swal.fire({
               icon: "warning",
               title: `Voulez-vous procéder à la signature de votre déclaration?`,
               text: "Si vous le souhaitez, vous avez la possibilité de fournir une remarque concernant votre déclaration.",
               input: "textarea",
               inputPlaceholder: "Remarque (optionnel)",
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async (commentaire: string) => {
                    return signerMutation
                         .mutateAsync({ declaration_ID, code, commentaire })
                         .then(() => {
                              toast.success("Votre déclaration a bien été signée.")
                              callback()
                         })
                         .catch(e => {
                              toast.error(e.response.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function demandeDeRegul(declaration_ID: number, callback: (declaration_ID: number, val: ConsultantDeclarationMensuelleSuiviModel) => void = () => {}) {
          swal.fire({
               icon: "info",
               title: `Vous êtes sur le point d'envoyer une demande de régularisation`,
               text: "Un mail sera envoyé à votre responsable direct ce qui vous permettra de modifier votre déclaration",
               showCancelButton: true,
               confirmButtonText: "Envoyer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               input: "textarea",
               inputPlaceholder: "Merci de préciser la raison de cette demande",
               inputAttributes: {
                    required: "true",
               },
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci de préciser la raison de cette demande") : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return demandeRegulMutation
                         .mutateAsync({ declaration_ID, commentaire })
                         .then(r => {
                              toast.info("Un mail a été envoyé à votre responsable direct")
                              callback(declaration_ID, r.data)
                         })
                         .catch(e => {
                              toast.error(e.response.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     return {
          mutations: {
               finaliser: finaliserMutation,
               reenvoyerMailFinalisation: reenvoyerMailFinalisationMutation,
               entamerRectificationSansDemandeValidation: entamerRectificationSansDemandeValidationRespOuSiegeMutation,
               signer: signerMutation,
               demandeRegul: demandeRegulMutation,
          },
          actions: {
               finaliser,
               reenvoyerMailFinalisation,
               entamerRectificationSansValidationBmOuSiege,
               signer,
               demandeRegul: demandeDeRegul,
          },
     }
}
