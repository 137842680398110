import * as Yup from "yup"
import React from "react"
import { Form, Formik, FormikHelpers } from "formik"
import MyAlert from "../../utils/MyAlert"
import MySimpleSpinner from "../../utils/MySimpleSpinner"
import axios, { AxiosError } from "axios"
import {
     API_BASE_URL,
     API_PUBLIC_GET_CANDIDAT_DETAILS_URL,
     API_PUBLIC_GET_DEPARTEMENTS_FRANCE_URL,
     API_PUBLIC_GET_PAYS_INDICATIFS_TEL_URL,
     API_PUBLIC_GET_PAYS_URL,
     API_PUBLIC_GET_REGIONS_FRANCE_URL,
     API_PUBLIC_GET_SECTEURS_ACTIVITE_URL,
     API_PUBLIC_GET_VILLES_FRANCE_URL,
     CONST_BUSINESS_UNIT_SECRET_DEFENSE_CODE,
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CIVILITES_OPTIONS_FOR_SELECT2,
     CONST_DISPONIBILITE_DATE_EXACTE,
     CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MOBILITE_DEPARTEMENTAL,
     CONST_MOBILITE_OPTIONS_FOR_SELECT2,
     CONST_MOBILITE_PAR_VILLE,
     CONST_MOBILITE_REGIONAL,
     CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2,
} from "../../constants"
import { useCandidatureWithoutAuthContext } from "./CandidatureWithoutAuth"
import { useQuery } from "react-query"
import { UserModel } from "../../modules/auth"
import MySelectField from "../../utils/fields/MySelectField"
import MySelectGetAllDataFromServer from "../../utils/fields/MySelectGetAllDataFromServer"
import MyInptuMaskField from "../../utils/fields/MyInputMaskField"
import MyDateDayMonthYearField from "../../utils/fields/MyDateDayMonthYearField"
import MySelectSearchDataFromServer from "../../utils/fields/MySelectSearchDataFromServer"
import MyInputField from "../../utils/fields/MyInputField"
import { IHttpErrorResponseModel } from "../../models"
import { toast } from "react-toastify"
import moment from "moment"
import MyCheckBoxField from "../../utils/fields/MyCheckBoxField"
import MyTextareaField from "../../utils/fields/MyTextareaField"

const CandidatureWithoutAuthStepTwo = () => {
     const context = useCandidatureWithoutAuthContext()

     const infoCandidatQuery = useQuery<UserModel, AxiosError>("REACT_QUERY_KEY_GET_INFO_CANDIDAT", () =>
          axios
               .get<UserModel>(API_PUBLIC_GET_CANDIDAT_DETAILS_URL(context.candidatureStep.candidat_id), { params: { email: context.candidatureStep.candidat_email } })
               .then(r => r.data)
     )

     const schema = Yup.object().shape({
          ...(infoCandidatQuery.data?.civilite === null ? { civilite: Yup.string().label(`"Civilité"`).required() } : {}),
          ...(infoCandidatQuery.data?.telIndicatif === null || infoCandidatQuery.data?.tel === null
               ? {
                      telIndicatif: Yup.number().label(`"Indicatif"`).required(),
                      tel: Yup.string().label(`"Téléphone"`).required(),
                 }
               : {}),
          ...(infoCandidatQuery.data?.pays === null ? { paysResidence: Yup.number().label(`"Pays de résidence"`).required() } : {}),
          ...(infoCandidatQuery.data?.niveauEtudes === null ? { niveauEtudes: Yup.string().label(`"Niveau d'études"`).required() } : {}),
          ...(infoCandidatQuery.data?.disponibilite === null
               ? {
                      disponibilite: Yup.string().label(`"Disponibilite"`).required(),
                      disponibiliteDateExacte: Yup.string().when("disponibilite", {
                           is: CONST_DISPONIBILITE_DATE_EXACTE,
                           then: schema => schema.required(),
                      }),
                 }
               : {}),
          ...(infoCandidatQuery.data?.mobilite === null
               ? {
                      mobilite: Yup.string().label(`"Mobilité"`).required(),
                      mobiliteRegions: Yup.array()
                           .label(`"Régions"`)
                           .when("mobilite", {
                                is: CONST_MOBILITE_REGIONAL,
                                then: schema => schema.required(),
                           }),
                      mobiliteDepartements: Yup.array()
                           .label(`"Départements"`)
                           .when("mobilite", {
                                is: CONST_MOBILITE_DEPARTEMENTAL,
                                then: schema => schema.required(),
                           }),
                      mobiliteVilles: Yup.array()
                           .label(`"Villes"`)
                           .when("mobilite", {
                                is: CONST_MOBILITE_PAR_VILLE,
                                then: schema => schema.required(),
                           }),
                 }
               : {}),
          ...(infoCandidatQuery.data?.salaireNetSouhaite === null ? { salaireNetMensuelSouhaite: Yup.number().label(`"Salaire mensuel"`).required() } : {}),
          ...(context.offreDetailsQuery.data?.businessUnitMetier?.businessUnit?.code === CONST_BUSINESS_UNIT_SECRET_DEFENSE_CODE &&
          infoCandidatQuery.data?.isHabilitableDefense === null
               ? {
                      isHabilitableDefense: Yup.boolean().label(`"Habilitable"`).required(),
                      isDejaHabiliteDefense: Yup.boolean().when("isHabilitableDefense", {
                           is: true,
                           then: schema => schema.required(),
                      }),
                      dateFinHabilitation: Yup.string().when("isDejaHabiliteDefense", {
                           is: true,
                           then: schema => schema.required(),
                      }),
                 }
               : {}),
          ...(!infoCandidatQuery.data?.curriculumExists
               ? {
                      curriculum: Yup.mixed()
                           .required()
                           .label(`"CV"`)
                           .test("fileSize", "Le CV doit avoir une taille inférieure ou égale à 10MB", (value: any) => {
                                return value && value.size <= 10000000
                           })
                           .test("fileFormat", "Le CV doit être au format PDF ou de type image", (value: any) => {
                                return value && ["application/pdf", "media/*"].includes(value.type)
                           }),
                 }
               : {}),
          ...(context.referenceAnnonce.toUpperCase() === "REF_SPON" && {
               businessUnitsCandidatureSpon: Yup.array().label(`"Secteurs"`).required().label(`"Secteurs"`),
               isCandidatureSponWithCommentaire: Yup.boolean().required(),
               commentaireCandidatureSpon: Yup.string()
                    .label(`"Commentaire"`)
                    .when("isCandidatureSponWithCommentaire", {
                         is: true,
                         then: schema => schema.required(),
                    }),
          }),
     })

     const initialValues: ICandidatureStepTwoFormRequestModel = {
          candidat: context.candidatureStep.candidat_id as number,
          jobboard: context.jobBoard,
          referenceAnnonce: context.referenceAnnonce,
          isHabilitableDefense: false, // Init
          isDejaHabiliteDefense: false, // Init
          isCandidatureSponWithCommentaire: false, // Init
     }

     function handleSubmit(values: ICandidatureStepTwoFormRequestModel, helpers: FormikHelpers<ICandidatureStepTwoFormRequestModel>) {
          helpers.setStatus(null)

          const formData = new FormData()
          for (const [key, value] of Object.entries(values)) {
               if (key === "mobiliteRegions" && value !== null) {
                    value.map((item: number) => {
                         formData.append(`data[${key}][]`, item.toString())
                    })
               }
               if (key === "mobiliteDepartements" && value !== null) {
                    value.map((item: number) => {
                         formData.append(`data[${key}][]`, item.toString())
                    })
               }
               if (key === "mobiliteVilles" && value !== null) {
                    value.map((item: number) => {
                         formData.append(`data[${key}][]`, item.toString())
                    })
               }
               if (key === "businessUnitsCandidatureSpon" && value !== null) {
                    value.map((item: number) => {
                         formData.append(`data[${key}][]`, item.toString())
                    })
               }
               if (key === "paysResidence" && value !== null) formData.append(`data[${key}]`, value.toString())
               if (key === "jobboard" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "telIndicatif" && value !== null) formData.append(`data[${key}]`, value.toString())
               if (key === "candidat" && value !== null) formData.append(`data[${key}]`, value.toString())
               if (key === "referenceAnnonce" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "civilite" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "tel" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "niveauEtudes" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "disponibilite" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "commentaireCandidatureSpon" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "mobilite" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "salaireNetMensuelSouhaite" && value !== null) formData.append(`data[${key}]`, value.toString())
               if (key === "isCandidatureSponWithCommentaire" && value !== null) formData.append(`data[${key}]`, value ? "1" : "0")
               if (key === "isHabilitableDefense" && value !== null) formData.append(`data[${key}]`, value ? "1" : "0")
               if (key === "isDejaHabiliteDefense" && value !== null) formData.append(`data[${key}]`, value ? "1" : "0")
               if (key === "dateFinHabilitation" && value !== null) formData.append(`data[${key}]`, moment(values.dateFinHabilitation, "DD/MM/YYYY").format("YYYY-MM-DD"))
               if (key === "disponibiliteDateExacte" && value !== null) formData.append(`data[${key}]`, moment(values.disponibiliteDateExacte, "DD/MM/YYYY").format("YYYY-MM-DD"))
               if (key === "curriculum" && value !== null) formData.append(`curriculum`, value)
          }

          axios.post(`${API_BASE_URL}/candidature-sans-auth-step-two`, formData)
               .then(() => {
                    context.setIsCandidatureFinished(true)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               {/* loader */}
               {infoCandidatQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />{" "}
                    </div>
               )}

               {/* error */}
               {!infoCandidatQuery.isFetching && infoCandidatQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{infoCandidatQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => infoCandidatQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {/* no data found */}
               {!infoCandidatQuery.isFetching && !infoCandidatQuery.isError && !infoCandidatQuery.data && (
                    <div className={"text-center p-4"}>
                         <p style={{ fontWeight: 500 }}>Un problème est survenu</p>
                    </div>
               )}

               {!infoCandidatQuery.isFetching && !infoCandidatQuery.isError && infoCandidatQuery.data && (
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                         {helpers => {
                              console.log(helpers.errors)
                              return (
                                   <Form noValidate autoComplete="off">
                                        {helpers.status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {helpers.status}
                                             </MyAlert>
                                        )}

                                        {/* Civilité */}
                                        {infoCandidatQuery.data?.civilite === null && (
                                             <div className="row mb-6">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Civilité</label>

                                                  <div className="col-lg-8">
                                                       <MySelectField
                                                            options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(item => item.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                                            name={"civilite"}
                                                            isSearchable={false}
                                                            value={helpers.values.civilite}
                                                            onChange={value => helpers.setFieldValue("civilite", value)}
                                                            isClearable={true}
                                                       />
                                                       {helpers.errors.civilite && <div className="text-danger">{helpers.errors.civilite}</div>}
                                                  </div>
                                             </div>
                                        )}

                                        {/* Tél */}
                                        {infoCandidatQuery.data?.tel === null && infoCandidatQuery.data?.telIndicatif === null && (
                                             <>
                                                  <div className="row mb-6">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Indicatif téléphonique</label>

                                                       <div className="col-lg-8">
                                                            <MySelectGetAllDataFromServer
                                                                 url={API_PUBLIC_GET_PAYS_INDICATIFS_TEL_URL}
                                                                 name={"telIndicatif"}
                                                                 placeholder={"Choisissez un indicatif"}
                                                                 value={helpers.values.telIndicatif}
                                                                 onChange={val => helpers.setFieldValue("telIndicatif", val)}
                                                            />
                                                            {helpers.errors.telIndicatif && (
                                                                 <div className="fv-plugins-message-container">
                                                                      <div className="fv-help-block">{helpers.errors.telIndicatif}</div>
                                                                 </div>
                                                            )}
                                                       </div>
                                                  </div>
                                                  <div className="row mb-6">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Téléphone</label>

                                                       <div className="col-lg-8">
                                                            <MyInptuMaskField
                                                                 name={"tel"}
                                                                 mask={"99 999999999999999999"}
                                                                 value={helpers.values.tel}
                                                                 placeholder={"Saisissez votre numéro"}
                                                                 inputMode={"numeric"}
                                                                 onChange={e => helpers.setFieldValue("tel", e.target.value)}
                                                                 isInvalid={!!helpers.errors.tel}
                                                            />
                                                            {helpers.errors.tel && (
                                                                 <div className="fv-plugins-message-container">
                                                                      <div className="fv-help-block">{helpers.errors.tel}</div>
                                                                 </div>
                                                            )}
                                                       </div>
                                                  </div>
                                             </>
                                        )}

                                        {/* Pays résidence */}
                                        {infoCandidatQuery.data?.pays === null && (
                                             <div className="row mb-6">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                       <span>Pays de résidence</span>
                                                  </label>

                                                  <div className="col-lg-8">
                                                       <MySelectGetAllDataFromServer
                                                            url={API_PUBLIC_GET_PAYS_URL}
                                                            name={"paysResidence"}
                                                            value={helpers.values.paysResidence}
                                                            onChange={val => helpers.setFieldValue("paysResidence", val)}
                                                       />
                                                       {helpers.errors.paysResidence && (
                                                            <div className="fv-plugins-message-container">
                                                                 <div className="fv-help-block">{helpers.errors.paysResidence}</div>
                                                            </div>
                                                       )}
                                                  </div>
                                             </div>
                                        )}

                                        {/* Niveau études */}
                                        {infoCandidatQuery.data?.niveauEtudes === null && (
                                             <div className="row mb-6">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Niveau d'études</label>

                                                  <div className="col-lg-8">
                                                       <MySelectField
                                                            options={CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2}
                                                            name={"niveauEtudes"}
                                                            isSearchable={false}
                                                            value={helpers.values.niveauEtudes}
                                                            onChange={value => helpers.setFieldValue("niveauEtudes", value)}
                                                       />
                                                       {helpers.errors.niveauEtudes && (
                                                            <div className="fv-plugins-message-container">
                                                                 <div className="fv-help-block">{helpers.errors.niveauEtudes}</div>
                                                            </div>
                                                       )}
                                                  </div>
                                             </div>
                                        )}

                                        {/* Dispo */}
                                        {infoCandidatQuery.data?.disponibilite === null && (
                                             <div className="row mb-6">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Disponibilité</label>

                                                  <div className="col-lg-8">
                                                       <MySelectField
                                                            options={CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2}
                                                            name={"disponibilite"}
                                                            isSearchable={false}
                                                            value={helpers.values.disponibilite}
                                                            onChange={value => helpers.setFieldValue("disponibilite", value)}
                                                       />
                                                       {helpers.errors.disponibilite && (
                                                            <div className="fv-plugins-message-container">
                                                                 <div className="fv-help-block">{helpers.errors.disponibilite}</div>
                                                            </div>
                                                       )}
                                                       {helpers.values.disponibilite === CONST_DISPONIBILITE_DATE_EXACTE && (
                                                            <>
                                                                 <br />
                                                                 <MyDateDayMonthYearField
                                                                      onChange={date => helpers.setFieldValue("disponibiliteDateExacte", date)}
                                                                      value={helpers.values.disponibiliteDateExacte}
                                                                      name={"disponibiliteDateExacte"}
                                                                      placeholder={"Date de disponibilité"}
                                                                 />
                                                                 {helpers.errors.disponibiliteDateExacte && (
                                                                      <div className="fv-plugins-message-container">
                                                                           <div className="fv-help-block">{helpers.errors.disponibiliteDateExacte}</div>
                                                                      </div>
                                                                 )}
                                                            </>
                                                       )}
                                                  </div>
                                             </div>
                                        )}

                                        {/* Mobilité*/}
                                        {infoCandidatQuery.data?.mobilite === null && (
                                             <div className="row mb-6">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Mobilité</label>

                                                  <div className="col-lg-8">
                                                       <MySelectField
                                                            options={CONST_MOBILITE_OPTIONS_FOR_SELECT2}
                                                            name={"mobilite"}
                                                            isSearchable={false}
                                                            value={helpers.values.mobilite}
                                                            onChange={value => helpers.setFieldValue("mobilite", value)}
                                                       />
                                                       {helpers.errors.mobilite && (
                                                            <div className="fv-plugins-message-container">
                                                                 <div className="fv-help-block">{helpers.errors.mobilite}</div>
                                                            </div>
                                                       )}

                                                       {helpers.values.mobilite === CONST_MOBILITE_REGIONAL && (
                                                            <>
                                                                 <br />
                                                                 <MySelectGetAllDataFromServer
                                                                      url={API_PUBLIC_GET_REGIONS_FRANCE_URL}
                                                                      name={"mobiliteRegions"}
                                                                      value={helpers.values.mobiliteRegions}
                                                                      placeholder={"Choisissez les régions"}
                                                                      isMulti={true}
                                                                      onChange={val => helpers.setFieldValue("mobiliteRegions", val)}
                                                                 />
                                                                 {helpers.errors.mobiliteRegions && (
                                                                      <div className="fv-plugins-message-container">
                                                                           <div className="fv-help-block">{helpers.errors.mobiliteRegions}</div>
                                                                      </div>
                                                                 )}
                                                            </>
                                                       )}

                                                       {helpers.values.mobilite === CONST_MOBILITE_DEPARTEMENTAL && (
                                                            <>
                                                                 <br />
                                                                 <MySelectGetAllDataFromServer
                                                                      url={API_PUBLIC_GET_DEPARTEMENTS_FRANCE_URL}
                                                                      name={"mobiliteDepartements"}
                                                                      placeholder={"Choisissez les départements"}
                                                                      value={helpers.values.mobiliteDepartements}
                                                                      isMulti={true}
                                                                      onChange={val => helpers.setFieldValue("mobiliteDepartements", val)}
                                                                 />
                                                                 {helpers.errors.mobiliteDepartements && (
                                                                      <div className="fv-plugins-message-container">
                                                                           <div className="fv-help-block">{helpers.errors.mobiliteDepartements}</div>
                                                                      </div>
                                                                 )}
                                                            </>
                                                       )}

                                                       {helpers.values.mobilite === CONST_MOBILITE_PAR_VILLE && (
                                                            <>
                                                                 <br />
                                                                 <MySelectSearchDataFromServer
                                                                      url={API_PUBLIC_GET_VILLES_FRANCE_URL}
                                                                      name={"mobiliteVilles"}
                                                                      value={helpers.values.mobiliteVilles}
                                                                      method={"GET"}
                                                                      placeholder={"Choisissez les villes"}
                                                                      isMulti={true}
                                                                      onChange={val => helpers.setFieldValue("mobiliteVilles", val)}
                                                                      minimumLengthSearch={1}
                                                                 />
                                                                 {helpers.errors.mobiliteVilles && (
                                                                      <div className="fv-plugins-message-container">
                                                                           <div className="fv-help-block">{helpers.errors.mobiliteVilles}</div>
                                                                      </div>
                                                                 )}
                                                            </>
                                                       )}
                                                  </div>
                                             </div>
                                        )}

                                        {/* Salaire */}
                                        {infoCandidatQuery.data?.salaireNetSouhaite === null && (
                                             <div className="row mb-6">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                       <span>
                                                            Salaire <b>NET MENSUEL</b> souhaité
                                                       </span>
                                                  </label>

                                                  <div className="col-lg-8">
                                                       <MyInputField
                                                            name={"salaireNetMensuelSouhaite"}
                                                            value={helpers.values.salaireNetMensuelSouhaite}
                                                            type={"number"}
                                                            inputMode={"numeric"}
                                                            isInvalid={false}
                                                            onChange={val => helpers.setFieldValue("salaireNetMensuelSouhaite", val)}
                                                            placeholder={"Salaire NET Mensuel souhaité"}
                                                       />
                                                       {helpers.errors.salaireNetMensuelSouhaite && (
                                                            <div className="fv-plugins-message-container">
                                                                 <div className="fv-help-block">{helpers.errors.salaireNetMensuelSouhaite}</div>
                                                            </div>
                                                       )}
                                                  </div>
                                             </div>
                                        )}

                                        {/* Habilitation */}
                                        {context.offreDetailsQuery.data?.businessUnitMetier?.businessUnit?.code === CONST_BUSINESS_UNIT_SECRET_DEFENSE_CODE &&
                                             infoCandidatQuery.data?.isHabilitableDefense === null && (
                                                  <>
                                                       <div className="row mb-6">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                                 <span>Êtes-vous habilitable Secret Défense?</span>
                                                            </label>

                                                            <div className="col-lg-8">
                                                                 <MyCheckBoxField
                                                                      name={"isHabilitableDefense"}
                                                                      value={helpers.values.isHabilitableDefense}
                                                                      isInvalid={false}
                                                                      onChange={val => helpers.setFieldValue("isHabilitableDefense", val)}
                                                                 />
                                                                 {helpers.errors.isHabilitableDefense && (
                                                                      <div className="fv-plugins-message-container">
                                                                           <div className="fv-help-block">{helpers.errors.isHabilitableDefense}</div>
                                                                      </div>
                                                                 )}
                                                            </div>
                                                       </div>

                                                       {helpers.values.isHabilitableDefense && (
                                                            <>
                                                                 <div className="row mb-6">
                                                                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                                           <span>Avez-vous été habilité Secret Défense?</span>
                                                                      </label>
                                                                      <div className="col-lg-8">
                                                                           <MyCheckBoxField
                                                                                name={"isDejaHabiliteDefense"}
                                                                                value={helpers.values.isDejaHabiliteDefense}
                                                                                isInvalid={false}
                                                                                onChange={val => helpers.setFieldValue("isDejaHabiliteDefense", val)}
                                                                           />
                                                                           {helpers.errors.isDejaHabiliteDefense && (
                                                                                <div className="fv-plugins-message-container">
                                                                                     <div className="fv-help-block">{helpers.errors.isDejaHabiliteDefense}</div>
                                                                                </div>
                                                                           )}
                                                                      </div>
                                                                 </div>

                                                                 {helpers.values.isDejaHabiliteDefense && (
                                                                      <div className="row mb-6">
                                                                           <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                                                <span>Saisissez la date de fin d'habilitation</span>
                                                                           </label>
                                                                           <div className="col-lg-8">
                                                                                <MyDateDayMonthYearField
                                                                                     onChange={date => helpers.setFieldValue("dateFinHabilitation", date)}
                                                                                     value={helpers.values.dateFinHabilitation}
                                                                                     name={"dateFinHabilitation"}
                                                                                     placeholder={"Saisissez la date de fin d'habilitation"}
                                                                                />
                                                                                {helpers.errors.dateFinHabilitation && (
                                                                                     <div className="fv-plugins-message-container">
                                                                                          <div className="fv-help-block">{helpers.errors.dateFinHabilitation}</div>
                                                                                     </div>
                                                                                )}
                                                                           </div>
                                                                      </div>
                                                                 )}
                                                            </>
                                                       )}
                                                  </>
                                             )}

                                        {/* Curriculum */}
                                        {!infoCandidatQuery.data?.curriculumExists && (
                                             <div className="row mb-6">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                       <span>Curriculum</span>
                                                  </label>

                                                  <div className="col-lg-8">
                                                       <input
                                                            type="file"
                                                            className="form-control form-control-lg"
                                                            placeholder="Curriculum"
                                                            onChange={e =>
                                                                 helpers.setFieldValue("curriculum", e.target.files && e.target.files.length > 0 ? e.target.files[0] : "")
                                                            }
                                                       />
                                                       {helpers.errors.curriculum && (
                                                            <div className="fv-plugins-message-container">
                                                                 <div className="fv-help-block">{helpers.errors.curriculum}</div>
                                                            </div>
                                                       )}
                                                  </div>
                                             </div>
                                        )}

                                        {context.referenceAnnonce.toUpperCase() === "REF_SPON" && (
                                             <>
                                                  <div className="separator separator-dashed" />

                                                  <div className="row mb-6">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Choisissez les secteurs qui vous intéresse</label>

                                                       <div className="col-lg-8">
                                                            <MySelectGetAllDataFromServer
                                                                 url={API_PUBLIC_GET_SECTEURS_ACTIVITE_URL}
                                                                 name={"businessUnitsCandidatureSpon"}
                                                                 placeholder={"Choisissez parmi les sécteurs"}
                                                                 value={helpers.values.businessUnitsCandidatureSpon}
                                                                 isMulti={true}
                                                                 onChange={val => helpers.setFieldValue("businessUnitsCandidatureSpon", val)}
                                                            />
                                                            {helpers.errors.businessUnitsCandidatureSpon && (
                                                                 <div className="fv-plugins-message-container">
                                                                      <div className="fv-help-block">{helpers.errors.businessUnitsCandidatureSpon}</div>
                                                                 </div>
                                                            )}
                                                       </div>
                                                  </div>

                                                  <div className="row mb-6">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                            <span>Voulez-vous ajouter un commentaire à votre candidature?</span>
                                                       </label>

                                                       <div className="col-lg-8">
                                                            <MyCheckBoxField
                                                                 name={"isCandidatureSponWithCommentaire"}
                                                                 value={helpers.values.isCandidatureSponWithCommentaire}
                                                                 isInvalid={false}
                                                                 onChange={val => helpers.setFieldValue("isCandidatureSponWithCommentaire", val)}
                                                            />
                                                            {helpers.errors.isCandidatureSponWithCommentaire && (
                                                                 <div className="fv-plugins-message-container">
                                                                      <div className="fv-help-block">{helpers.errors.isCandidatureSponWithCommentaire}</div>
                                                                 </div>
                                                            )}
                                                       </div>
                                                  </div>

                                                  {helpers.values.isCandidatureSponWithCommentaire && (
                                                       <div className="row mb-6">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                                                 <span>Rédigez votre commentaire</span>
                                                            </label>

                                                            <div className="col-lg-8">
                                                                 <MyTextareaField
                                                                      name={"commentaireCandidatureSpon"}
                                                                      value={helpers.values.commentaireCandidatureSpon}
                                                                      isInvalid={false}
                                                                      rows={10}
                                                                      onChange={val => helpers.setFieldValue("commentaireCandidatureSpon", val)}
                                                                 />
                                                                 {helpers.errors.commentaireCandidatureSpon && (
                                                                      <div className="fv-plugins-message-container">
                                                                           <div className="fv-help-block">{helpers.errors.commentaireCandidatureSpon}</div>
                                                                      </div>
                                                                 )}
                                                            </div>
                                                       </div>
                                                  )}
                                             </>
                                        )}

                                        {/* Submit button */}
                                        <div className={"d-flex justify-content-sm-between flex-column-reverse flex-sm-row"}>
                                             <a
                                                  href={"https://cts-corporate.com/offres"}
                                                  target={"_parent"}
                                                  className={"btn btn-sm btn-primary d-inline-flex align-items-center justify-content-center"}
                                             >
                                                  <span className={"fas fa-arrow-left me-1"} />
                                                  Retour aux offres
                                             </a>
                                             <button
                                                  className={"btn btn-sm btn-primary d-inline-flex align-items-center justify-content-center mb-sm-0 mb-4"}
                                                  type={"submit"}
                                                  disabled={helpers.isSubmitting}
                                             >
                                                  {!helpers.isSubmitting ? (
                                                       <span>
                                                            Envoyer ma candidature <span className={"fas fa-paper-plane ms-1"} />
                                                       </span>
                                                  ) : (
                                                       <MySimpleSpinner size={"sm"} />
                                                  )}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               )}
          </>
     )
}

interface ICandidatureStepTwoFormRequestModel {
     candidat?: number
     referenceAnnonce?: string
     civilite?: string
     paysResidence?: number
     telIndicatif?: number
     tel?: string
     niveauEtudes?: string
     disponibilite?: string
     disponibiliteDateExacte?: string
     mobilite?: string
     mobiliteRegions?: number[]
     mobiliteDepartements?: number[]
     mobiliteVilles?: number[]
     salaireNetMensuelSouhaite?: number
     isHabilitableDefense: boolean
     isDejaHabiliteDefense: boolean
     dateFinHabilitation?: string
     businessUnitsCandidatureSpon?: number[]
     isCandidatureSponWithCommentaire: boolean
     commentaireCandidatureSpon?: string
     jobboard?: string
     curriculum?: File
}

export default CandidatureWithoutAuthStepTwo
