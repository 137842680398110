import {Link} from "react-router-dom";
import React from "react";
import {useAuth} from "../../../../auth";
import {AUTH_ESPACE_AVENTURIER_TYPE_CANDIDAT, AUTH_ESPACE_AVENTURIER_TYPE_CONSULTANT, AUTH_ESPACE_AVENTURIER_TYPE_PREEMBAUCHE} from "../../../../../constants";
import {InfoPersoViewOnlyEspaceCandidat} from "./InfoPersoViewOnlyEspaceCandidat";
import {InfoPersoViewOnlyEspacePreemabauche} from "./infoPersoViewOnlyEspacePreembauche/InfoPersoViewOnlyEspacePreemabauche";
import {InfoPersoViewOnlyEspaceConsultant} from "./infoPersoViewOnlyEspaceConsultant/InfoPersoViewOnlyEspaceConsultant";

export function InfoPersoViewOnly() {
    const {currentUser} = useAuth()

    return <>
        {currentUser?.typeEspaceAppAventurier === AUTH_ESPACE_AVENTURIER_TYPE_CANDIDAT && <InfoPersoViewOnlyEspaceCandidat/>}
        {currentUser?.typeEspaceAppAventurier === AUTH_ESPACE_AVENTURIER_TYPE_PREEMBAUCHE && <InfoPersoViewOnlyEspacePreemabauche/>}
        {currentUser?.typeEspaceAppAventurier === AUTH_ESPACE_AVENTURIER_TYPE_CONSULTANT && <InfoPersoViewOnlyEspaceConsultant/>}
    </>
}

export function InfoPersoViewWrapper() {
    const {infoPersonellesNecessairesToEmbaucheToBeFilled} = useAuth()

    return <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header '>
            <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Mes informations personnelles</h3>
            </div>

            <Link to="/mon-compte/profil/edit"
                  className={`btn btn-sm d-none d-sm-block ${infoPersonellesNecessairesToEmbaucheToBeFilled.length == 0 ? "btn-primary" : "btn-warning"} align-self-center`}>
                {infoPersonellesNecessairesToEmbaucheToBeFilled.length == 0 ? "Passer en mode édition" : <span className={"text-black"}>Compléter mes informations</span>}
            </Link>

            <Link to="/mon-compte/profil/edit" className={`d-sm-none d-block text-warning align-self-center`}>
                {infoPersonellesNecessairesToEmbaucheToBeFilled.length == 0 ? <i className={"fas fa-edit text-primary"} style={{fontSize: '1.5rem'}}/> : <i className={"fas fa-edit text-warning"} style={{fontSize: '1.5rem'}}/>}
            </Link>


        </div>

        <div className='card-body p-9 w-lg-60'>
            <InfoPersoViewOnly/>
        </div>
    </div>
}



