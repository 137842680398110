import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react"
import { addOffreAmongFavoris, getOffreDetails, getOffresFavoris, postuler, removeOffreFromFavoris, retirerCandidature } from "../core/_requests"
import { InfoToFillBeforeApplyModel, InfoToFillBeforeApplyShemaModel } from "../core/_models"
import { AxiosError } from "axios"
import { useIntl } from "react-intl"
import { Link, useLocation, useParams } from "react-router-dom"
import { useAuth, UserModel } from "../../auth"
import {
     API_GET_DEPARTEMENTS_FRANCE_URL,
     API_GET_PAYS_INDICATIFS_TEL_URL,
     API_GET_PAYS_URL,
     API_GET_REGIONS_FRANCE_URL,
     API_GET_VILLES_FRANCE_URL,
     CONST_BUSINESS_UNIT_SECRET_DEFENSE_CODE,
     CONST_CANDIDAT_DOCUMENT_TYPE_CV,
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CIVILITES_OPTIONS_FOR_SELECT2,
     CONST_DEFAULT_TOAST_OPTIONS,
     CONST_DISPONIBILITE_DATE_EXACTE,
     CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2,
     CONST_MOBILITE_DEPARTEMENTAL,
     CONST_MOBILITE_OPTIONS_FOR_SELECT2,
     CONST_MOBILITE_PAR_VILLE,
     CONST_MOBILITE_REGIONAL,
     CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2,
     CONST_OUI_NON_FOR_SELECT2,
} from "../../../constants"

import * as Yup from "yup"
import { useFormik } from "formik"
import { isNumeric } from "../../../helpers/helper-global"
import MySelectField from "../../../utils/fields/MySelectField"
import MySelectGetAllDataFromServer from "../../../utils/fields/MySelectGetAllDataFromServer"
import MyInptuMaskField from "../../../utils/fields/MyInputMaskField"
import MySelectSearchDataFromServer from "../../../utils/fields/MySelectSearchDataFromServer"
import MyDateDayMonthYearField from "../../../utils/fields/MyDateDayMonthYearField"
import { toast } from "react-toastify"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { getCandidatures } from "../../../app-candidat/pages/candidatures/core/_requests"
import { CandidatureModel, OffreModel } from "../../../models"

const ONGLET_DESCRIPTION = "ONGLET_DESCRIPTION"
const ONGLET_PROFIL = "ONGLET_PROFIL"
const ONGLET_LOCALISATION = "ONGLET_LOCALISATION"
const ONGLET_SECTEUR = "ONGLET_SECTEUR"

type DetailsOffreContextType = {
     ongletActif: string
     setOngletActif: Dispatch<SetStateAction<string>>
     loading: boolean
     setLoading: Dispatch<SetStateAction<boolean>>
     error: string | undefined
     loadData: () => void
     handleRemoveOffreFromFavoris: (id: number) => void
     handleAddOffreAmongFavoris: (id: number) => void
     handlRetirerCandidature: (id: number) => void
     handleAppyToOffreWihoutMissingInfo: (id: number) => void
     details: OffreModel | undefined
     checkIfUserCanApply: () => boolean
     setUserMustFillSomeInfoBeforeApply: Dispatch<SetStateAction<boolean | undefined>>
     dejaPostule: boolean
     candidatures: CandidatureModel[]
     setCandidatures: Dispatch<CandidatureModel[]>
     offresFavoris: OffreModel[]
     locationState: { prevPage: "pageCandidatures" | "pageOffres" | null | undefined }
}
const DetailsOffreContext = createContext<DetailsOffreContextType | null>(null)

export function DetailsOffre() {
     const locationState = useLocation().state as { prevPage: "pageCandidatures" | "pageOffres" | null | undefined }
     const MySwal = withReactContent(Swal)
     const { currentUser } = useAuth()
     let { id } = useParams()

     const [error, setError] = useState<string>()
     const intl = useIntl()
     const params = new URLSearchParams(window.location.search)
     const titre = params.get("titre") // Intitule est présent uniquement dans détails
     const [ongletActif, setOngletActif] = useState<string>(ONGLET_DESCRIPTION)
     const [loading, setLoading] = useState<boolean>(true)
     const [detailsOffre, setDetailsOffre] = useState<OffreModel>()
     const [candidatures, setCandidatures] = useState<CandidatureModel[]>([])
     const [offresFavoris, setOffresFavoris] = useState<OffreModel[]>([])
     const [userMustFillSomeInfoBeforeApply, setUserMustFillSomeInfoBeforeApply] = useState<boolean>()
     const dejaPostule: boolean = useMemo(() => {
          if (candidatures.length === 0) return false

          return candidatures.filter(item => item.annonce.id.toString() === id).length > 0
     }, [candidatures])

     useEffect(() => {
          loadData()
     }, [])

     function loadData() {
          if (id) {
               setError(undefined)
               const offer_id = parseInt(id)
               getCandidatures()
                    .then(r => {
                         setCandidatures(r.data)
                         getOffresFavoris()
                              .then(r => {
                                   setOffresFavoris(r.data)
                                   getOffreDetails(offer_id)
                                        .then(r => {
                                             setDetailsOffre(r.data)
                                        })
                                        .catch((e: AxiosError) => {
                                             setError(e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                                        })
                                        .finally(() => {
                                             setLoading(false)
                                        })
                              })
                              .catch((e: AxiosError) => {
                                   setError(e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                              })
                    })
                    .catch((e: AxiosError) => {
                         setError(e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                    })
          } else {
               setError("Aucune référence d'annonce")
          }
     }

     function checkIfUserCanApply(): boolean {
          if (
               currentUser?.civilite === null ||
               currentUser?.tel === null ||
               currentUser?.pays?.id === null ||
               currentUser?.niveauEtudes === null ||
               currentUser?.disponibilite === null ||
               currentUser?.mobilite === null ||
               currentUser?.salaireNetSouhaite === null ||
               !currentUser?.undeletedCandidatDocuments?.some(item => item.type == CONST_CANDIDAT_DOCUMENT_TYPE_CV)
          ) {
               setUserMustFillSomeInfoBeforeApply(true)
               return false
          }

          if (detailsOffre?.businessUnitMetier?.businessUnit?.code === CONST_BUSINESS_UNIT_SECRET_DEFENSE_CODE) {
               if (currentUser?.isHabilitableDefense === undefined) setUserMustFillSomeInfoBeforeApply(true)
               return false
          }

          return true
     }

     function handleAddOffreAmongFavoris(id: number) {
          toast.info("Ajout en cours ...", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: false })
          addOffreAmongFavoris(id)
               .then(r => {
                    setOffresFavoris(r.data)
                    toast.dismiss()
                    toast.success("L'offre a bien été ajoutée parmi vos favoris.", CONST_DEFAULT_TOAST_OPTIONS)
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
               })
     }

     function handleRemoveOffreFromFavoris(id: number) {
          toast.info("Suppression en cours ...", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: false })

          removeOffreFromFavoris(id)
               .then(r => {
                    setOffresFavoris(r.data)
                    toast.dismiss()
                    toast.success("L'offre ne fait plus partie de vos favoris.", CONST_DEFAULT_TOAST_OPTIONS)
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
               })
     }

     function handlRetirerCandidature(offre_id: number) {
          MySwal.fire({
               icon: "warning",
               text: `Êtes-vous sûr de vouloir retirer votre candidature?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: () => {
                    return retirerCandidature(offre_id)
                         .then(r => {
                              setCandidatures(r.data)
                              toast.success("Votre candidature à cette offre a bien été retirée. Nous ne la prendrons plus en compte.", CONST_DEFAULT_TOAST_OPTIONS)
                         })
                         .catch((e: AxiosError) => {
                              toast.error(e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function handleAppyToOffreWihoutMissingInfo(offre_id: number) {
          MySwal.fire({
               icon: "warning",
               text: `Êtes-vous sûr de vouloir postuler à cette offre?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: () => {
                    return postuler(offre_id)
                         .then(r => {
                              setCandidatures(r.data.candidatures)
                              toast.success("Votre candidature a bien été prise en compte.", CONST_DEFAULT_TOAST_OPTIONS)
                         })
                         .catch((e: AxiosError) => {
                              toast.error(e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     return (
          <>
               <div className="card">
                    <div className="card-header">
                         <div className="card-title">
                              <h3 className="fw-bolder m-0">{titre}</h3>
                         </div>
                         <Link
                              className={"btn btn-primary btn-sm align-self-center d-none d-lg-inline"}
                              to={locationState?.prevPage == "pageCandidatures" ? "/mes-candidatures" : "/offres"}
                         >
                              Retour aux {locationState?.prevPage == "pageCandidatures" ? "candidatures" : "offres"}
                         </Link>
                    </div>

                    <div className="card-body pt-0 pe-0 ps-0">
                         <DetailsOffreContext.Provider
                              value={{
                                   ongletActif,
                                   setOngletActif,
                                   loadData,
                                   loading,
                                   setLoading,
                                   details: detailsOffre,
                                   error,
                                   checkIfUserCanApply,
                                   setUserMustFillSomeInfoBeforeApply,
                                   dejaPostule,
                                   handleRemoveOffreFromFavoris,
                                   handleAddOffreAmongFavoris,
                                   handlRetirerCandidature,
                                   handleAppyToOffreWihoutMissingInfo,
                                   offresFavoris,
                                   candidatures,
                                   setCandidatures,
                                   locationState,
                              }}
                         >
                              {userMustFillSomeInfoBeforeApply && (
                                   <>
                                        <FormMissingFieldsToFillBeforeApply details={detailsOffre} setUserMustFillSomeInfoBeforeApply={setUserMustFillSomeInfoBeforeApply} />
                                   </>
                              )}

                              {userMustFillSomeInfoBeforeApply === undefined && (
                                   <>
                                        <Onglets />
                                        <OngletContent />
                                   </>
                              )}
                         </DetailsOffreContext.Provider>
                    </div>
               </div>
          </>
     )
}

// Onglets Description, profil, localisation & secteur
function Onglets() {
     return (
          <DetailsOffreContext.Consumer>
               {value => (
                    <div className="card">
                         <div className="card-body">
                              <div className="d-flex overflow-auto justify-content-sm-start justify-content-center">
                                   <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                                        <li className="nav-item">
                                             <span
                                                  className={`nav-link text-active-primary me-6 cursor-pointer ${value?.ongletActif === ONGLET_DESCRIPTION && "active"}`}
                                                  onClick={() => value?.setOngletActif(ONGLET_DESCRIPTION)}
                                             >
                                                  <span className={"d-none d-sm-block"}>Description</span>
                                                  <i className={"d-block d-sm-none fas fa-file-alt"} style={{ fontSize: "2rem" }} />
                                             </span>
                                        </li>
                                        <li className="nav-item">
                                             <span
                                                  className={`nav-link text-active-primary me-6 cursor-pointer ${value?.ongletActif === ONGLET_PROFIL && "active"}`}
                                                  onClick={() => value?.setOngletActif(ONGLET_PROFIL)}
                                             >
                                                  <span className={"d-none d-sm-block"}>Profil recherché</span>
                                                  <i className={"d-block d-sm-none fas fa-user-tie"} style={{ fontSize: "2rem" }} />
                                             </span>
                                        </li>
                                        <li className="nav-item">
                                             <span
                                                  className={`nav-link text-active-primary me-6 cursor-pointer ${value?.ongletActif === ONGLET_LOCALISATION && "active"}`}
                                                  onClick={() => value?.setOngletActif(ONGLET_LOCALISATION)}
                                             >
                                                  <span className={"d-none d-sm-block"}>Localisation</span>
                                                  <i className={"d-block d-sm-none fas fa-map-pin"} style={{ fontSize: "2rem" }} />
                                             </span>
                                        </li>
                                        <li className="nav-item">
                                             <span
                                                  className={`nav-link text-active-primary me-6 cursor-pointer ${value?.ongletActif === ONGLET_SECTEUR && "active"}`}
                                                  onClick={() => value?.setOngletActif(ONGLET_SECTEUR)}
                                             >
                                                  <span className={"d-none d-sm-block"}>Secteur</span>
                                                  <i className={"d-block d-sm-none fas fa-tag"} style={{ fontSize: "2rem" }} />
                                             </span>
                                        </li>
                                   </ul>
                              </div>
                         </div>
                    </div>
               )}
          </DetailsOffreContext.Consumer>
     )
}

// Content en fonction des Onglets
function OngletContent() {
     return (
          <DetailsOffreContext.Consumer>
               {value => (
                    <div className="mx-8 mb-5">
                         <div className="separator mb-5"></div>

                         {value?.loading && (
                              <div className={"text-center p-4"}>
                                   <span className="spinner-border mb-2"></span>
                                   <p style={{ fontWeight: 500 }}>Chargement des informations en cours ...</p>
                              </div>
                         )}

                         {!value?.loading && value?.error && (
                              <div className="alert alert-danger">
                                   <div className="alert-text font-weight-bold">
                                        {value?.error}.{" "}
                                        <a
                                             href="#"
                                             onClick={e => {
                                                  e.preventDefault()
                                                  value?.setLoading(true)
                                                  setTimeout(() => {
                                                       value?.loadData()
                                                  }, 2000)
                                             }}
                                        >
                                             Recharger les informations.
                                        </a>
                                   </div>
                              </div>
                         )}

                         {!value?.loading && !value?.error && (
                              <>
                                   <div style={{ maxHeight: "35vh", overflow: "scroll", textAlign: "justify" }}>
                                        {value?.ongletActif === ONGLET_DESCRIPTION && (
                                             <div>
                                                  <h3 className={"d-sm-none d-block mb-4"}>Description</h3>
                                                  {value?.details?.talentPlugOfferDTO?.job?.job_description}
                                             </div>
                                        )}
                                        {value?.ongletActif === ONGLET_PROFIL && (
                                             <div>
                                                  <h3 className={"d-sm-none d-block mb-4"}>Profil recherché</h3>
                                                  {value?.details?.talentPlugOfferDTO?.profil?.applicant_profile}
                                             </div>
                                        )}
                                        {value?.ongletActif === ONGLET_LOCALISATION && (
                                             <div>
                                                  <h3 className={"d-sm-none d-block mb-4"}>Localisation</h3>
                                                  {value?.details?.ville?.nom}
                                             </div>
                                        )}
                                        {value?.ongletActif === ONGLET_SECTEUR && (
                                             <div>
                                                  <h3 className={"d-sm-none d-block mb-4"}>Secteur</h3>
                                                  {value?.details?.businessUnitMetier?.businessUnit?.nom}
                                             </div>
                                        )}
                                   </div>

                                   <div className="mt-5 d-flex justify-content-center">
                                        <Link
                                             className={"btn btn-primary btn-sm d-lg-none me-lg-0 me-5 align-items-center d-flex"}
                                             to={value?.locationState?.prevPage == "pageCandidatures" ? "/mes-candidatures" : "/offres"}
                                        >
                                             <i className={"fas fa-arrow-left p-0"} />
                                        </Link>

                                        {value?.dejaPostule === false ? (
                                             <button
                                                  className="btn btn-light-primary btn-sm me-5"
                                                  onClick={() => {
                                                       if (value?.checkIfUserCanApply()) {
                                                            value?.handleAppyToOffreWihoutMissingInfo(value!.details!.id)
                                                       }
                                                  }}
                                             >
                                                  Postuler
                                             </button>
                                        ) : (
                                             <button
                                                  className="btn btn-primary btn-sm me-5"
                                                  onClick={() => {
                                                       value?.handlRetirerCandidature(value!.details!.id)
                                                  }}
                                             >
                                                  Retirer ma candidature
                                             </button>
                                        )}
                                        {value?.offresFavoris.find(item => item.id == value!.details!.id) ? (
                                             <button className="btn btn-danger btn-sm" onClick={() => value?.handleRemoveOffreFromFavoris(value!.details!.id)}>
                                                  Retirer de mes favoris
                                             </button>
                                        ) : (
                                             <button className="btn btn-light-danger btn-sm" onClick={() => value?.handleAddOffreAmongFavoris(value!.details!.id)}>
                                                  Ajouter à mes favoris
                                             </button>
                                        )}
                                   </div>
                              </>
                         )}
                    </div>
               )}
          </DetailsOffreContext.Consumer>
     )
}

// Formulaire des missing fields à remplir pour pouvoir postuler
type PropsFormMissingFieldsToFillBeforeApply = {
     details: OffreModel | undefined
     setUserMustFillSomeInfoBeforeApply: Dispatch<SetStateAction<boolean | undefined>>
}
const FormMissingFieldsToFillBeforeApply: React.FC<PropsFormMissingFieldsToFillBeforeApply> = ({ details, setUserMustFillSomeInfoBeforeApply }) => {
     const { currentUser, setCurrentUser } = useAuth()
     const detailsOffreContext = useContext(DetailsOffreContext)
     const intl = useIntl()
     let shape = {}
     const [submitting, setSubmitting] = useState<boolean>(false)

     if (currentUser?.civilite === null) shape["civilite"] = Yup.string().required("La civilité est requise")
     if (currentUser?.tel === null || currentUser?.telIndicatif === null) {
          shape["telIndicatifId"] = Yup.number().label("Indicatif téléphonique").required("L'indicatif est requis")
          shape["tel"] = Yup.string().required("Le tél. est requis")
     }
     if (currentUser?.pays === null) shape["paysResidenceId"] = Yup.number().required("Le pays de résidence est requis")
     if (currentUser?.niveauEtudes === null) shape["niveauEtudes"] = Yup.string().required("Le niveau d'études est requis")
     if (currentUser?.salaireNetSouhaite === null) shape["salaireNetMensuelSouhaite"] = Yup.number().typeError("Un nombre est requis").required("Le salaire est requis")
     if (currentUser?.disponibilite === null) {
          shape["disponibilite"] = Yup.string().required("La disponibilité est requise")
          shape["disponibiliteDateExacte"] = Yup.string()
               .when("disponibilite", {
                    is: CONST_DISPONIBILITE_DATE_EXACTE,
                    then: schema => schema.required("La date de disponibilité est requise").typeError("La date de disponibilité est requise"),
               })
               .nullable()
     }
     if (currentUser?.mobilite === null) {
          shape["mobilite"] = Yup.string().required("La mobilité est requise")
          shape["mobiliteRegions"] = Yup.array()
               .when("mobilite", {
                    is: CONST_MOBILITE_REGIONAL,
                    then: schema => schema.required("Les regions sont requises").typeError("Les regions sont requises"),
               })
               .nullable()
          shape["mobiliteDepartements"] = Yup.array()
               .when("mobilite", {
                    is: CONST_MOBILITE_DEPARTEMENTAL,
                    then: schema => schema.required("Les départements sont requise").typeError("Les regions sont requise"),
               })
               .nullable()
          shape["mobiliteVilles"] = Yup.array()
               .when("mobilite", {
                    is: CONST_MOBILITE_PAR_VILLE,
                    then: schema => schema.required("Les villes sont requises").typeError("Les villes sont requises"),
               })
               .nullable()
     }

     if (details?.businessUnitMetier?.businessUnit?.code === CONST_BUSINESS_UNIT_SECRET_DEFENSE_CODE && currentUser?.isHabilitableDefense === null) {
          shape["habilitableDefense"] = Yup.boolean().required("Choisissez si vous êtes habilitable ou pas")
          shape["dejaHabiliteDefense"] = Yup.boolean()
               .when("habilitableDefense", {
                    is: true,
                    then: schema => schema.required("Choisissez si vous avez déjà été habilité").typeError("Choisissez si vous avez déjà été habilité"),
               })
               .nullable()
          shape["dateFinHabilitation"] = Yup.string()
               .when("dejaHabiliteDefense", {
                    is: true,
                    then: schema => schema.required("Saisissez la date fin d'habilitation").typeError("Saisissez la date fin d'habilitation"),
               })
               .nullable()
     }

     if (!currentUser?.undeletedCandidatDocuments?.some(item => item.type == CONST_CANDIDAT_DOCUMENT_TYPE_CV)) {
          shape["curriculum"] = Yup.mixed()
               .required("Le CV est requis")
               .test("fileSize", "Le CV doit avoir une taille inférieure ou égale à 10MB", (value: any) => {
                    return value && value.size <= 10000000
               })
               .test("fileFormat", "Le CV doit être au format PDF ou de type image", (value: any) => {
                    return value && ["application/pdf", "media/*"].includes(value.type)
               })
     }

     const schema = Yup.object().shape(shape, [["tel", "telIndicatifId"]])

     // Formik
     const formik = useFormik<InfoToFillBeforeApplyShemaModel>({
          initialValues: {},
          validationSchema: schema,
          onSubmit: async (values, { setStatus }) => {
               setSubmitting(true)
               setStatus(null)

               const candidat_info: InfoToFillBeforeApplyModel = {}
               if (values.civilite) candidat_info.civilite = values.civilite
               if (values.paysResidenceId) candidat_info.paysResidenceId = values.paysResidenceId
               if (values.telIndicatifId) candidat_info.telIndicatifId = values.telIndicatifId
               if (values.tel) candidat_info.tel = values.tel
               if (values.niveauEtudes) candidat_info.niveauEtudes = values.niveauEtudes
               if (values.disponibilite) candidat_info.disponibilite = values.disponibilite
               if (values.disponibilite === CONST_DISPONIBILITE_DATE_EXACTE && values.disponibiliteDateExacte)
                    candidat_info.disponibiliteDateExacte = values.disponibiliteDateExacte
               if (values.mobilite) candidat_info.mobilite = values.mobilite
               if (values.mobilite === CONST_MOBILITE_REGIONAL && values.mobiliteRegions) candidat_info.mobiliteRegions = values.mobiliteRegions
               if (values.mobilite === CONST_MOBILITE_DEPARTEMENTAL && values.mobiliteDepartements) candidat_info.mobiliteDepartements = values.mobiliteDepartements
               if (values.mobilite === CONST_MOBILITE_PAR_VILLE && values.mobiliteVilles) candidat_info.mobiliteVilles = values.mobiliteVilles
               if (values.salaireNetMensuelSouhaite) candidat_info.salaireNetMensuelSouhaite = values.salaireNetMensuelSouhaite
               if (values.habilitableDefense) candidat_info.habilitableDefense = values.habilitableDefense
               if (values.habilitableDefense && values.dejaHabiliteDefense) candidat_info.dejaHabiliteDefense = values.dejaHabiliteDefense
               if (values.habilitableDefense && values.dejaHabiliteDefense && values.dateFinHabilitation) candidat_info.dateFinHabilitation = values.dateFinHabilitation

               postuler(details!.id, candidat_info, values.curriculum ? values.curriculum : null)
                    .then(r => {
                         setSubmitting(false)
                         toast.success("Votre candidature a bien été envoyée. Vous receverez un mail de confirmation dans quelques instants.", CONST_DEFAULT_TOAST_OPTIONS)
                         detailsOffreContext?.setCandidatures(r.data.candidatures)
                         setCurrentUser(r.data.user as UserModel)
                         setUserMustFillSomeInfoBeforeApply(undefined)
                    })
                    .catch((e: AxiosError) => {
                         setStatus(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                         setSubmitting(false)
                    })
          },
     })

     return (
          <form onSubmit={formik.handleSubmit} noValidate className="form p-9 w-lg-60">
               <div className="alert alert-warning">
                    <div className="alert-text font-weight-bold text-dark">
                         Afin de traiter votre dossier de candidature, nous aurions besoin de quelques informations supplémentaires. Veuillez remplir les champs ci-dessous avant de
                         postuler à cette annonce.
                    </div>
               </div>

               {formik.status && (
                    <div className="alert alert-danger">
                         <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
               )}

               {currentUser?.civilite === null && (
                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">Civilité</label>

                         <div className="col-lg-8 fv-row">
                              <MySelectField
                                   options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(item => item.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                   name={"civilite"}
                                   isSearchable={false}
                                   value={formik.values?.civilite}
                                   onChange={value => formik.setFieldValue("civilite", value)}
                                   isClearable={true}
                              />
                              {formik.errors.civilite && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.civilite}</div>
                                   </div>
                              )}
                         </div>
                    </div>
               )}

               {currentUser?.tel === null && currentUser?.telIndicatif === null && (
                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">Téléphone</label>

                         <div className="col-lg-8">
                              <div className="row">
                                   <div className="col-xl-6 mb-xl-0 mb-3 fv-row">
                                        <MySelectGetAllDataFromServer
                                             url={API_GET_PAYS_INDICATIFS_TEL_URL}
                                             name={"telIndicatifId"}
                                             placeholder={"Choisissez un indicatif"}
                                             value={formik.values.telIndicatifId}
                                             onChange={val => formik.setFieldValue("telIndicatifId", val)}
                                        />
                                        {formik.errors.telIndicatifId && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.telIndicatifId}</div>
                                             </div>
                                        )}
                                   </div>

                                   <div className="col-xl-6 fv-row">
                                        <MyInptuMaskField
                                             name={"tel"}
                                             mask={"99 999999999999999999"}
                                             value={formik.values.tel}
                                             placeholder={"Saisissez votre numéro"}
                                             inputMode={"numeric"}
                                             onChange={e => formik.setFieldValue("tel", e.target.value)}
                                             isInvalid={!!formik.errors.tel}
                                        />
                                        {formik.errors.tel && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.tel}</div>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>
               )}

               {currentUser?.pays === null && (
                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                              <span>Pays de résidence</span>
                         </label>

                         <div className="col-lg-8 fv-row">
                              <MySelectGetAllDataFromServer
                                   url={API_GET_PAYS_URL}
                                   name={"pays"}
                                   value={formik.values.paysResidenceId}
                                   onChange={val => formik.setFieldValue("paysResidenceId", val)}
                              />
                              {formik.errors.paysResidenceId && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.paysResidenceId}</div>
                                   </div>
                              )}
                         </div>
                    </div>
               )}

               {currentUser?.niveauEtudes === null && (
                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">Niveau d'études</label>

                         <div className="col-lg-8 fv-row">
                              <MySelectField
                                   options={CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2}
                                   name={"niveauEtudes"}
                                   isSearchable={false}
                                   value={formik.values.niveauEtudes}
                                   onChange={value => formik.setFieldValue("niveauEtudes", value)}
                              />
                              {formik.errors.niveauEtudes && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.niveauEtudes}</div>
                                   </div>
                              )}
                         </div>
                    </div>
               )}

               {currentUser?.disponibilite === null && (
                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">Disponibilité</label>

                         <div className="col-lg-8 fv-row">
                              <MySelectField
                                   options={CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2}
                                   name={"disponibilite"}
                                   isSearchable={false}
                                   value={formik.values.disponibilite}
                                   onChange={value => formik.setFieldValue("disponibilite", value)}
                              />
                              {formik.errors.disponibilite && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.disponibilite}</div>
                                   </div>
                              )}
                              {formik.values.disponibilite === CONST_DISPONIBILITE_DATE_EXACTE && (
                                   <>
                                        <br />
                                        <MyDateDayMonthYearField
                                             onChange={date => formik.setFieldValue("disponibiliteDateExacte", date)}
                                             value={formik.values.disponibiliteDateExacte}
                                             name={"disponibiliteDateExacte"}
                                             placeholder={"Date de disponibilité"}
                                        />
                                        {formik.errors.disponibiliteDateExacte && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.disponibiliteDateExacte}</div>
                                             </div>
                                        )}
                                   </>
                              )}
                         </div>
                    </div>
               )}

               {currentUser?.mobilite === null && (
                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">Mobilité</label>

                         <div className="col-lg-8 fv-row">
                              <MySelectField
                                   options={CONST_MOBILITE_OPTIONS_FOR_SELECT2}
                                   name={"mobilite"}
                                   isSearchable={false}
                                   value={formik.values.mobilite}
                                   onChange={value => formik.setFieldValue("mobilite", value)}
                              />
                              {formik.errors.mobilite && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.mobilite}</div>
                                   </div>
                              )}

                              {formik.values.mobilite === CONST_MOBILITE_REGIONAL && (
                                   <>
                                        <br />
                                        <MySelectGetAllDataFromServer
                                             url={API_GET_REGIONS_FRANCE_URL}
                                             name={"mobiliteRegions"}
                                             value={formik.values.mobiliteRegions}
                                             placeholder={"Choisissez les régions"}
                                             isMulti={true}
                                             onChange={val => formik.setFieldValue("mobiliteRegions", val)}
                                        />
                                        {formik.errors.mobiliteRegions && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.mobiliteRegions}</div>
                                             </div>
                                        )}
                                   </>
                              )}

                              {formik.values.mobilite === CONST_MOBILITE_DEPARTEMENTAL && (
                                   <>
                                        <br />
                                        <MySelectGetAllDataFromServer
                                             url={API_GET_DEPARTEMENTS_FRANCE_URL}
                                             name={"mobiliteDepartements"}
                                             placeholder={"Choisissez les départements"}
                                             value={formik.values.mobiliteDepartements}
                                             isMulti={true}
                                             onChange={val => formik.setFieldValue("mobiliteDepartements", val)}
                                        />
                                        {formik.errors.mobiliteDepartements && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.mobiliteDepartements}</div>
                                             </div>
                                        )}
                                   </>
                              )}

                              {formik.values.mobilite === CONST_MOBILITE_PAR_VILLE && (
                                   <>
                                        <br />
                                        <MySelectSearchDataFromServer
                                             url={API_GET_VILLES_FRANCE_URL}
                                             name={"mobiliteVilles"}
                                             value={formik.values.mobiliteVilles}
                                             placeholder={"Choisissez les villes"}
                                             isMulti={true}
                                             method={"GET"}
                                             onChange={val => formik.setFieldValue("mobiliteVilles", val)}
                                             minimumLengthSearch={1}
                                        />
                                        {formik.errors.mobiliteVilles && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.mobiliteVilles}</div>
                                             </div>
                                        )}
                                   </>
                              )}
                         </div>
                    </div>
               )}

               {currentUser?.salaireNetSouhaite === null && (
                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                              <span>
                                   Salaire <b>NET MENSUEL</b> souhaité
                              </span>
                         </label>

                         <div className="col-lg-8 fv-row">
                              <input
                                   type="text"
                                   className="form-control form-control-lg "
                                   placeholder="Salaire net mensuel souhaité"
                                   inputMode={"numeric"}
                                   {...formik.getFieldProps("salaireNetMensuelSouhaite")}
                                   onChange={e => {
                                        formik.setFieldValue("salaireNetMensuelSouhaite", !isNumeric(e.target.value) ? "" : parseInt(e.target.value))
                                   }}
                              />
                              {formik.errors.salaireNetMensuelSouhaite && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.salaireNetMensuelSouhaite}</div>
                                   </div>
                              )}
                         </div>
                    </div>
               )}

               {details?.businessUnitMetier?.businessUnit?.code === CONST_BUSINESS_UNIT_SECRET_DEFENSE_CODE && currentUser?.isHabilitableDefense === null && (
                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                              <span>Habilitation Secret Défense</span>
                         </label>

                         <div className="col-lg-8 fv-row">
                              <MySelectField
                                   options={CONST_OUI_NON_FOR_SELECT2}
                                   name={"habilitableDefense"}
                                   value={formik.values.habilitableDefense}
                                   isSearchable={false}
                                   placeholder={"Habilitation Secret Défense"}
                                   onChange={val => formik.setFieldValue("habilitableDefense", val)}
                              />
                              {formik.errors.habilitableDefense && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.habilitableDefense}</div>
                                   </div>
                              )}

                              {formik.values.habilitableDefense && (
                                   <>
                                        <br />
                                        <MySelectField
                                             options={CONST_OUI_NON_FOR_SELECT2}
                                             name={"dejaHabiliteDefense"}
                                             isSearchable={false}
                                             value={formik.values.dejaHabiliteDefense}
                                             onChange={val => formik.setFieldValue("dejaHabiliteDefense", val)}
                                             placeholder={"Avez-vous été habilité Secret Défense?"}
                                        />
                                        {formik.errors.dejaHabiliteDefense && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.dejaHabiliteDefense}</div>
                                             </div>
                                        )}

                                        {formik.values.dejaHabiliteDefense && (
                                             <>
                                                  <br />
                                                  <MyDateDayMonthYearField
                                                       onChange={date => formik.setFieldValue("dateFinHabilitation", date)}
                                                       value={formik.values.dateFinHabilitation}
                                                       name={"dateFinHabilitation"}
                                                       placeholder={"Saisissez la date de fin d'habilitation"}
                                                  />
                                                  {formik.errors.dateFinHabilitation && (
                                                       <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.dateFinHabilitation}</div>
                                                       </div>
                                                  )}
                                             </>
                                        )}
                                   </>
                              )}
                         </div>
                    </div>
               )}

               {!currentUser?.undeletedCandidatDocuments?.some(item => item.type == CONST_CANDIDAT_DOCUMENT_TYPE_CV) && (
                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                              <span>Curriculum</span>
                         </label>

                         <div className="col-lg-8 fv-row">
                              <input
                                   type="file"
                                   className="form-control form-control-lg"
                                   placeholder="Curriculum"
                                   onChange={e => formik.setFieldValue("curriculum", e.target.files && e.target.files.length > 0 ? e.target.files[0] : "")}
                              />
                              {formik.errors.curriculum && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.curriculum}</div>
                                   </div>
                              )}
                         </div>
                    </div>
               )}

               <div className="separator mb-5"></div>
               <div className="d-flex justify-content-end">
                    <button
                         type={"button"}
                         className={"btn btn-sm btn-active-light-primary me-2"}
                         disabled={submitting}
                         onClick={() => detailsOffreContext!.setUserMustFillSomeInfoBeforeApply(undefined)}
                    >
                         Annuler
                    </button>
                    <button type="submit" className="btn btn-sm btn-primary" disabled={submitting}>
                         {!submitting && "Appliquer les modifications"}
                         {submitting && (
                              <span className="indicator-progress" style={{ display: "block" }}>
                                   Veuillez patienter <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                         )}
                    </button>
               </div>
          </form>
     )
}
