import {useAuth} from "../../../../auth";
import {CONST_CANDIDAT_DISPONIBILITE_DATE_EXACTE, CONST_CANDIDAT_MOBILITE_DEPARTEMENTAL, CONST_CANDIDAT_MOBILITE_PAR_VILLE, CONST_CANDIDAT_MOBILITE_REGIONAL} from "../../../../../constants";
import {FirstLetterUppercase} from "../../../../../helpers/helper-global";

export function InfoPersoViewOnlyEspaceCandidat() {
    const {currentUser} = useAuth()

    return <>
        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Civilité</label>

            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{currentUser?.civiliteLabel}</span>
            </div>
        </div>


        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Prénom NOM</label>

            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{currentUser?.prenom} {currentUser?.nom}</span>
            </div>
        </div>

        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Email</label>

            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark me-2'>{currentUser?.email}</span>
                <span className='badge badge-success'>Vérifié</span>
            </div>
        </div>

        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Téléphone</label>

            <div className='col-lg-8'>
                <span
                    className='fw-bolder fs-6 text-dark'>{currentUser?.telIndicatif?.phoneCodeWithPlusPrefix} {currentUser?.tel}</span>
            </div>
        </div>

        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Date de naissance</label>

            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{currentUser?.dateNaissance?.format('DD/MM/YYYY')}</span>
            </div>
        </div>

        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Pays de résidence</label>

            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{currentUser?.pays?.nomFr}</span>
            </div>
        </div>

        <div className="separator my-10"></div>

        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Niveau d'études</label>

            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>{currentUser?.niveauEtudesLabel}</span>
            </div>
        </div>

        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Disponibilité</label>

            <div className='col-lg-8 fv-row'>
                <span className='fw-bold fs-6'>
                    {currentUser?.disponibilite === CONST_CANDIDAT_DISPONIBILITE_DATE_EXACTE && FirstLetterUppercase(currentUser?.disponibiliteDate?.format('dddd Do MMM YYYY'))}
                    {currentUser?.disponibilite !== CONST_CANDIDAT_DISPONIBILITE_DATE_EXACTE && currentUser?.disponibiliteLabel}
                </span>
            </div>
        </div>

        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Mobilité</label>

            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                    {currentUser?.mobilite === CONST_CANDIDAT_MOBILITE_DEPARTEMENTAL && currentUser?.departements?.map(item => item.nom).join(" - ")}
                    {currentUser?.mobilite === CONST_CANDIDAT_MOBILITE_REGIONAL && currentUser?.regions?.map(item => item.nom).join(" - ")}
                    {currentUser?.mobilite === CONST_CANDIDAT_MOBILITE_PAR_VILLE && currentUser?.villes?.map(item => item.nom).join(" - ")}
                    {![CONST_CANDIDAT_MOBILITE_DEPARTEMENTAL, CONST_CANDIDAT_MOBILITE_REGIONAL, CONST_CANDIDAT_MOBILITE_PAR_VILLE].includes(currentUser?.mobilite || '') && currentUser?.mobiliteLabel}
                </span>
            </div>
        </div>

        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Salaire <b>NET MENSUEL</b> souhaité</label>

            <div className='col-lg-8'>
                <span
                    className='fw-bolder fs-6 text-dark'>{currentUser?.salaireNetSouhaite && `${currentUser.salaireNetSouhaite}€`}</span>
            </div>
        </div>

        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Habilitation Secret Défense</label>

            <div className='col-lg-8'>
                <span className='fw-bolder fs-6 text-dark'>
                    {currentUser?.isHabilitableDefense === true && 'Oui'}
                    {currentUser?.isHabilitableDefense === false && 'Non'}
                </span>
            </div>
        </div>

        {currentUser?.isHabilitableDefense === true &&
            <>
                <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Déjà habilité Secret Défense</label>

                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>
                            {currentUser?.isDejaHabiliteDefense === true && <>
                                Oui
                            </>}
                            {currentUser?.isDejaHabiliteDefense === false && 'Non'}
                        </span>
                    </div>
                </div>

                {currentUser?.isDejaHabiliteDefense === true && <div className='row mb-7'>
                    <label className='col-lg-4 fw-bold text-muted'>Date de fin d'habilitation</label>

                    <div className='col-lg-8'>
                        <span className='fw-bolder fs-6 text-dark'>
                            {currentUser?.dateFinHabilitationDefense?.format('DD/MM/YYYY')}
                        </span>
                    </div>
                </div>}
            </>
        }
    </>
}

