import React from "react"
import "../styles.scss"
import MyAlert from "../../../../../../../utils/MyAlert"
import { useDeclarationUpdate } from "../DeclarationUpdate"
import {
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_MODIF_SANS_VALIDATION_BM_OU_SIEGE,
} from "../../../../../../../constants"

const urlParams = new URLSearchParams(window.location.search)

const DeclarationUpdateRecapitulatifMessageHeader = () => {
     const codeSignature = urlParams.get("code_signature") || null
     const context = useDeclarationUpdate()

     return (
          <div className={"mb-4"}>
               {!codeSignature &&
                    [
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_MODIF_SANS_VALIDATION_BM_OU_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                    ].includes(context.data.declaration!.etat?.type) && (
                         <MyAlert type={"primary"} classNames={"mb-2"}>
                              <b className={"text-dark"}>Un mail de finalisation</b> vous a été envoyé pour signer votre déclaration. Si vous ne l'avez pas reçu, veuillez cliquer
                              sur le bouton "re-envoyer le mail de finalisation" situé au pied de la page.
                         </MyAlert>
                    )}
          </div>
     )
}

export default DeclarationUpdateRecapitulatifMessageHeader
