import * as Yup from "yup"
import React from "react"
import { Form, Formik, FormikHelpers } from "formik"
import MyAlert from "../../utils/MyAlert"
import MyInputField from "../../utils/fields/MyInputField"
import MySimpleSpinner from "../../utils/MySimpleSpinner"
import axios, { AxiosError } from "axios"
import { API_BASE_URL, CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "../../constants"
import { useCandidatureWithoutAuthContext } from "./CandidatureWithoutAuth"
import { IHttpErrorResponseModel } from "../../models"
import { toast } from "react-toastify"

const CandidatureWithoutAuthStepOne = () => {
     const context = useCandidatureWithoutAuthContext()

     const schema = Yup.object().shape({
          nom: Yup.string().label(`"Nom"`).required(),
          prenom: Yup.string().label(`"Prénom"`).required(),
          email: Yup.string().email().label(`"Email"`).required(),
     })

     const initialValues: ICandidatureStepOneFormRequestModel = {
          referenceAnnonce: context.referenceAnnonce,
          jobboard: context.jobBoard,
     }

     function handleSubmit(values: ICandidatureStepOneFormRequestModel, helpers: FormikHelpers<ICandidatureStepOneFormRequestModel>) {
          helpers.setStatus(null)

          axios.post<{ candidat_id: number; code: "CODE_CANDIDAT_OK" | "CODE_CANDIDAT_AVEC_INFO_MANQUANT" | "CODE_CANDIDAT_NEW" }>(
               `${API_BASE_URL}/candidature-sans-auth-step-one`,
               values
          )
               .then(r => {
                    if (r.data.code === "CODE_CANDIDAT_OK") {
                         context.setIsCandidatureFinished(true)
                    } else {
                         context.setCandidatureStep({ step: "two", candidat_id: r.data.candidat_id, candidat_email: values.email as string })
                    }
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete="off">
                              {helpers.status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {helpers.status}
                                   </MyAlert>
                              )}

                              {/* Nom */}
                              <div className="row mb-4">
                                   <label className={`col-lg-4 col-form-label fw-bold fs-6 required`}>Nom</label>

                                   <div className="col-lg-8">
                                        <MyInputField
                                             name={"nom"}
                                             value={helpers.values.nom}
                                             onChange={val => helpers.setFieldValue("nom", val)}
                                             isInvalid={!!helpers.errors.nom}
                                             placeholder={"Nom"}
                                             className={"bg-transparent"}
                                        />
                                        {helpers.errors.nom && <div className={"text-danger"}>{helpers.errors.nom}</div>}
                                   </div>
                              </div>

                              {/* Prénom */}
                              <div className="row mb-4">
                                   <label className={`col-lg-4 col-form-label fw-bold fs-6 required`}>Prénom</label>

                                   <div className="col-lg-8">
                                        <MyInputField
                                             name={"prenom"}
                                             value={helpers.values.prenom}
                                             onChange={val => helpers.setFieldValue("prenom", val)}
                                             isInvalid={!!helpers.errors.prenom}
                                             placeholder={"Prénom"}
                                             className={"bg-transparent"}
                                        />
                                        {helpers.errors.prenom && <div className={"text-danger"}>{helpers.errors.prenom}</div>}
                                   </div>
                              </div>

                              {/* Email */}
                              <div className="row mb-4">
                                   <label className={`col-lg-4 col-form-label fw-bold fs-6 required`}>Email</label>

                                   <div className="col-lg-8">
                                        <MyInputField
                                             name={"email"}
                                             value={helpers.values.email}
                                             onChange={val => helpers.setFieldValue("email", val)}
                                             isInvalid={!!helpers.errors.email}
                                             placeholder={"Email"}
                                             className={"bg-transparent"}
                                        />
                                        {helpers.errors.email && <div className={"text-danger"}>{helpers.errors.email}</div>}
                                   </div>
                              </div>

                              {/* Submit button */}
                              <div className={"d-flex justify-content-between"}>
                                   <a href={"https://cts-corporate.com/offres"} className={"btn btn-sm btn-primary d-inline-flex align-items-center"} target={"_parent"}>
                                        <span className={"fas fa-arrow-left me-1"} />
                                        Retour aux offres
                                   </a>
                                   <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                        {!helpers.isSubmitting ? (
                                             <>
                                                  Postuler <span className={"fas fa-arrow-right ms-1"} />
                                             </>
                                        ) : (
                                             <MySimpleSpinner size={"sm"} />
                                        )}
                                   </button>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

interface ICandidatureStepOneFormRequestModel {
     nom?: string
     prenom?: string
     email?: string
     referenceAnnonce: string
     jobboard?: string
}

export default CandidatureWithoutAuthStepOne
