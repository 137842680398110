import {createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useRef, useState,} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {
    AUTH_ESPACE_AVENTURIER_TYPE_CANDIDAT, AUTH_ESPACE_AVENTURIER_TYPE_CONSULTANT,
    AUTH_ESPACE_AVENTURIER_TYPE_PREEMBAUCHE, CONST_CANDIDAT_DOCUMENT_TYPE_CV, CONST_CANDIDAT_DOCUMENT_TYPES_FOR_SELECT2, CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS,
    CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI, CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE, CONST_CONSULTANT_DOCUMENT_TYPE_RIB,
    CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR, CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2,
    CONST_CONTRAT_ST,
    CONST_PAYS_CODE_FRANCE
} from "../../../constants";

type AuthContextProps = {
    auth: AuthModel | undefined
    saveAuth: (auth: AuthModel | undefined) => void
    currentUser: UserModel | undefined
    setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
    completionInfoProfilEnPourcentage: number
    infoPersonellesNecessairesToEmbaucheToBeFilled: string[]
    documentsNecessairesToEmbaucheToBeFilled: string[]
    logout: () => void
}

const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    saveAuth: () => {
    },
    currentUser: undefined,
    setCurrentUser: () => {
    },
    completionInfoProfilEnPourcentage: 0,
    infoPersonellesNecessairesToEmbaucheToBeFilled: [],
    documentsNecessairesToEmbaucheToBeFilled: [],
    logout: () => {
    },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
    return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
    const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
    const [currentUser, setCurrentUser] = useState<UserModel | undefined>()

    // % complétion info profil
    const completionInfoProfilEnPourcentage: number = useMemo(() => {
        if (!currentUser) return 0

        if (currentUser.typeEspaceAppAventurier === AUTH_ESPACE_AVENTURIER_TYPE_CANDIDAT) {
            let totalInfoToFill = 12
            let totalFilled = 0

            if (currentUser.civilite != null || currentUser.civilite != undefined) totalFilled++
            if (currentUser.nom != null || currentUser.nom != undefined) totalFilled++
            if (currentUser.prenom != null || currentUser.prenom != undefined) totalFilled++
            if (currentUser.email != null || currentUser.email != undefined) totalFilled++
            if (currentUser.tel != null || currentUser.tel != undefined) totalFilled++
            if (currentUser.dateNaissance != null || currentUser.dateNaissance != undefined) totalFilled++
            if (currentUser.pays != null || currentUser.pays != undefined) totalFilled++
            if (currentUser.niveauEtudes != null || currentUser.niveauEtudes != undefined) totalFilled++
            if (currentUser.disponibilite != null || currentUser.disponibilite != undefined) totalFilled++
            if (currentUser.mobilite != null || currentUser.mobilite != undefined) totalFilled++
            if (currentUser.salaireNetSouhaite != null || currentUser.salaireNetSouhaite != undefined) totalFilled++
            if (currentUser.isHabilitableDefense != null || currentUser.isHabilitableDefense != undefined) totalFilled++

            const docs = currentUser.undeletedCandidatDocuments
            totalInfoToFill += 1 // Que le CV

            if (docs.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)) totalFilled++

            return Math.round(totalFilled / totalInfoToFill * 100)
        } else if (currentUser.typeEspaceAppAventurier === AUTH_ESPACE_AVENTURIER_TYPE_PREEMBAUCHE) {
            const consultant = currentUser.consultant


            let totalInfoToFill = consultant.contrat != CONST_CONTRAT_ST ? 13 : 9
            let totalFilled = 0

            if (consultant.civilite != null || consultant.civilite != undefined) totalFilled++
            if (consultant.nom != null || consultant.nom != undefined) totalFilled++
            if (consultant.prenom != null || consultant.prenom != undefined) totalFilled++
            if (consultant.naissanceDate != null || consultant.naissanceDate != undefined) totalFilled++
            if (consultant.naissancePays != null || consultant.naissancePays != undefined) totalFilled++
            if (consultant.nationalite != null || consultant.nationalite != undefined) totalFilled++
            if (consultant.domicileAdresse != null || consultant.domicileAdresse != undefined) totalFilled++
            if (consultant.domicilePays != null || consultant.domicilePays != undefined) totalFilled++
            if (consultant.domicileCodePostal != null || consultant.domicileCodePostal != undefined) totalFilled++

            if (consultant.contrat != CONST_CONTRAT_ST) {
                if (consultant.numeroSecuriteSociale != null || consultant.numeroSecuriteSociale != undefined) totalFilled++
                if (consultant.contactUrgenceNom != null || consultant.contactUrgenceNom != undefined) totalFilled++
                if (consultant.contactUrgencePrenom != null || consultant.contactUrgencePrenom != undefined) totalFilled++
                if (consultant.contactUrgenceTel != null || consultant.contactUrgenceTel != undefined) totalFilled++
            }


            const consultantDocs = currentUser.consultant.undeletedAndSigneConsultantDocuments
            const candidatDocs = currentUser.undeletedCandidatDocuments
            totalInfoToFill += (() => {
                if (consultant.contrat != CONST_CONTRAT_ST) {
                    return consultant.nationalite?.code == CONST_PAYS_CODE_FRANCE ? 5 : 6
                } else {
                    return 1 // Que le CV pour les ST
                }
            })()

            if (candidatDocs.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)) totalFilled++

            if (consultant.contrat != CONST_CONTRAT_ST) {
                if (consultant.nationalite?.code != CONST_PAYS_CODE_FRANCE && consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR)) totalFilled++
                if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)) totalFilled++
                if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)) totalFilled++
                if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)) totalFilled++
                if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)) totalFilled++

            }


            return Math.round(totalFilled / totalInfoToFill * 100)
        } else {
            // Espace consultant
            const consultant = currentUser.consultant


            let totalInfoToFill = consultant.contrat != CONST_CONTRAT_ST ? 7 : 3
            let totalFilled = 0

            if (consultant.domicileAdresse != null || consultant.domicileAdresse != undefined) totalFilled++
            if (consultant.domicilePays != null || consultant.domicilePays != undefined) totalFilled++
            if (consultant.domicileCodePostal != null || consultant.domicileCodePostal != undefined) totalFilled++

            if (consultant.contrat != CONST_CONTRAT_ST) {
                if (consultant.numeroSecuriteSociale != null || consultant.numeroSecuriteSociale != undefined) totalFilled++
                if (consultant.contactUrgenceNom != null || consultant.contactUrgenceNom != undefined) totalFilled++
                if (consultant.contactUrgencePrenom != null || consultant.contactUrgencePrenom != undefined) totalFilled++
                if (consultant.contactUrgenceTel != null || consultant.contactUrgenceTel != undefined) totalFilled++
            }



            const consultantDocs = currentUser.consultant.undeletedAndSigneConsultantDocuments
            const candidatDocs = currentUser.undeletedCandidatDocuments
            totalInfoToFill += (() => {
                if (consultant.contrat != CONST_CONTRAT_ST) {
                    return consultant.nationalite?.code == CONST_PAYS_CODE_FRANCE ? 5 : 6
                } else {
                    return 1 // Que le CV pour les ST
                }
            })()

            if (candidatDocs.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)) totalFilled++

            if (consultant.contrat != CONST_CONTRAT_ST) {
                if (consultant.nationalite?.code != CONST_PAYS_CODE_FRANCE && consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR)) totalFilled++
                if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)) totalFilled++
                if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)) totalFilled++
                if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)) totalFilled++
                if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)) totalFilled++

            }


            return Math.round(totalFilled / totalInfoToFill * 100)
        }
    }, [currentUser])

    const infoPersonellesNecessairesToEmbaucheToBeFilled: string[] = useMemo(() => {
        if (!currentUser) return []

        let infoMissing: string[] = []
        if (currentUser.typeEspaceAppAventurier == AUTH_ESPACE_AVENTURIER_TYPE_PREEMBAUCHE || currentUser.typeEspaceAppAventurier == AUTH_ESPACE_AVENTURIER_TYPE_CONSULTANT) {
            if (!currentUser.consultant.telProPays) infoMissing.push('Indicatif téléphonique')
            if (!currentUser.consultant.telPro) infoMissing.push('N° de téléphone')
            if (!currentUser.consultant.naissanceDate) infoMissing.push('Date de naissance')
            if (!currentUser.consultant.naissancePays) infoMissing.push('Pays de naissance')
            if (currentUser.consultant.naissancePays?.code === CONST_PAYS_CODE_FRANCE && !currentUser.consultant.naissanceVilleFrance) infoMissing.push('Ville de naissance')
            if (currentUser.consultant.naissancePays?.code !== CONST_PAYS_CODE_FRANCE && !currentUser.consultant.naissanceVille) infoMissing.push('Ville de naissance')
            if (!currentUser.consultant.nationalite) infoMissing.push('Nationalité')
            if (!currentUser.consultant.domicileAdresse) infoMissing.push('Adresse de résidence')
            if (!currentUser.consultant.domicilePays) {
                infoMissing.push('Pays de résidence')
            } else {
                if (currentUser.consultant.domicilePays.code === CONST_PAYS_CODE_FRANCE && !currentUser.consultant.domocileVilleFrance) infoMissing.push('Ville de résidence')
                if (currentUser.consultant.domicilePays.code !== CONST_PAYS_CODE_FRANCE && !currentUser.consultant.domicileVille) infoMissing.push('Ville de résidence')

            }

            if (currentUser.consultant.contrat !== CONST_CONTRAT_ST) {
                if (!currentUser.consultant.numeroSecuriteSociale) infoMissing.push('N° de Sécurité Sociale')
            }
        }

        return infoMissing
    }, [currentUser])

    const documentsNecessairesToEmbaucheToBeFilled: string[] = useMemo(() => {
        if (!currentUser) return []

        let infoMissing: string[] = []

        if (!currentUser.undeletedCandidatDocuments.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)) {
            const item = CONST_CANDIDAT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CANDIDAT_DOCUMENT_TYPE_CV)
            infoMissing.push(item!.label)
        }

        if (currentUser.typeEspaceAppAventurier == AUTH_ESPACE_AVENTURIER_TYPE_PREEMBAUCHE || currentUser.typeEspaceAppAventurier == AUTH_ESPACE_AVENTURIER_TYPE_CONSULTANT) {
            if (currentUser.consultant?.contrat !== CONST_CONTRAT_ST) {
                if (!currentUser.consultant.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)) {
                    const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)
                    infoMissing.push(item!.label)
                }

                if (currentUser.consultant?.nationalite?.code !== CONST_PAYS_CODE_FRANCE && !currentUser.consultant.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR)) {
                    const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR)
                    infoMissing.push(item!.label)
                }

                if (!currentUser.consultant.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)) {
                    const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)
                    infoMissing.push(item!.label)
                }

                if (!currentUser.consultant.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)) {
                    const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)
                    infoMissing.push(item!.label)
                }

                if (!currentUser.consultant.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)) {
                    const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)
                    infoMissing.push(item!.label)
                }
            }
        }

        return infoMissing
    }, [currentUser])

    const saveAuth = (auth: AuthModel | undefined) => {
        setAuth(auth)
        if (auth) {
            authHelper.setAuth(auth)
        } else {
            authHelper.removeAuth()
        }
    }

    const logout = () => {
        saveAuth(undefined)
        setCurrentUser(undefined)
    }

    return (
        <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout, completionInfoProfilEnPourcentage, infoPersonellesNecessairesToEmbaucheToBeFilled, documentsNecessairesToEmbaucheToBeFilled}}>
            {children}
        </AuthContext.Provider>
    )
}

const AuthInit: FC<WithChildren> = ({children}) => {
    const {auth, logout, setCurrentUser} = useAuth()
    const didRequest = useRef(false)
    const [showSplashScreen, setShowSplashScreen] = useState(true)
    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {
        const requestUser = async (apiToken: string) => {
            try {
                if (!didRequest.current) {
                    const {data} = await getUserByToken(apiToken)
                    if (data) {
                        setCurrentUser(data)
                    }
                }
            } catch (error) {
                if (!didRequest.current) {
                    logout()
                }
            } finally {
                setShowSplashScreen(false)
            }

            return () => (didRequest.current = true)
        }

        if (auth && auth.token) {
            requestUser(auth.token)
        } else {
            logout()
            setShowSplashScreen(false)
        }
        // eslint-disable-next-line
    }, [])

    return showSplashScreen ? <LayoutSplashScreen/> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
