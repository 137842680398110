import { useLocation, useNavigate } from "react-router-dom"
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import MyAlert from "../../../../../../utils/MyAlert"
import { declarationDetails } from "../../../core/_request"
import { ConsultantDeclarationMensuelleModel } from "../../../../../../models"
import { useIntl } from "react-intl"
import "./styles.scss"
import { DeclarationUpdateCra } from "./DeclarationUpdateCra"
import { DeclarationUpdateConges } from "./DeclarationUpdateConges"
import { DeclarationUpdateNotesDeFrais } from "./ndf/DeclarationUpdateNotesDeFrais"
import { DeclarationUpdateRecapitulatif } from "./recap/DeclarationUpdateRecapitulatif"
import { DeclarationUpdateTicketsResaurant } from "./DeclarationUpdateTicketsResaurant"
import { DeclarationUpdateArrets } from "./DeclarationUpdateArrets"
import {
     CONST_DECLARATION_SUIVI_TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_VALIDATION_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
     CONST_DECLARATION_SUIVI_TYPE_REJET_REGUL_PAR_RESP,
     CONST_DECLARATION_SUIVI_TYPE_RESP_VIENT_DE_DEMANDER_UNE_RECTIFICATION_APRES_SIGNATURE_APRES_DEMANDE_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_VALIDATION_REGUL_PAR_RESP,
} from "../../../../../../constants"

const urlParams = new URLSearchParams(window.location.search)

type DeclarationUpdateContextProps = {
     data: { loading: boolean; error: false | string; declaration: ConsultantDeclarationMensuelleModel | null }
     setData: Dispatch<SetStateAction<{ loading: boolean; error: false | string; declaration: ConsultantDeclarationMensuelleModel | null }>>
     declarationStepHeader: "periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "recap"
     setDeclarationStepHeader: Dispatch<SetStateAction<"periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "recap">>
     enCoursDeRectificationEnabledDepuisRecapitulatif: boolean
     setEnCoursDeRectificationEnabledDepuisRecapitulatif: Dispatch<SetStateAction<boolean>>
     editionModeEnabled: boolean
}
const DeclarationUpdateContext = createContext<DeclarationUpdateContextProps>({} as DeclarationUpdateContextProps)

export function useDeclarationUpdate() {
     return useContext(DeclarationUpdateContext)
}

export function DeclarationUpdate() {
     const intl = useIntl()

     const navigate = useNavigate()
     const location_state = useLocation().state as { declaration_id: string; default_step: "periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "recap" }

     const declaration_id = location_state?.declaration_id || (urlParams.get("declaration_id") as string)
     const default_step = location_state?.default_step || (urlParams.get("default_step") as string)

     const [data, setData] = useState<{ loading: boolean; error: false | string; declaration: ConsultantDeclarationMensuelleModel | null }>({
          loading: true,
          error: false,
          declaration: null,
     })
     const [enCoursDeRectificationEnabledDepuisRecapitulatif, setEnCoursDeRectificationEnabledDepuisRecapitulatif] = useState<boolean>(false)
     const [declarationStepHeader, setDeclarationStepHeader] = useState<"periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "recap">(default_step || "arrets")

     useEffect(() => {
          if (declaration_id) {
               declarationDetails(parseInt(declaration_id))
                    .then(r => {
                         setData(prev => ({ ...prev, loading: false, declaration: r.data }))
                    })
                    .catch(e => {
                         const error = e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                         toast.error(error, {autoClose: false})
                         setData(prev => ({ ...prev, loading: false, error: error }))
                    })
          } else {
               navigate(`/declarations/new`)
          }
     }, [])

     return (
          <div className="card mb-5 mb-xl-10">
               <div className="card-body p-9">
                    {data.loading && (
                         <div className={"text-center p-4"}>
                              <span className="spinner-border mb-2"></span>
                              <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
                         </div>
                    )}

                    {data.error && <MyAlert type={"danger"}>{data.error}</MyAlert>}

                    {!data.loading && data.declaration && (
                         <>
                              <DeclarationUpdateContext.Provider
                                   value={{
                                        data: data,
                                        setData: setData,
                                        declarationStepHeader: declarationStepHeader,
                                        setDeclarationStepHeader: setDeclarationStepHeader,
                                        enCoursDeRectificationEnabledDepuisRecapitulatif: enCoursDeRectificationEnabledDepuisRecapitulatif,
                                        setEnCoursDeRectificationEnabledDepuisRecapitulatif: setEnCoursDeRectificationEnabledDepuisRecapitulatif,
                                        editionModeEnabled: data.declaration.modifiable,
                                   }}
                              >
                                   {data.declaration &&
                                        data.declaration.etat &&
                                        data.declaration.etat?.commentaire &&
                                        [
                                             CONST_DECLARATION_SUIVI_TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT,
                                             CONST_DECLARATION_SUIVI_TYPE_VALIDATION_REGUL_PAR_RESP,
                                             CONST_DECLARATION_SUIVI_TYPE_REJET_REGUL_PAR_RESP,
                                             CONST_DECLARATION_SUIVI_TYPE_RESP_VIENT_DE_DEMANDER_UNE_RECTIFICATION_APRES_SIGNATURE_APRES_DEMANDE_REGUL,
                                             CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
                                             CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                                             CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM,
                                             CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_VALIDATION_REGUL,
                                        ].includes(data.declaration.etat.type as string) && (
                                             <MyAlert type={"info"} customIcon={"fas fa-comment-dots"} classNames={"mb-2"}>
                                                  <b className={"text-dark"}>{data.declaration.etat.personnel ? data.declaration.etat.personnel?.prenomNom : "Moi"}: </b>{" "}
                                                  {data.declaration.etat?.commentaire}
                                             </MyAlert>
                                        )}

                                   <DeclarationStepHeader />

                                   {declarationStepHeader == "arrets" && <DeclarationUpdateArrets />}

                                   {declarationStepHeader == "ddc" && <DeclarationUpdateConges />}

                                   {declarationStepHeader == "cra" && <DeclarationUpdateCra />}

                                   {declarationStepHeader == "ndf" && <DeclarationUpdateNotesDeFrais />}

                                   {declarationStepHeader == "ticket_resto" && <DeclarationUpdateTicketsResaurant />}

                                   {declarationStepHeader == "recap" && <DeclarationUpdateRecapitulatif />}
                              </DeclarationUpdateContext.Provider>
                         </>
                    )}
               </div>
          </div>
     )
}

export const DeclarationStepHeader = ({ stepperStep }: { stepperStep?: "periode" }) => {
     const context = useDeclarationUpdate()

     return (
          <div className="stepper stepper-links mb-5">
               <div className="stepper-nav">
                    {/* begin::Step période */}
                    <div
                         className={`stepper-item m-sm-5 m-2 ${stepperStep == "periode" && "current"} ${
                              ["ddc", "cra", "ndf", "ticket_resto", "recap"].includes(context.declarationStepHeader) && "completed"
                         }`}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>1. Période</span>
                              <span className={"d-block d-sm-none fas fa-calendar-plus"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step période */}

                    {/* begin::Step DDC */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${context.declarationStepHeader === "arrets" && "current"} ${
                              ["ddc", "cra", "ndf", "ticket_resto", "recap"].includes(context.declarationStepHeader) && "completed"
                         }  ${!["periode", "ddc"].includes(context.declarationStepHeader) && "cursor-pointer"}`}
                         onClick={() => !["periode", "arrets"].includes(context.declarationStepHeader) && context.setDeclarationStepHeader!("arrets")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>2. Arrêts</span>
                              <span className={"d-block d-sm-none fas fa-file-medical"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step DDC */}

                    {/* begin::Step DDC */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${context.declarationStepHeader == "ddc" && "current"} ${
                              ["cra", "ndf", "ticket_resto", "recap"].includes(context.declarationStepHeader) && "completed"
                         }  ${!["periode", "ddc"].includes(context.declarationStepHeader) && "cursor-pointer"}`}
                         onClick={() => !["periode", "ddc"].includes(context.declarationStepHeader) && context.setDeclarationStepHeader!("ddc")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>3. Congés</span>
                              <span className={"d-block d-sm-none fas fa-umbrella-beach"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step DDC */}

                    {/* begin::Step CRA */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${context.declarationStepHeader == "cra" && "current"} ${
                              ["ndf", "ticket_resto", "recap"].includes(context.declarationStepHeader) && "completed"
                         } ${!["periode", "cra"].includes(context.declarationStepHeader) && "cursor-pointer"}`}
                         onClick={() => {
                              if (!["periode", "cra"].includes(context.declarationStepHeader)) context.setDeclarationStepHeader!("cra")
                         }}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>4. CRA</span>
                              <span className={"d-block d-sm-none fas fa-calendar-days"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step CRA */}

                    {/* begin::Step NDF */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${["ndf"].includes(context.declarationStepHeader) && "current"} ${
                              ["ticket_resto", "recap"].includes(context.declarationStepHeader) && "completed"
                         } ${!["periode", "ndf"].includes(context.declarationStepHeader) && "cursor-pointer"}`}
                         onClick={() => !["periode", "ndf"].includes(context.declarationStepHeader) && context.setDeclarationStepHeader!("ndf")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>5. Notes de frais</span>
                              <span className={"d-block d-sm-none fas fa-file-invoice-dollar"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step NDF */}

                    {/* begin::Step Tickets restaurant */}
                    <div
                         className={`stepper-item m-sm-5 m-4 ${context.declarationStepHeader == "ticket_resto" && "current"} ${
                              ["recap"].includes(context.declarationStepHeader) && "completed"
                         } ${!["periode", "ticket_resto"].includes(context.declarationStepHeader) && "cursor-pointer"}`}
                         onClick={() => !["periode", "ticket_resto"].includes(context.declarationStepHeader) && context.setDeclarationStepHeader!("ticket_resto")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>6. Tickets restaurant</span>
                              <span className={"d-block d-sm-none fas fa-utensils"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step Tickets restaurant */}

                    {/* begin::Step 5 */}
                    <div
                         className={`stepper-item ${context.declarationStepHeader == "recap" && "current"}  ${
                              !["periode", "recap"].includes(context.declarationStepHeader) && "cursor-pointer"
                         }`}
                         onClick={() => !["periode", "recap"].includes(context.declarationStepHeader) && context.setDeclarationStepHeader!("recap")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>7. Récapitulatif</span>
                              <span className={"d-block d-sm-none fas fa-list-alt"} style={{ fontSize: "1.5rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step 5 */}
               </div>
          </div>
     )
}
