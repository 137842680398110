import React from "react";
import {DeleteAccount} from "./cards/DeleteAccount";
import {Authentication} from "./cards/authentication/Authentication";
import {useAuth} from "../../../auth";
import {AUTH_ESPACE_AVENTURIER_TYPE_CONSULTANT} from "../../../../constants";

export function Compte() {
    const {currentUser} = useAuth()
    return <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
            <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Paramètres du compte</h3>
            </div>
        </div>

        <div className='card-body p-9'>
            <Authentication/>

            {currentUser?.typeEspaceAppAventurier !== AUTH_ESPACE_AVENTURIER_TYPE_CONSULTANT && <DeleteAccount/>}
        </div>
    </div>
}

