import {Link} from "react-router-dom";
import React from "react";
import {useAuth} from "../../../../auth";
import InfoPersoEditFormEspaceCandidat from "./InfoPersoEditFormEspaceCandidat";
import InfoPersoEditFormEspacePreembaucheSousTraitant from "./InfoPersoEditFormEspacePreembaucheSousTraitant";
import InfoPersoEditFormEspacePreembaucheSalarie from "./InfoPersoEditFormEspacePreembaucheSalarie";
import {AUTH_ESPACE_AVENTURIER_TYPE_CANDIDAT, AUTH_ESPACE_AVENTURIER_TYPE_CONSULTANT, AUTH_ESPACE_AVENTURIER_TYPE_PREEMBAUCHE, CONST_CONTRAT_ST} from "../../../../../constants";
import InfoPersoEditFormEspaceConsultantSousTraitant from "./InfoPersoEditFormEspaceConsultantSousTraitant";
import InfoPersoEditFormEspaceConsultantSalarie from "./InfoPersoEditFormEspaceConsultantSalarie";

export function InfoPersoEdit() {
    const {currentUser} = useAuth()

    return <>
        {currentUser?.typeEspaceAppAventurier === AUTH_ESPACE_AVENTURIER_TYPE_CANDIDAT && <InfoPersoEditFormEspaceCandidat/>}
        {currentUser?.typeEspaceAppAventurier === AUTH_ESPACE_AVENTURIER_TYPE_PREEMBAUCHE && currentUser?.consultant?.contrat === CONST_CONTRAT_ST && <InfoPersoEditFormEspacePreembaucheSousTraitant/>}
        {currentUser?.typeEspaceAppAventurier === AUTH_ESPACE_AVENTURIER_TYPE_PREEMBAUCHE && currentUser?.consultant?.contrat !== CONST_CONTRAT_ST && <InfoPersoEditFormEspacePreembaucheSalarie/>}
        {currentUser?.typeEspaceAppAventurier === AUTH_ESPACE_AVENTURIER_TYPE_CONSULTANT && currentUser?.consultant?.contrat === CONST_CONTRAT_ST && <InfoPersoEditFormEspaceConsultantSousTraitant/>}
        {currentUser?.typeEspaceAppAventurier === AUTH_ESPACE_AVENTURIER_TYPE_CONSULTANT && currentUser?.consultant?.contrat !== CONST_CONTRAT_ST && <InfoPersoEditFormEspaceConsultantSalarie/>}
    </>
}

export function InfoPersoEditWrapper() {
    return <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header'>
            <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Mes informations personnelles</h3>
            </div>

            <Link to="/mon-compte/profil" className='btn btn-sm btn-primary align-self-center d-none d-sm-block'>
                Abandonner le mode édition
            </Link>

            <Link to="/mon-compte/profil" className={`d-sm-none d-block align-self-center`}>
                <i className={"fas fa-eye text-primary"} style={{fontSize: '1.5rem'}}/>
            </Link>
        </div>

        <div className='card-body w-lg-60'>
            <InfoPersoEdit/>
        </div>
    </div>
}



