import { CONST_DEFAULT_TOAST_OPTIONS } from "../../../constants"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"
import { addOffreAmongFavoris, getOffres, getOffresFavoris, removeOffreFromFavoris } from "../core/_requests"
import { AxiosError } from "axios"
import { OffreModel } from "../../../models"
import { Link } from "react-router-dom"

export function Offres() {
     const intl = useIntl()
     const [loading, setLoading] = useState<boolean>(false)
     const [initialOffres, setInitialOffres] = useState<OffreModel[]>([])
     const [offres, setOffres] = useState<OffreModel[]>([])
     const [offresFavoris, setOffresFavoris] = useState<OffreModel[]>([])
     const [filterText, setFilterText] = useState("")

     useEffect(() => {
          setLoading(true)
          loadOffres()
     }, [])

     function loadOffres() {
          getOffres()
               .then(r => {
                    setOffres(r.data)
                    setInitialOffres(r.data)
                    getOffresFavoris().then(r => {
                         setOffresFavoris(r.data)
                         toast.dismiss()
                         setLoading(false)
                    })
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
               })
     }

     function handleAddOffreAmongFavoris(id: number) {
          toast.info("Ajout en cours ...", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: false })
          addOffreAmongFavoris(id)
               .then(r => {
                    setOffresFavoris(r.data)
                    toast.dismiss()
                    toast.success("L'offre a bien été ajoutée parmi vos favoris.", CONST_DEFAULT_TOAST_OPTIONS)
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
               })
     }

     function handleRemoveOffreFromFavoris(id: number) {
          toast.info("Suppression en cours ...", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: false })

          removeOffreFromFavoris(id)
               .then(r => {
                    setOffresFavoris(r.data)
                    toast.dismiss()
                    toast.success("L'offre ne fait plus partie de vos favoris.", CONST_DEFAULT_TOAST_OPTIONS)
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
               })
     }

     const handleFilterChangeSearch = (value: string) => {
          setFilterText(value)

          const filteredItems = value == "" ? initialOffres : initialOffres.filter(item => JSON.stringify(item).toLowerCase().includes(value))

          setOffres(filteredItems)
     }

     const handleKeyUpSearch = event => {
          if (event.key === "Enter") event.target.blur()
     }

     return (
          <div className="card">
               <div className="card-body p-5 w-xxl-1200px">
                    {loading && (
                         <div className={"text-center p-4"}>
                              <span className="spinner-border mb-2"></span>
                              <p style={{ fontWeight: 500 }}>Chargement des offres en cours ...</p>
                         </div>
                    )}

                    {!loading && offres && (
                         <>
                              <div className="d-flex justify-content-between mb-7">
                                   <div className="w-xxl-15 w-xl-20 w-lg-25 w-md-30 w-sm-35 w-75">
                                        <input
                                             type={"text"}
                                             className={"form-control form-control-sm"}
                                             onChange={e => handleFilterChangeSearch(e.target.value.toLowerCase())}
                                             placeholder={"Rechercher ..."}
                                             onKeyUp={handleKeyUpSearch}
                                             value={filterText}
                                        />
                                   </div>
                                   <div className="">
                                        <button
                                             className={`btn btn-primary btn-sm float-end ${loading && "disabled"}`}
                                             onClick={() => {
                                                  setFilterText("")
                                                  setLoading(true)
                                                  setTimeout(() => {
                                                       loadOffres()
                                                  }, 2000)
                                             }}
                                        >
                                             <i className={"fas fa-sync pe-md-2 p-0"} />
                                             <span className={`d-md-inline d-none`}>Recharger la liste</span>
                                        </button>
                                   </div>
                              </div>
                              <div className="row mb-4" style={{ maxHeight: "50vh", overflow: "scroll" }}>
                                   {offres.map((offre, key) => (
                                        <div className="col-md-6 mb-9" key={key}>
                                             <div className="d-flex align-items-center">
                                                  <Link to={`/offres/${offre.id}/details?titre=${offre.intitule}`} className={"btn btn-active-light-primary btn-sm"}>
                                                       <span className={"d-none d-sm-block fas fa-eye fs-1"} />
                                                       <i className={"d-sm-none d-block fas fa-eye p-0 text-dark fs-3"}></i>
                                                  </Link>
                                                  <div className={"border-start ps-3"}>
                                                       <Link
                                                            to={`/offres/${offre.id}/details?titre=${offre.intitule}`}
                                                            className={"text-dark fw-bolder text-hover-primary text-active-primary fs-6 d-block py-2"}
                                                       >
                                                            {offre.intitule}
                                                       </Link>
                                                       <span className="text-muted d-block fw-bold mb-1">
                                                            <span className="badge badge-light-primary me-1 mb-1">{offre.businessUnitMetier?.businessUnit?.nom}</span>
                                                            <span className="badge badge-light-warning text-dark me-1">{offre.ville?.nom}</span>
                                                            <span className="badge badge-light-danger">Publié le {offre.publishedAt?.format("DD/MM/YYYY")}</span>
                                                       </span>
                                                       <div className="text-dark d-block fw-bold">
                                                            {offresFavoris.find(item => item.id == offre.id) ? (
                                                                 <span
                                                                      className={"cursor-pointer text-hover-dark d-flex align-items-center"}
                                                                      onClick={() => handleRemoveOffreFromFavoris(offre.id)}
                                                                      style={{ color: "#c4151c" }}
                                                                 >
                                                                      <i className={"fas fa-heart me-2 fs-4"} style={{ color: "#c4151c" }} /> Supprimer des favoris
                                                                 </span>
                                                            ) : (
                                                                 <span
                                                                      className={"cursor-pointer text-hover-danger d-flex align-items-center"}
                                                                      onClick={() => handleAddOffreAmongFavoris(offre.id)}
                                                                 >
                                                                      <i className={"fas fa-heart me-2 fs-4"} /> Ajouter aux favoris
                                                                 </span>
                                                            )}
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   ))}
                              </div>

                              <ul className="pagination d-flex justify-content-end align-items-center">
                                   <div className={"text-dark"}>
                                        1-{offres.length} sur {offres.length} offres
                                   </div>
                                   <li className="page-item disabled">
                                        <a href="#" className="page-link ">
                                             <i className="previous"></i>
                                        </a>
                                   </li>
                                   <li className="page-item disabled">
                                        <a href="#" className="page-link text-primary">
                                             1
                                        </a>
                                   </li>
                                   <li className="page-item disabled next">
                                        <a href="#" className="page-link">
                                             <i className="next"></i>
                                        </a>
                                   </li>
                              </ul>
                         </>
                    )}
               </div>
          </div>
     )
}
